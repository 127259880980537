import { Modal, Button, Select, Input, Tabs, notification } from "antd";
import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";
import { AssignGroupCourse } from "../../Graphs/CorporateGroups/AssignCourse";

const { Option } = Select;
const { TabPane } = Tabs;

export class AssignCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      courses: [],
      selectedCourses: [{ course: null, maxUsers: "" }],
    };
  }

  async componentDidMount() {
    await this.fetchCourses();
  }

  fetchCourses = async () => {
    try {
      const { authToken } = this.props;
      const skip = 0;
      const limit = 50;
      const response = await listAllDropDownCourse(authToken, skip, limit);
      this.setState({ courses: response.data || [] });
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  showModal = async() => {

    await this.fetchCourses();

    this.setState({ visible: true,
      selectedCourses: [{ course: null, maxUsers: "" }],
     });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      selectedCourses: [{ course: null, maxUsers: "" }],
    });
  };

  handleSubmit = async () => {
    const { selectedCourses } = this.state;
    const { authToken, groupId } = this.props;

    if (selectedCourses.every((item) => item.course && item.maxUsers)) {
      try {
        const payload = {
          group_id: groupId,
          courses: (() => {
            const courses = [];
            for (let i = 0; i < this.state.selectedCourses.length; i++) {
              courses.push({
                course_id: this.state.selectedCourses[i]
                  ? this.state.selectedCourses[i].course
                  : null,
                max_user_count: this.state.selectedCourses[i]
                  ? Number(this.state.selectedCourses[i].maxUsers)
                  : null,
              });
            }
            return courses;
          })(),
        };        

        console.log("Submitting:", payload);

        const response = await AssignGroupCourse(authToken, payload);
        console.log("Response:", response); // ✅ Debugging response

        if (response && response.status === 200) {
          notification.success({
            message: "Success",
            description: "Courses assigned successfully!",
          });
          this.setState({ visible: false });

          await this.fetchCourses();
        } else if (response) {
          notification.error({
            message: "Error",
            description: response.message || "Failed to assign courses.",
          });
        }
      } catch (error) {
        console.error("Error submitting courses:", error);
        notification.error({
          message: "Error",
          description: "Failed to assign courses. Please try again.",
        });
      }
    } else {
      alert("Please fill all fields.");
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  handleChange = (index, field, value) => {
    const selectedCourses = [...this.state.selectedCourses];
    selectedCourses[index][field] = value;
    this.setState({ selectedCourses });
  };

  addRow = () => {
    const { selectedCourses } = this.state;
    if (selectedCourses.length < 5) {
      this.setState({
        selectedCourses: [...selectedCourses, { course: null, maxUsers: "" }],
      });
    } else {
      alert("You can add a maximum of 5 courses.");
    }
  };

  removeRow = (index) => {
    const selectedCourses = [...this.state.selectedCourses];
    selectedCourses.splice(index, 1);
    this.setState({ selectedCourses });
  };

  renderRows = () => {
    const { selectedCourses, courses } = this.state;

    let rows = [];
    for (let i = 0; i < selectedCourses.length; i++) {
      rows.push(
        <div
          key={i}
          style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}
        >
          {/* Course Dropdown */}
          <div style={{ flex: 1 }}>
            Courses
            <Select
              id={`course_${i}`}
              placeholder="Select Course"
              value={selectedCourses[i].course || ""}
              onChange={(value) => this.handleChange(i, "course", value)}
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="children"
            >
              {(() => {
                const options = [];
                for (let j = 0; j < courses.length; j++) {
                  options.push(
                    <Option key={courses[j]._id} value={courses[j]._id}>
                      {courses[j].name}
                    </Option>
                  );
                }
                return options;
              })()}
            </Select>
          </div>

          {/* Max Users */}
          <div style={{ flex: 1 }}>
            Max Users
            <Input
              type="number"
              min="0"
              placeholder="Max Users"
              value={selectedCourses[i].maxUsers || ""}
              onChange={(e) => {
                let value = Math.max(0, Number(e.target.value));
                
                if (value < 1) {
                  notification.error({
                    message: "Invalid Input",
                    description: "Value less than 1 is not allowed.",
                  });
                }
          
                this.handleChange(i, "maxUsers", value);
              }}
              style={{ width: "100%" }}
            />
          </div>

          {/* Remove Row Button */}
          <Button
            onClick={() => this.removeRow(i)}
            disabled={selectedCourses.length === 1}
            style={{
              border: "1px solid lightgrey",
              color: "grey",
              backgroundColor: "transparent",
              padding: "6px 11px",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            ✖
          </Button>
        </div>
      );
    }
    return rows;
  };

  render() {
    const { visible, selectedCourses } = this.state;

    return (
      <>
        {/* Assign Button */}
        <Button type="primary" onClick={this.showModal}>
          Assign Course
        </Button>

        {/* Modal */}
        <Modal
          title="Assign Course"
          visible={visible}
          onCancel={this.handleCancel}
          onOk={this.handleSubmit}
          okText="Assign"
          cancelText="Cancel"
          width={600}
          maskClosable={false}
        >
          <div>
            {this.renderRows()}

            {/* Add Row Button */}
            {selectedCourses.length < 5 && (
              <Button
                type="dashed"
                onClick={this.addRow}
                style={{ marginTop: "10px" }}
              >
                + Add Course
              </Button>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  LoginData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(AssignCourse));
