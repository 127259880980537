import React, { Fragment, Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllUser } from "../../Graphs/User/listUser";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Tag, Table } from "antd";
import Title from "./Title";
import { AddCourseSessionTopic } from "./AddCourseSessionTopic";
import { addCourseSessionTopic } from "../../Graphs/CourseSessionTopic/addCourseSessionTopic";
import CourseSessionTopicRecordsTable from "./CourseSessionTopicRecordsTable";
import { getAllCourseSessionTopic } from "../../Graphs/CourseSessionTopic/listCourseSessionTopic";
import { getAllCourse } from "../../Graphs/Course/listCourse";
import { getAllCourseSession } from "../../Graphs/CourseSession/listCourseSession";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class CourseSessionTopic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userListTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (
      !checkPermission(this.props.loginUserData.permissions, "course", "view")
    ) {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText, course_id } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      let routeName = "COURSESESSIONTOPIC";
      if (course_id) {
        routeName = "COURSE_COURSESESSIONTOPIC";
      }

      await setRouteName(routeName);
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listCourseSessionTopicData();
    } catch (e) {
      notification["error"]({
        message: "Topic",
        description: "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listCourseSessionTopicData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListCourseSessionTopicData,
      setListCourseSessionTopicDataTotal,
      course_id,
    } = this.props;

    try {
      let courseSessionTopicData = await getAllCourseSessionTopic(
        authToken,
        0,
        10,
        course_id
      );

      if (courseSessionTopicData.status === 200) {
        //set user data
        // await setListCourseSessionTopicData(courseSessionTopicData.data.docs);
        await setListCourseSessionTopicData(courseSessionTopicData.data);
        await setListCourseSessionTopicDataTotal(courseSessionTopicData.total);

        this.setState({
          // courseSessionTopicList: courseSessionTopicData.data.docs,
          courseSessionTopicList: courseSessionTopicData.data,
          courseSessionTopicTotal: courseSessionTopicData.total,
        });
      } else if (courseSessionTopicData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListCourseSessionTopicData,
      setListCourseSessionTopicDataTotal,
    } = this.props;

    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });
      let addSessionTopicData = await addCourseSessionTopic(authToken, values);

      if (addSessionTopicData.status === 200) {
        await this.listCourseSessionTopicData();
        notification["success"]({
          message: "Add Topic Status",
          description: "Topic added successfully",
        });
      } else if (addSessionTopicData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Add Topic Status",
        description: "There was a problem in Adding Topic",
      });
      console.log("!!!!!!!!e", e);
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const {
      listCourseSessionTopicData,
      listCourseSessionTopicDataTotal,
      loginUserData,
      authToken,
      course_id,
    } = this.props;

    return (
      <Fragment>
        {!course_id ? (
          <Title parent="Course Session Topic" title="Course Session Topic" />
        ) : null}

        {checkPermission(loginUserData.permissions, "course", "create") && (
          <div
            style={{
              marginTop: "20px",
              display: "inline-block",
              width: "100%",
              marginBottom: "20px",
              paddingLeft: "14px",
              paddingRight: "55px",
            }}
          >
            <div style={{ float: "left", cursor: "pointer" }}>
              <AddCourseSessionTopic
                primaryColor={primaryColor}
                onSubmit={this.addSubmit}
                authToken={authToken}
                course_id={course_id}
                loginData={this.props.loginUserData}
              />
            </div>
          </div>
        )}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <CourseSessionTopicRecordsTable
                courseSessionTopicList={listCourseSessionTopicData}
                listCourseSessionTopicTotal={listCourseSessionTopicDataTotal}
                primaryColor={primaryColor}
                course_id={course_id}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listCourseSessionTopicData:
    state.coursesessiontopic.listCourseSessionTopicData,
  listCourseSessionTopicDataTotal:
    state.coursesessiontopic.listCourseSessionTopicDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCourseSessionTopicData: (listCourseSessionTopicData) => {
      dispatch({
        type: "SET_LIST_COURSE_SESSION_TOPIC_DATA",
        listCourseSessionTopicData: listCourseSessionTopicData,
      });
    },
    setListCourseSessionTopicDataTotal: (listCourseSessionTopicDataTotal) => {
      dispatch({
        type: "SET_LIST_COURSE_SESSION_TOPIC_DATA_TOTAL",
        listCourseSessionTopicDataTotal: listCourseSessionTopicDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseSessionTopic));
