import { callApiToServer } from "../callApi";

export async function editFaq(authtoken, faqObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify(faqObject);

    console.log("Body: ",body);

    let endUrl = `/v1/auth/faq/${faqObject.faq_id}`; 
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
