import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";
import { listAllDropDownCategory } from "../../Graphs/Category/listAllDropDownCategory";
import { checkPermission } from "../AdminUser/CheckAdminPermission";

const { Content } = Layout;
const Option = Select.Option;

export class AddSubCategory extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      selectedCategory: "",
      errors: {
        selectedCategory: "",
      },
    };
  }

  input;
  allCategories = [];

  async componentDidMount() {

    const { authToken } = this.props;

    let categoryData = await listAllDropDownCategory(authToken);

    if (categoryData.data && categoryData.data.length >= 1) {
      categoryData.data.map((data, index) => {
        this.allCategories.push(
          <Option value={data._id}>
            {data.name}
          </Option>
        );
      });
    }
  }

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  changeCategory = (value) => {
    this.setState({
      selectedCategory: value,
    });
  };

  handleSubmit = (values, action) => {
    const { selectedCategory, errors } = this.state;

    const dummyErrors = { ...errors };

    dummyErrors.selectedCategory = "";

    let isValidation = false;

    if (!selectedCategory) {
      isValidation = true;
      dummyErrors.selectedCategory = "Please select category";
    }

    this.setState({
      errors: dummyErrors
    });

    if (isValidation) {
      return;
    }

    values.category_id = selectedCategory;
    this.props.onSubmit(values);

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedType: "",
    });
  };

  render() {
    const { primaryColor, loginData } = this.props;
    const hasPermission = checkPermission(loginData, "users", "create");
    
    return (
      <div>
         {hasPermission && ( 
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>
         )}

        <Modal
          destroyOnClose={true}
          title="Add Sub Category"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          maskClosable={false}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: ""
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Name
                        <Input
                          id="name"
                          placeholder="Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.name}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Category
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeCategory(value)}
                          placeholder="Select Category"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allCategories}
                        </Select>
                        {this.state.errors.selectedCategory ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedCategory}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button type="primary" style={{ marginTop: "19px", background: "#5C6AC4", color: "#fff", }} onClick={handleSubmit}>
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}
