import React, { Fragment } from "react";
import {
  Spin,
  Tabs,
  Layout,
  Card,
  notification,
  Table,
  Tooltip,
  Switch,
  Button,
  Popconfirm,
} from "antd";
import Title from "./Title";
import {DeleteGroupCourse} from "../../Graphs/CorporateGroups/DeleteCourse"
import { changeStatus } from "../../Graphs/CorporateGroups/changeStatus";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { listUser } from "../../Graphs/CorporateGroups/listUser";
import { UserPreview } from "./userPreview";
import { getUserTableColumns } from "./userPreview";
import { listCourse } from "../../Graphs/CorporateGroups/listCourse";
import { getCourseTableColumns } from "./coursePreview";
import { listDepartment } from "../../Graphs/CorporateGroups/listDepartment";
import { getDepartmentTableColumns } from "./departmentPreview";
import { AssignCourse } from "./AssignCourse";
import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";
import EditCourseModal from "./EditCourseModal";
import { deleteCorporateUser } from "../../Graphs/CorporateGroups/deletecorporate";
import { editCorporate } from "../../Graphs/CorporateGroups/editcorporateusers";
import EditCorporateUser from "./EditCorporateUSer";
import { changeStatusUser } from "../../Graphs/CorporateGroups/changeStatusUser";
import ChangePasswordForm from "./ChangePasswordModal";
import { changePasswordCorporate } from "../../Graphs/CorporateGroups/changePasswordUser";
import {
  LockOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const { TabPane } = Tabs;

class GroupAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeCourseTab: "1",
      corporateUsers: [],
      corporateCourses: [],
      corporateDepartments: [],
      serialCounter: 1,
      data: [],
      editModalVisible: false,
      selectedCourse: null,
      editUserModalVisible: false,
      changePasswordModalVisible: false,
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  async componentDidMount() {
    await this.fetchCorporateUsers();
    await this.fetchCorporateCourses();
    await this.fetchCorporateDepartments();
  }

  fetchCorporateUsers = async (pagination = this.state.pagination) => {
    const { authToken, match } = this.props;

    this.setState({ isLoading: true });

    try {
      const skipNumber = 0;
      const limitNumber = 100;
      const _id = match ? (match.params ? match.params.id : null) : null;
      const response = await listUser(authToken, skipNumber, limitNumber, _id);
      //   console.log("Fetched Corporate Users:", response.data);

      this.setState({
        corporateUsers: response.data || [],
        isLoading: false,
      });
    } catch (err) {
      console.error("Failed to fetch corporate users:", err);
      this.setState({ isLoading: false });
      notification["error"]({
        message: "Error",
        description: "Failed to fetch corporate users.",
      });
    }
  };

  fetchCorporateCourses = async (pagination = this.state.pagination) => {
    const { authToken, match } = this.props;

    this.setState({ isLoading: true });

    try {
      const skipNumber = 0;
      const limitNumber = 100;
      const _id = match ? (match.params ? match.params.id : null) : null;
      //   console.log("ABC");
      const response = await listCourse(
        authToken,
        skipNumber,
        limitNumber,
        _id
      );
      //   console.log("Fetched Corporate Courses:", response);

      this.setState({
        corporateCourses: response.data || [],
        isLoading: false,
      });
    } catch (err) {
      console.error("Failed to fetch corporate courses:", err);
      this.setState({ isLoading: false });
      notification["error"]({
        message: "Error",
        description: "Failed to fetch corporate courses.",
      });
    }
  };

  fetchCorporateDepartments = async (pagination = this.state.pagination) => {
    const { authToken, match, LoginData } = this.props;

    this.setState({ isLoading: true });

    try {
      const skipNumber = 0;
      const limitNumber = 100;
      const _id = match ? (match.params ? match.params.id : null) : null;
      //   console.log("ABC");
      const response = await listDepartment(
        authToken,
        skipNumber,
        limitNumber,
        _id
      );
      //   console.log("Fetched Corporate Departments:", response);

      this.setState({
        corporateDepartments: response.data || [],
        isLoading: false,
      });
    } catch (err) {
      //   console.error("Failed to fetch corporate courses:", err);
      this.setState({ isLoading: false });
      notification["error"]({
        message: "Error",
        description: "Failed to fetch corporate courses.",
      });
    }
  };

  handleTableChange = (pagination) => {
    this.setState({ pagination }, () => this.fetchCorporateUsers(pagination));
    this.setState({ pagination }, () => this.fetchCorporateCourses(pagination));
    this.setState({ pagination }, () =>
      this.fetchCorporateDepartments(pagination)
    );
  };

  handleEditClick = (item) => {
    this.setState({
      selectedCourse: item,
      editModalVisible: true,
    });
  };

  handleEditUser = (item) => {
    console.log("Editing user:", item);
    this.setState(
      {
        selectedCourse: item,
        editUserModalVisible: true,
      },
      () => {
        console.log("Modal visibility:", this.state.editUserModalVisible);
      }
    );
  };

  handlePasswordChange = (item) => {
    console.log("Changing password for:", item);
    this.setState({
      selectedCourse: item,
      changePasswordModalVisible: true,
    });
  };
  
  // handlePasswordSubmit = (values) => {
  //   const { selectedUser } = this.state;
  //   const passwordData = {
  //     newPassword: values.new_password,
  //   };
    
  //   this.handleChangePassword(passwordData, selectedUser._id);
  // };  

  handleCloseModal = () => {
    this.setState({
      editModalVisible: false,
      selectedCourse: null,
      editUserModalVisible: false,
      changePasswordModalVisible: false,
    });
  };

  handleUserChange = (updatedUserData) => {
    this.setState({
      selectedCourse: updatedUserData,
    });
  };

  handleDeleteUser = async (item) => {

    // console.log("Item: ",item)

    const {authToken} = this.props;

    try {
      const response = await deleteCorporateUser(authToken, item);
  
      if (response.status === 200) {
        notification.success({ message: "User Deleted Successfully!" });
      } else {
        notification.error({
          message: response.message || "Failed to delete user.",
        });
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      notification.error({ message: "Something went wrong!" });
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  

  handleOnTabClick = (tabKey) => {
    this.setState({ activeCourseTab: tabKey });
  };

  handleAssign = async (selectedCourses) => {
    console.log("Assigned courses:", selectedCourses);

    this.fetchCorporateCourses();
  };

  handleDeleteGroupCourse = async (courseId) => {
    const { authToken, match } = this.props;
    const groupId = match ? (match.params ? match.params.id : null) : null;

    console.log("Group ID: ", groupId)
    try {
      const payload = {
        group_id: groupId,
        course_id: courseId,
      };
  
      const response = await DeleteGroupCourse(authToken, payload);
  
      notification.success({
        message: "Success",
        description: response.message || "Course deleted successfully!",
      });
  
      // Refresh course list after delete
      // this.fetchCorporateCourses();
  
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message || "Failed to delete course",
      });
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  

  onChangeStatus = async (item, checked) => {
    const { authToken } = this.props;

    try {
      console.log("Changing status for:", item._id, "New Status:", checked);

      // Call the API function
      const response = await changeStatusUser(authToken, item._id, checked);
      console.log("Response: ", response);

      if (response) {
        notification.success({
          message: "Status Updated",
          description: `User status has been changed.`,
        });

        // Refresh the user list to reflect changes
        this.fetchCorporateUsers();
      } else {
        throw new Error(response.message || "Failed to update status");
      }
    } catch (error) {
      console.error("Error changing status:", error);

      notification.error({
        message: "Error",
        description: "Failed to update status. Please try again.",
      });
    }
  };

  render() {
    const {
      isLoading,
      activeCourseTab,
      corporateUsers,
      pagination,
      serialCounter,
      corporateCourses,
      corporateDepartments,
      editModalVisible,
      selectedCourse,
      editUserModalVisible,
    } = this.state;

    const { LoginData } = this.props;
    // console.log(LoginData.role);

    return (
      <Fragment>
        <Title parent="Details" />
        <div className="container-fluid pb-5">
          {isLoading ? (
            <Spin
              size="large"
              style={{ marginLeft: "480px", marginTop: "130px" }}
            />
          ) : (
            <Content>
              <div className="card-container">
                <Card
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>User Details</span>
                      {this.props.LoginData.role !== "corporate" && (
                        <AssignCourse
                          authToken={this.props.authToken}
                          onSubmit={this.handleAssignCourse}
                          courses={this.state.courses}
                          groupId={this.props.match.params.id}
                          LoginData={this.state.LoginUserData}
                        />
                      )}
                    </div>
                  }
                >
                  <Tabs
                    type="card"
                    onTabClick={this.handleOnTabClick}
                    activeKey={activeCourseTab}
                    style={{ border: "1px solid #f0f0f0" }}
                  >
                    {/* USERS TAB (Only for Admin) */}
                    {LoginData.role !== "corporate" && (
                      <TabPane tab="Users" key="2">
                        <Table
                          columns={getUserTableColumns(
                            serialCounter,
                            this.onChangeStatus,
                            this.handleEditUser,
                            this.handlePasswordChange,
                            this.handleDeleteUser
                          )}
                          dataSource={corporateUsers}
                          rowKey={(record) => record._id}
                          pagination={{
                            ...pagination,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "25", "50", "100"],
                          }}
                          onChange={this.handleTableChange}
                        />
                      </TabPane>
                    )}

                    {/* COURSE TAB (Visible for both Corporate and Admin) */}
                    <TabPane tab="Course" key="1">
                      <Table
                        columns={getCourseTableColumns(
                          serialCounter,
                          this.onChangeStatus,
                          this.props.authToken,
                          this.handleEditClick,
                          this.handleDeleteGroupCourse,
                          this.props.LoginData
                        )}
                        dataSource={corporateCourses}
                        rowKey={(record) => record._id}
                        pagination={{
                          ...pagination,
                          showSizeChanger: true,
                          pageSizeOptions: ["10", "25", "50", "100"],
                        }}
                        onChange={this.handleTableChange}
                      />
                    </TabPane>

                    {/* DEPARTMENT TAB (Only for Admin) */}
                    {LoginData.role !== "corporate" && (
                      <TabPane tab="Department" key="3">
                        <Table
                          columns={getDepartmentTableColumns(
                            serialCounter,
                            this.onChangeStatus
                          )}
                          dataSource={corporateDepartments}
                          rowKey={(record) => record._id}
                          pagination={{
                            ...pagination,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "25", "50", "100"],
                          }}
                          onChange={this.handleTableChange}
                        />
                      </TabPane>
                    )}
                  </Tabs>
                </Card>
              </div>
            </Content>
          )}
        </div>

        {/* Render Modal */}
        {editModalVisible && (
          <EditCourseModal
            visible={editModalVisible}
            onClose={this.handleCloseModal}
            courseData={selectedCourse}
            authToken={this.props.authToken}
            groupId={this.props.match.params.id}
          />
        )}

        {editUserModalVisible && (
          <EditCorporateUser
            visible={editUserModalVisible}
            onClose={this.handleCloseModal}
            userData={selectedCourse}
            authToken={this.props.authToken}
            onChange={this.handleUserChange} // Assuming you have a function to handle changes
          />
        )}

        {this.state.changePasswordModalVisible && (
          <ChangePasswordForm
            visible={this.state.changePasswordModalVisible}
            onClose={this.handleCloseModal}
            userData={selectedCourse} // Pass user data
            authToken={this.props.authToken}
            onSubmit={this.handlePasswordChange}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  LoginData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(GroupAction));
