import { callApiToServer } from "../callApi";

export async function createDepartment(Token, departmentData) {
  try {

    // console.log("Token: ",Token)
    let headers = {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    };

    let endUrl = "v1/auth/admin/department";

    const response = await callApiToServer(
      JSON.stringify(departmentData),
      headers,
      "POST",
      endUrl
    );

    console.log("Create Department Response:", response);
    return response;
  } catch (err) {
    console.error("Create Department Error:", err);
    throw err;
  }
}
