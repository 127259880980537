import { Modal, Button } from "antd";
import * as React from "react";
import { Layout, Input, Select, Checkbox } from "antd";
import { Formik } from "formik";
import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";

const { Content } = Layout;
const Option = Select.Option;

export class AddGroup extends React.Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			data: [],
			view: false,
			isLoading: false,
			visible: false,
			selectedCourseType: "",
			isRestricted: false,
			isPrivate: false,
			errors: {
				selectedCourseType: "",
			},
		};
	}

	input;
	allCourses = [];

	async componentDidMount() {

		const { authToken, course_id } = this.props;

		let courseData = await listAllDropDownCourse(authToken, 0, 1000000);

		if (courseData.data && courseData.data.length >= 1) {
			courseData.data.map((data, index) => {
				this.allCourses.push(
					<Option value={data._id} key={data._id}>
						{data.name}
					</Option>
				);
			});
		}

		if (course_id) {
			this.setState({
				selectedCourseType: course_id
			})
		}
	}

	validateForm = (values) => {
		const { selectedCourseType } = this.state;

		const errors = {};

		console.log("Validating form -- ", values);

		if (!values.name) {
			errors.name = "Name is required";
		}

		if (!selectedCourseType) {
			errors.selectedCourseType = "Please select course";
		}

		console.log("Validating errors -- ", errors);
		return errors;
	}

	handleSubmit = (values, action) => {

		const { selectedCourseType } = this.state;

		values.course_id = selectedCourseType;

		this.props.onSubmit(values);

		this.setState({
			visible: false,
		});
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = (e) => {
		this.setState({
			visible: false,
		});
	};

	handleCancel = (e) => {
		this.setState({
			visible: false
		});
	};

	changeCourse = (value) => {
		this.setState({
			selectedCourseType: value,
		});
	};

	onChangeIsRestricted() {
		this.setState({
			isRestricted: !this.state.isRestricted,
		});
	}

	onChangeIsPrivate() {
		this.setState({
			isPrivate: !this.state.isPrivate,
		});
	}

	render() {
		const { primaryColor, course_id } = this.props;
		const { selectedCourseType, isPrivate, isRestricted } = this.state;

		return (
			<div>
				<div
					onClick={this.showModal}
					style={{
						width: "100px",
						backgroundColor: primaryColor,
						color: "white",
						padding: "7px 0px",
						textAlign: "center",
						borderRadius: "50px",
					}}
				>
					ADD
				</div>

				<Modal
					destroyOnClose={true}
					name="Add Group"
					visible={this.state.visible}
					onCancel={this.handleCancel}
					footer={null}
					maskClosable={false}
				>
					<Content style={{ background: "#fff", marginLeft: "10px" }}>
						<Formik
							initialValues={{
								name: ""

							}}
							validate={this.validateForm}
							onSubmit={this.handleSubmit}
						>
							{({
								  values,
								  errors,
								  touched,
								  handleChange,
								  handleBlur,
								  handleSubmit,
								  setFieldValue,
								  setFieldTouched,
								  isSubmitting,
							  }) => {
								return (
									<div>
										<div className="formik-field_wrap">
											<div className="formik-field_wrap">
												<div className="formik-field-left">
													Name
													<Input
														id="name"
														placeholder="Name"
														value={values.name}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
													{errors.name && touched.name ? (
														<p
															style={{
																color: "red",
																fontSize: "small",
																margin: "0",
															}}
														>
															{errors.name}
														</p>
													) : null}
												</div>

												<div className="formik-field-right">
													Course
													{course_id ?
														<Select
															style={{ width: "100%" }}
															placeholder="Select Course"
															disabled
															value={selectedCourseType}
															optionFilterProp="children"
															filterOption={(input, option) =>
																option.children
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															}
														>
															{this.allCourses}
														</Select>
														:
														<Select
															allowClear
															style={{ width: "100%" }}
															onChange={(value) => this.changeCourse(value)}
															placeholder="Select Course"
															showSearch
															optionFilterProp="children"
															filterOption={(input, option) =>
																option.children
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															}
														>
															{this.allCourses}
														</Select>
													}
													{errors.selectedCourseType ? (
														<p
															style={{
																color: "red",
																fontSize: "small",
																margin: "0",
															}}
														>
															{errors.selectedCourseType}
														</p>
													) : null}
												</div>
											</div>

											<div className="formik-field_wrap">
												<div className="formik-field-left">
													<Checkbox
														onChange={(value) => this.onChangeIsRestricted()}
														checked={isRestricted}
													>
														is Restricted?
													</Checkbox>
													{this.state.errors.isRestricted ? (
														<p
															style={{
																color: "red",
																fontSize: "small",
																margin: "0",
															}}
														>
															{this.state.errors.isRestricted}
														</p>
													) : null}
												</div>

												<div className="formik-field-right">
													<Checkbox
														onChange={(value) => this.onChangeIsPrivate()}
														checked={isPrivate}
													>
														is Private?
													</Checkbox>
													{this.state.errors.isPrivate ? (
														<p
															style={{
																color: "red",
																fontSize: "small",
																margin: "0",
															}}
														>
															{this.state.errors.isPrivate}
														</p>
													) : null}
												</div>
											</div>

											{/* <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Course
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={(value) => this.changeCourse(value)}
                            placeholder="Select Course"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.allCourses}
                          </Select>
                          {this.state.errors.selectedCourseType ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.selectedCourseType}
                            </p>
                          ) : null}
                        </div>
                      </div> */}

											<Button type="primary" style={{ marginTop: "19px", background: "#5C6AC4", color: "#fff", }}
											        onClick={handleSubmit}>
												Add
											</Button>
										</div>
									</div>
								);
							}}
						</Formik>
					</Content>
				</Modal>
			</div>
		);
	}
}
