import { Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input } from "antd";
import { Formik } from "formik";
import styled from "styled-components";
import { withRouter } from "react-router";

const { Content } = Layout;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      time: "",
      timeString: "",
      roleType: "1",
    };
  }

  input;

  componentDidMount() {
    this.setState({
      isLoading: false,
    });
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.new_password) {
      errors.new_password = "New Password is required";
    } else if (values.new_password !== "" && values.new_password.length < 8) {
      errors.new_password = "At least 8 characters required";
    } 
    if (!values.confirm_new_password) {
      errors.confirm_new_password = "Confirm New Password is required";
    }

    if (values.new_password !== values.confirm_new_password) {
      errors.confirm_new_password =
        "New Password and Confirm New Password does not match";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit = async (values, action) => {
    this.props.onSubmit(values, this.props.data._id);
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <Formik
          initialValues={{
            old_password: "",
            new_password: "",
            confirm_new_password: "",
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div className="container-fluid p-20">
                <div className="form-group">
                  <div className="formik-field">
                    New Password
                    <Input
                      id="new_password"
                      type="password"
                      placeholder="New Password"
                      value={values.new_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.new_password && touched.new_password ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.new_password}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <div className="formik-field">
                    Confirm New Password
                    <Input
                      id="confirm_new_password"
                      type="password"
                      placeholder="Confirm New Password"
                      value={values.confirm_new_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.confirm_new_password &&
                    touched.confirm_new_password ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.confirm_new_password}
                      </p>
                    ) : null}
                  </div>
                </div>

                <Button
                  // type="primary"
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default withRouter(ChangePasswordForm);
