import { callApiToServer } from "../callApi";

export async function changePassword(authtoken,formData, itemId) 
  {
    // console.log(authtoken)
    try {
      let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "authorization": "Bearer " + authtoken  
      };
  
      let body = JSON.stringify({
        newPassword: formData.new_password,
        user_id: itemId
      });
  
      let endUrl = "v1/auth/admin/change-password";
      console.log("Calling API:", endUrl);
  
      let responseData = await callApiToServer(body, header, "POST", endUrl);
  
      console.log("API response:", responseData);
  
      return responseData;
  
    } catch (err) {
      throw err;
    }
  }
  