import React from "react";
import { Modal, Input, Form, Button, notification } from "antd";
import { EditGroupCourse } from "../../Graphs/CorporateGroups/EditMaxUser";

class EditCourseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      maxUsers: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      // Set initial value when modal opens
      this.setState({
        maxUsers:
          this.props.courseData &&
          this.props.courseData.courses &&
          this.props.courseData.courses.max_user_count
            ? this.props.courseData.courses.max_user_count
            : "",
      });
    }
  }

  handleChange = (e) => {
    const value = Math.max(0, Number(e.target.value) || 0); // Prevent negative values
    this.setState({ maxUsers: value });
  };

  handleSubmit = async () => {
    const { groupId, courseData, authToken, onClose } = this.props;
    const { maxUsers } = this.state;

    try {
      this.setState({ loading: true });

      const payload = {
        group_id: groupId,
        course_id: courseData.courses.course_id ? courseData.courses.course_id : null,
        max_user_count: Number(maxUsers),
      };

      const response = await EditGroupCourse(authToken, payload);

      notification.success({
        message: "Success",
        description: response.message || "Course updated successfully!",
      });

      onClose(); // Close modal after success
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message || "Failed to update course",
      });
    } finally {
      this.setState({ loading: false });
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  render() {
    const { visible, onClose } = this.props;
    const { loading, maxUsers } = this.state;

    return (
      <Modal
        title="Edit Course"
        visible={visible}
        onCancel={onClose}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={this.handleSubmit}
          >
            Update
          </Button>,
        ]}
      >
        {/* Max Users */}
        <div style={{ flex: 1 }}>
          Max Users
          <Input
            type="number"
            placeholder="Max Users"
            value={maxUsers}
            onChange={this.handleChange}
            style={{ width: "100%" }}
          />
        </div>
      </Modal>
    );
  }
}

export default EditCourseModal;
