/* eslint-disable */

import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Default from "../components/dashboard/defaultCompo/default";
import Login from "../components/Login/Login";
import App from "../components/app";
import { connect } from "react-redux";
import { AuthRouteGaurd } from "./AuthRouteGuard";
import { UnAuthRouteGaurd } from "./UnAuthRouteGuard";
import Category from "../components/Category";
import User from "../components/User";
import Admin from "../components/Admin";
import Course from "../components/Course";
import CourseSession from "../components/CourseSession";
import CourseSessionTopic from "../components/CourseSessionTopic";
import Language from "../components/Language";
import Module from "../components/Module";
import Faq from "../components/Faq";
import SubCategory from "../components/SubCategory";
import CourseDetailPage from "../components/Course/CourseDetailPage";
import Testimonial from "../components/Testimonial";
import EditProfile from "../components/AdminUser/EditProfile";
import ChangeAdminEmail from "../components/AdminUser/ChangeAdminEmail";
import ChangeAdminPassword from "../components/AdminUser/ChangeAdminPassword";
import Group from "../components/Group";
import AppContent from "../components/AppContent";
import QuizPreview from "../components/User/PreviewTopicQuiz"
import GeneralFaq from "../components/generalFaq";
import Department from "../components/Department";
import CorporateGroup from "../components/CorporateGroups";
import GropuActionPage from "../components/CorporateGroups/GropuActionPage";
import { ListModel } from "../components/User/ListModel";

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loginFlag } = this.props;
    console.log("!!!!!!!!!!!!!!!loginFlag in router printed here", loginFlag);

    return (
      <Switch>
        <UnAuthRouteGaurd
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
        />
        <Fragment>
          <App>
            {/* dashboard menu */}
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={Default}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/dashboard`}
              component={Default}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/category`}
              component={Category}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/courses`}
              component={Course}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/course-session`}
              component={CourseSession}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/course-session-topic`}
              component={CourseSessionTopic}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/language`}
              component={Language}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/module`}
              component={Module}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/faq`}
              component={Faq}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/general_faq`}
              component={GeneralFaq}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/department`}
              component={Department}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/corporategroup`}
              component={CorporateGroup}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/corporategroupaction/:id`}
              component={GropuActionPage}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/sub-category`}
              component={SubCategory}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/user`}
              component={User}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/userCourse`}
              component={ListModel}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/admin`}
              component={Admin}
            />
            <AuthRouteGaurd
              path={`${process.env.PUBLIC_URL}/course/detail/:courseId`}
              component={CourseDetailPage}
            />
            <AuthRouteGaurd
              path={`${process.env.PUBLIC_URL}/testimonial`}
              component={Testimonial}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/edit-profile`}
              component={EditProfile}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/edit-profile/mail`}
              component={ChangeAdminEmail}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/edit-profile/password`}
              component={ChangeAdminPassword}
            />
            <AuthRouteGaurd
              path={`${process.env.PUBLIC_URL}/group`}
              component={Group}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/static-pages`}
              component={AppContent}
            />
            {/* <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/user/course/topics/preview/:userId/:courseId`}
              component={QuizPreview}
            /> */}
          </App>
        </Fragment>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  loginFlag: state.auth.loginFlag,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
