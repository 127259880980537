import { callApiToServer } from "../callApi";

export async function uploadGroupCsv(Token, formData) {
    if (!formData.has('file')) {
      throw new Error("File is missing in FormData");
    }
  
    try {
      let headers = {
        Authorization: `Bearer ${Token}`,
        // "Content-Type": "multipart/form-data",
      };
  
      let endUrl = "/file/upload/group_csv/file";
  
      const response = await callApiToServer(formData, headers, "POST", endUrl);
      console.log("Upload response:", response);
      return response;
    } catch (err) {
      console.error("Upload Error:", err);
      throw err;
    }
  }
  
