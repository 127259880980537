import React, { Component } from "react";
import { Table, Tooltip, Button } from "antd";
import { Modal } from "antd";
import { getUserCourseList } from "../../Graphs/User/listUserCourse";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import PreviewTopicQuiz from "./PreviewTopicQuiz";
import EditDateModal from "./EditDateModal";

class ListUserCourseData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      showCoursesModal: false,
      selectedCourse: null,
      showEditDateModal: false,  // State to manage EditDateModal
      selectedItem: null,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedUser = this.getLimitedUser.bind(this);
  }

  async getLimitedUser(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchUserData(start, end);
        } else {
          //call normal user Data
          this.listUserCourseData(start, end);
        }
      }
    );
  }

  openEditDateModal = (item) => {
    this.setState({
      showEditDateModal: true,
      selectedItem: item,
    });
  };

  closeEditDateModal = () => {
    this.setState({ showEditDateModal: false, selectedItem: null });
  };

  listUserCourseData = async (start, end) => {
    try {
      // console.log("listUserCourseData");
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListUserCourseData,
        setListUserDataTotal,
        type,
        adminSelectedData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      console.log(this.props.adminSelectedData);

      let userData = await getUserCourseList(
        authToken,
        { ...this.props.location.state.adminSelectedData, type },
        start,
        end
      );

      if (userData.status === 200) {
        //set user Data
        await setListUserCourseData(userData.data);
        // await setListUserCourseData(userData.data.docs);
        await setListUserDataTotal(userData.total);

        this.setState({
          isLoading: false,
          data: userData.data,
        });
      } else if (userData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };
  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedUser(pagination)
    );
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 70,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      width: 150,
      key: "courseName",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <strong>
              <Tooltip
                placement="bottom"
                title={item && item.courses ? item.courses.name : "-"}
              >
                <div>{item && item.courses ? item.courses.name : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Start Date",
      width: 100,
      key: "start_date",
      render: (text, item, index) => {
        const formattedDate = item.courses.start_date
          ? moment(item.courses.start_date).format("DD MMM YYYY")
          : "-";

        return (
          <div>
            <strong>
              {/* <Tooltip placement="bottom" title="Start Date"> */}
              <div>{formattedDate}</div>
              {/* </Tooltip> */}
            </strong>
          </div>
        );
      },
    },
    {
      title: "Completed Date",
      width: 100,
      key: "completedAt",
      render: (text, item, index) => {
        const formattedDate =
          item && item.courses && item.courses.completedAt
            ? moment(item.courses.completedAt).format("DD MMM YYYY")
            : "-";

        return (
          <div>
            <strong>
              {/* <Tooltip placement="bottom" title="Completed Date"> */}
              <div>{formattedDate}</div>
              {/* </Tooltip> */}
            </strong>
          </div>
        );
      },
    },
    {
      title: "Progress",
      width: 130, 
      key: "progress",
      render: (text, item, index) => {
        const completedTopics = item.courses.completed_topics_count || 0;
        const totalTopics = item.courses.topics_count || 0;
        const progress = totalTopics > 0 ? (completedTopics / totalTopics) * 100 : 0;
    
        return (
          <div>
            <strong> {progress.toFixed(1)}%  </strong>
          </div>
        );
      },
    },
    {
      title: "Score",
      width: 160,
      key: "score",
      render: (text, item, index) => {
        // console.log("user record --> ", item);
        return (
          <div>
            <strong>
              <Tooltip
                placement="bottom"
                title={item && item.courses ? item.courses.score : "-"}
              >
                <div>{item && item.courses ? item.courses.score : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 170,
      key: "action",
      render: (text, item) => (
        <div style={{ display: "inline-block", width: "100%" }}>
          {/* View Courses Button */}
          <Tooltip placement="bottom" title="View Courses">
            <Button
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() =>
                this.setState({
                  showCoursesModal: true,
                  selectedCourse: {
                    userId: item.user_id ? item.user_id : "",
                    courseId: item.courses.course_id ? item.courses.course_id : "",
                  },
                })
              }
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Actions",
      width: 170,
      key: "actionss",
      render: (text, item) => (
        <div style={{ display: "inline-block", width: "100%" }}>
          {/* Edit Date Button */}
          <Tooltip placement="bottom" title="Edit Date">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              style={{ marginLeft: "10px" }} // Add spacing
              onClick={() =>
                this.setState({
                  showEditDateModal: true, // Open EditDateModal
                  selectedItem: item, // Pass selected item details
                  selectedCourse: {
                    userId: item.user_id ? item.user_id : "",
                    courseId: item.courses.course_id ? item.courses.course_id : "",
                  },
                })
              }
            />
          </Tooltip>
        </div>
      ),
    }
    
  ];

  componentDidMount() {
    console.log("componentDidMount");
    this.listUserCourseData(0, 10);
  }
  render() {
    const { listUserCourseData, type, loginUserData } = this.props;
    // console.log(loginUserData)

    console.log(listUserCourseData);

    if (type === "inprogess") {
      this.columns = this.columns.filter(
        (item) => !["completedAt", "score", "action"].includes(item.key)
      );
      if(loginUserData.role === "corporate") {
        this.columns = this.columns.filter(
          (item) => !["actionss"].includes(item.key)
        )
      }
      
    }
    if (type === "completed") {
      this.columns = this.columns.filter(
        (item) => !["progress", "actionss"].includes(item.key)
      );
    }
    return (
      <div>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          dataSource={listUserCourseData}
          onChange={this.handleTableChange}
        />

        <Modal
          title="Quiz Details"
          visible={this.state.showCoursesModal}
          onCancel={() => this.setState({ showCoursesModal: false })}
          footer={null}
          maskClosable={false}
          width={1300}
        >
          {this.state.selectedCourse && (
            <PreviewTopicQuiz
              userId={this.state.selectedCourse.userId}
              courseId={this.state.selectedCourse.courseId}
            />
          )}
        </Modal>

        {/* Edit Date Modal */}
        {this.state.showEditDateModal && (
          <EditDateModal
            visible={this.state.showEditDateModal}
            onClose={this.closeEditDateModal} // Function to close modal
            selectedItem={this.state.selectedItem} // Pass selected data
            authToken={this.props.authToken}
            userId={this.state.selectedCourse.userId}
            courseId={this.state.selectedCourse.courseId}
          />
        )}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listUserCourseData: state.user.listUserCourseData,
  listUserDataTotal: state.user.listUserDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserCourseData: (listUserCourseData) => {
      dispatch({
        type: "SET_LIST_USER_COURSE_DATA",
        listUserCourseData: listUserCourseData,
      });
    },
    setListUserDataTotal: (listUserDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: listUserDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListUserCourseData));
