import React, { useState } from "react";
import { Modal, Button, Input, notification } from "antd";
import { editCorporate } from "../../Graphs/CorporateGroups/editcorporateusers";

const EditCorporateUser = ({ 
  visible, 
  onClose, 
  userData, 
  onChange, 
  authToken,  
}) => {
  // console.log("User Data: ", authToken);
  
  // State for form data
  const [formData, setFormData] = useState(userData || {});

  // Handle input changes
  const handleChange = (field, value) => {
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);
    if (onChange) onChange(updatedData); // Callback to update parent state if needed
  };

  // Handle edit user
  const handleEditAdmin = async () => {

    try {
      const response = await editCorporate(authToken, formData, userData._id);
      if (response.status === 200) {
        notification.success({ message: "User Updated Successfully!" }); 
        onClose(); 
      } else {
        notification.error({
          message: response.message || "Failed to update user.",
        });
      }
    } catch (error) {
      console.error("Error updating user:", error);
      notification.error({ message: "Something went wrong!" });
    }

    onClose();

    setTimeout(() => {
      window.location.reload();
    }, 1000);
    
  };

  return (
    <Modal
      title="Edit User"
      visible={visible} // Changed from `visible` (deprecated) to `open`
      onCancel={onClose}
      maskClosable={false}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Close
        </Button>,
        <Button key="edit" type="primary" onClick={handleEditAdmin}>
          Edit
        </Button>,
      ]}
    >
      {userData ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {/* First Name */}
          <div style={{ display: "flex", gap: "16px" }}>
            <div style={{ flex: 1 }}>
              <p>
                First Name:
              </p>
              <Input
                value={formData.first_name}
                onChange={(e) => handleChange("first_name", e.target.value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <p>
                Last Name:
              </p>
              <Input
                value={formData.last_name}
                onChange={(e) => handleChange("last_name", e.target.value)}
              />
            </div>
          </div>

          {/* Email (Read-only) */}
          <div>
            <p>Email:</p>
            <Input value={formData.email} disabled />
          </div>
        </div>
      ) : (
        <p>No user data available.</p>
      )}
    </Modal>
  );
};

export default EditCorporateUser;
