import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllCategory } from "../../Graphs/Category/listCategory";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import CategoryRecordsTable from "./CategoryRecordsTable";
import { AddCategory } from "./AddCategory";
import { addCategory } from "../../Graphs/Category/addCategory";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      categoryTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (this.props.loginUserData.role === "corporate") {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("CATEGORY");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listCategoryData();
    } catch (e) {
      notification["success"]({
        message: "Category",
        description: "Somwthing went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listCategoryData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListCategoryData,
      setListCategoryDataTotal,
    } = this.props;

    try {
      let categoryData = await getAllCategory(authToken, 0, 10);

      if (categoryData.status === 200) {
        //set category data
        // await setListCategoryData(categoryData.data.docs);
        await setListCategoryData(categoryData.data);
        await setListCategoryDataTotal(categoryData.total);

        this.setState({
          categoryList: categoryData.data,
          // categoryList: categoryData.data.docs,
          categoryTotal: categoryData.total,
        });
      } else if (categoryData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { history, authToken, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });
      let addCategoryData = await addCategory(authToken, values);
      if (addCategoryData.status === 200) {
        //set category data
        await this.listCategoryData();
      } else if (addCategoryData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!e", e);
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const { categoryList, categoryTotal, loginUserData } = this.props;

    return (
      <Fragment>
        <Title parent="Category" title="category" />

        {checkPermission(loginUserData.permissions, "category", "create") && (
          <div
            style={{
              marginTop: "20px",
              display: "inline-block",
              width: "100%",
              marginBottom: "20px",
              paddingLeft: "14px",
              paddingRight: "55px",
            }}
          >
            <div style={{ float: "left", cursor: "pointer" }}>
              <AddCategory
                primaryColor={primaryColor}
                onSubmit={this.addSubmit}
                loginData={this.props.loginUserData}
              />
            </div>
          </div>
        )}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <CategoryRecordsTable
                listCategoryData={categoryList}
                primaryColor={primaryColor}
                listCategoryDataTotal={categoryTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  categoryList: state.category.listCategoryData,
  categoryTotal: state.category.listCategoryDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCategoryData: (listCategoryData) => {
      dispatch({
        type: "SET_LIST_CATEGORY_DATA",
        listCategoryData: listCategoryData,
      });
    },
    setListCategoryDataTotal: (listCategoryDataTotal) => {
      dispatch({
        type: "SET_LIST_CATEGORY_DATA_TOTAL",
        listCategoryDataTotal: listCategoryDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Category));
