import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
  Tag,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCourse } from "../../Graphs/Course/listCourse";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { EditModal } from "./EditModal";
import { deleteCourse } from "../../Graphs/Course/deleteCourse";
import { editCourse } from "../../Graphs/Course/editCourse";
import { changeStatus } from "../../Graphs/Course/changeStatus";
import { searchCourse } from "../../Graphs/Course/searchCourse";
import { Link } from "react-router-dom";
import { checkPermission } from "../AdminUser/CheckAdminPermission";
var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class CourseRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedCourse = this.getLimitedCourse.bind(this);
  }

  async componentDidMount() {
    
      // Keep existing state logic
      this.setState({
        data: this.props.data,
        isLoading: false,
        userSelectedData: null,
      });
    }

  modalRef;

  async getLimitedCourse(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchCourseData(start, end);
        } else {
          //call normal user Data
          this.listCourseData(start, end);
        }
      }
    );
  }

  listSearchCourseData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCourseData,
        setListCourseDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let courseData = await searchCourse(authToken, searchText, start, end);

      if (courseData.status === 200) {
        //set user Data
        await setListCourseData(courseData.data);
        // await setListCourseData(courseData.data.docs);
        await setListCourseDataTotal(courseData.total);

        this.setState({
          isLoading: false,
          data: courseData.data,
          // data: courseData.data.docs,
        });
      } else if (courseData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listCourseData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCourseData,
        setListCourseDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let courseData = await getAllCourse(authToken, start, end);

      if (courseData.status === 200) {
        //set user Data
        await setListCourseData(courseData.data);
        // await setListCourseData(courseData.data.docs);
        await setListCourseDataTotal(courseData.total);

        this.setState({
          isLoading: false,
          data: courseData.data,
        });
      } else if (courseData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  showModal(item) {
    console.log("open modal", item);
    this.setState(
      {
        courseSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      userSelectedData: null,
    });
  }

  handleSingleCourseData = async (item) => {
    const { setSingleCourseData } = this.props;

    await setSingleCourseData(item);
  };

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 70,
      render: (text, item, index) => {
        const { current, pageSize } = this.state.pagination;
        const serialNumber = (current - 1) * pageSize + index + 1;
        return (
          <div>
            <span>{serialNumber}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Name">
                <div>{item.name ? item.name : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Slug",
      key: "slug_name",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Slug">
                <div>{item.slug_name ? item.slug_name : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Author",
      key: "author",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Author">
                <div>{item.author ? item.author : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    // {
    //   title: "Languages",
    //   key: "Languages",
    //   render: (text, item, index) => {
    //     let languageString = "";
    //     item.languages &&
    //       item.languages.map((data, index) => {
    //         if (index === item.languages.length - 1) {
    //           languageString = languageString + data.language_id.name;
    //           return;
    //         }

    //         languageString = languageString + data.language_id.name + ", ";
    //       });
    //     return (
    //       <div>
    //         <Tooltip placement="bottom" title="Languages">
    //           <div>{languageString ? languageString : "-"}</div>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Difficulty Level",
      key: "Difficulty Level",
      render: (text, item, index) => {
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Difficulty Level">
                <div>{item.difficulty_level ? item.difficulty_level : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Price",
      key: "price",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Price">
              <div>{item.price ? String(item.price) : "0"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Paid",
      key: "Paid",
      render: (text, item, index) => {
        return (
          <div>
            <Tooltip placement="bottom" title="Paid">
              {item.is_paid ? (
                <Tag color="success">Paid</Tag>
              ) : (
                <Tag color="blue">Free</Tag>
              )}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Published",
      key: "is_active",
      render: (text, item, index) => {
        const { loginUserData } = this.props;
        // const role = checkPermission(loginUserData, "users", "role");
        console.log(loginUserData.role)
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="Is Published">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                    disabled={loginUserData.role === "corporate"}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 150,
      key: "action",
      render: (text, item, index) => {
        const { loginUserData } = this.props;
        const canEdit = checkPermission(loginUserData, "users", "edit");
        const canDelete = checkPermission(loginUserData, "users", "delete");

        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {canEdit && (
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            )}
              <div
                ml="15px"
                mr="5px"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <Link to={`course/detail/${item._id}`}>
                  <Tooltip placement="bottom" title="View Course">
                    <Button
                      shape="circle"
                      icon={<EyeOutlined />}
                      onClick={() => this.handleSingleCourseData(item)}
                    />
                  </Tooltip>
                </Link>
              </div>

            {canDelete && (
              <div
                ml="15px"
                mr="5px"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={this.deleteCourseData.bind(this, item)}
                    title="Are you sure you want to delete Course?"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  async deleteCourseData(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCourseData,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteCourse(authToken, item._id);

      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete Course",
          description: "Successfully deleted",
        });

        await this.listCourseData(0, 10);
      } else if (deleteData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Delete Category",
        description: e,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item._id, checked);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Change Course Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listCourseData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (err) {
      notification["error"]({
        message: "Change Course Status",
        description: err,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (courseObject, courseId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editCourse(authToken, courseObject, courseId);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Edit Course Status",
          description: "Successfully edited",
        });
        {
          /*
          if (searchText != null && searchText !== "") {
            this.listSearchCategoryData(startIndex, limitNumber);
            return;
          }*/
        }
        this.listCourseData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Edit Course",
        description: e,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCourse(pagination)
    );
  }

  render() {
    const { isLoading, data } = this.state;
    const { courseList, courseTotal, searchLoader } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ overflowX: "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={courseList}
          pagination={{
            total: courseTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />

        {/* {this.state.courseSelectedData &&
        checkPermission(
          this.props.loginUserData.permissions,
          "course",
          "edit"
        ) ? ( */}
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            courseSelectedData={this.state.courseSelectedData}
            authToken={this.props.authToken}
            onSubmit={this.editSubmit}
          />
        {/* ) : null} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listCourseData: state.course.listCourseData,
  listCourseDataTotal: state.course.listCourseDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCourseData: (listCourseData) => {
      dispatch({
        type: "SET_LIST_COURSE_DATA",
        listCourseData: listCourseData,
      });
    },
    setListCourseDataTotal: (listCourseDataTotal) => {
      dispatch({
        type: "SET_LIST_COURSE_DATA_TOTAL",
        listCourseDataTotal: listCourseDataTotal,
      });
    },
    setSingleCourseData: (data) => {
      dispatch({
        type: "SET_SINGLE_COURSE_DATA",
        singleCourseData: data,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseRecordsTable));
