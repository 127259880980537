import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Input, Select, Button, Spin, Icon, notification } from "antd";
import LanguageRecordsTable from "./LanguageRecordsTable";
import { getAllLanguage } from "../../Graphs/Language/listLanguage";
import { addLanguage } from "../../Graphs/Language/addLanguage";
import { AddLanguage } from "./AddLanguage";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (this.props.loginUserData.role === "corporate") {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("LANGUAGE");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });
      await this.listLanguageData();
    } catch (e) {
      console.log("!!!!!!!!error in did mount", e);
    }
  }

  listLanguageData = async () => {
    try {
      const {
        authToken,
        setUserLogout,
        setListLanguageData,
        setListLanguageDataTotal,
      } = this.props;
      let languageData = await getAllLanguage(authToken, 0, 10);
      if (languageData.status == 200) {
        //set user data
        await setListLanguageData(languageData.data);
        await setListLanguageDataTotal(languageData.data.total);
      } else if (languageData.status == 401) {
        await setUserLogout();
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { authToken, setUserLogout } = this.props;
      this.setState({
        isLoading: true,
      });
      let addLanguageData = await addLanguage(authToken, values);
      await this.listLanguageData();
    } catch (e) {
      console.log("!!!!!!!!e", e);
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const { listLanguageData, listLanguageDataTotal, loginUserData } =
      this.props;

    return (
      <Fragment>
        <Title parent="Language" title="language" />

        {checkPermission(loginUserData.permissions, "language", "create") && (
          <div
            style={{
              marginTop: "20px",
              display: "inline-block",
              width: "100%",
              marginBottom: "20px",
              paddingLeft: "14px",
              paddingRight: "55px",
            }}
          >
            <div style={{ float: "left", cursor: "pointer" }}>
              <AddLanguage
                primaryColor={primaryColor}
                onSubmit={this.addSubmit}
                loginData={this.props.loginUserData}
              />
            </div>
          </div>
        )}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <LanguageRecordsTable
                listLanguageData={listLanguageData}
                primaryColor={primaryColor}
                listLanguageDataTotal={listLanguageDataTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listLanguageData: state.language.listLanguageData,
  listLanguageDataTotal: state.language.listLanguageDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUserLogout: () => {
      dispatch({
        type: "USER_LOGOUT",
      });
    },
    setListLanguageData: (listLanguageData) => {
      dispatch({
        type: "SET_LIST_LANGUAGE_DATA",
        listLanguageData: listLanguageData,
      });
    },
    setListLanguageDataTotal: (listLanguageDataTotal) => {
      dispatch({
        type: "SET_LIST_LANGUAGE_DATA_TOTAL",
        listLanguageDataTotal: listLanguageDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Language));
