import { callApiToServer } from "../callApi";

export async function AssignCourse(Token, data) {
  try {
    let header = {
    //   Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Berear ${Token}` ,
    };

    let endUrl = `v1/auth/admin/assign_course`;

    const body = JSON.stringify(data)
    console.log(body)

    let responseData = await callApiToServer(body, header, "POST", endUrl,);

    return responseData;
  } catch (err) {
    throw err;
  }
}
