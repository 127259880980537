import React from "react";
import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Spin,
  Tag,
  notification,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { getCorporateGroups } from "../../Graphs/CorporateGroups/lsitCorporateGroups";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AddCorporateUser } from "./AddCorporate";
import { EditForm } from "./EditFrom";
import { auth } from "firebase";
import { ChangePassowordModal } from "./ChangePassswordModal";
import { changeStatus } from "../../Graphs/CorporateGroups/changeStatus";
import { listCourse } from "../../Graphs/CorporateGroups/listCourse";
import { deleteCorporate } from "../../Graphs/CorporateGroups/deletecourporategroup";

class CorporateGroupTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      selectedItem: null, // Store the selected item
      visible: false,
      passvisible: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
      },
    };
  }

  changePasswordmodalRef;

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async (pagination = this.state.pagination) => {
    const { authToken, searchQuery } = this.props;
    const skipNumber = (pagination.current - 1) * pagination.pageSize;
    const limitNumber = pagination.pageSize;

    this.setState({ isLoading: true });

    try {
      const response = await getCorporateGroups(
        authToken,
        skipNumber,
        limitNumber,
        searchQuery
      );
      // console.log(response)
      if (response.status === 200) {
        this.setState({
          data: response.data,
          pagination: {
            ...pagination,
            total: response.total || 0,
          },
          startIndex: skipNumber,
          limitNumber: limitNumber,
          isLoading: false,
        });
      } else {
        throw new Error(response.message || "Failed to fetch data");
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message,
      });
      this.setState({ isLoading: false });
    }
  };

  handleTableChange = (pagination) => {
    this.setState({ pagination }, () => this.fetchData(pagination));
  };

  handleEdit = (item) => {
    // console.log("Edit item:", item);
    // Open modal for editing
  };

  showModal = (item) => {
    // console.log("ANCD", item);
    this.setState({ selectedItem: item, visible: true });
  };

  handleStatusChange = async (item, checked) => {
    const { authToken } = this.props; // Get authToken from props
  
    try {
      // Optimistically update the UI
      this.setState((prevState) => ({
        data: prevState.data.map((user) =>
          user._id === item._id ? { ...user, is_active: checked } : user
        ),
      }));
  
      // Call API to update status
      const response = await changeStatus(authToken, item._id, checked);
  
      if (response.status === 200) {
        notification.success({
          message: "Success",
          description: "Status updated successfully!",
        });
      } else {
        throw new Error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      notification.error({
        message: "Error",
        description: "Failed to update status",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
  
      // Revert UI change if API fails
      this.setState((prevState) => ({
        data: prevState.data.map((user) =>
          user._id === item._id ? { ...user, is_active: !checked } : user
        ),
      }));
    }
  };  


  handleDeleteUser = async (id) => {
    const { authToken } = this.props;
    // console.log("ID: ", id)
  
    try {
      const response = await deleteCorporate(authToken, id);
  
      notification.success({
        message: "Success",
        description: response.message || "Admin deleted successfully!",
      });
  
      // Refresh list after delete if needed
      // this.fetchAdminList();
  
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message || "Failed to delete admin",
      });
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);

    await this.fetchData();
  };
  

  showChangePasswordModal(item) {
    // console.log("open modal", item);
    this.changePasswordmodalRef.show();
    this.setState({ selectedItem: item});
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedType: "",
    });
  };

  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => (
        <div>{index + 1 + this.state.startIndex}</div>
      ),
    },
    {
      title: "Corporate Group Name",
      key: "name",
      render: (text, item) => (
        <Tooltip title="Group Name">
          <div>
            {" "}
            <strong> {item.first_name || "-"} </strong>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Total Department",
      key: "type",
      render: (text, item) => (
        <Tooltip title="Department">
          {/* <Tag color={item.type === "private" ? "blue" : "green"}> */}
            {item.total_department || "-"}
          {/* </Tag> */}
        </Tooltip>
      ),
    },
    {
      title: "Active",
      key: "is_active",
      render: (text, item) => (
        <Switch
          checked={item.is_active}
          onChange={(checked) => this.handleStatusChange(item, checked)}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, item) => (
        <div ml="15px" mr="5px" style={{ float: "left", marginLeft: "20px" }}>
          <Link to={`/corporategroupaction/${item._id}`}>
            <Tooltip placement="bottom" title="View Course">
              <Button shape="circle" icon={<EyeOutlined />} />
            </Tooltip>
          </Link>
          <div mr="5px" style={{ float: "left", marginLeft: "10px" }}>
            <Tooltip placement="bottom" title="Edit">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  this.showModal(item);
                }}
              />
            </Tooltip>
          </div>
          <div mr="5px" style={{ float: "left", marginLeft: "10px" }}>
            <Tooltip placement="bottom" title="Change Password">
              <Button
                shape="circle"
                icon={<LockOutlined />}
                onClick={() => {
                  this.showChangePasswordModal(item);
                }}
              />
            </Tooltip>
          </div>
          <div
            ml="15px"
            mr="5px"
            style={{ float: "left", marginLeft: "10px", marginRight: "10px" }}
          >
            <Tooltip placement="bottom" title="Delete">
              <Popconfirm
                // onConfirm={this.deleteUserData.bind(this, item)}
                title="Are you sure you want to delete User?"
                onConfirm={() => this.handleDeleteUser(item._id)}
              >
                <Button shape="circle" icon={<DeleteOutlined 
                />} />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  render() {
    const { data, isLoading, pagination, visible, selectedItem } = this.state;
    const { authToken } = this.props;

    return (
      <>
        {isLoading ? (
          <Spin size="large" style={{ margin: "50px" }} />
        ) : (
          <Table
            rowKey="_id"
            columns={this.columns}
            dataSource={data || []}
            pagination={pagination}
            onChange={this.handleTableChange}
            locale={{ emptyText: "No data available" }}
          />
        )}

        {/* Edit Corporate Group Modal */}
        <EditForm
          visible={visible} // Pass state from CorporateGroupTable
          onCancel={this.handleCancel} // Handle close from CorporateGroupTable
          item={selectedItem}
          itemId={selectedItem && selectedItem._id}
          Token={this.props.authToken}
        />

        <ChangePassowordModal
          refx={(e) => (this.changePasswordmodalRef = e)}
          onClose={this.handleCancel}
          item={selectedItem}
          itemId={selectedItem && selectedItem._id}
          onChangePassword={this.handleChangePassword}
          Token={this.props.authToken}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  searchQuery: state.group.searchQuery,
});

const mapDispatchToProps = (dispatch) => ({
  setLoginFlag: (flag) => dispatch({ type: "SET_LOGIN_FLAG", flag }),
  setUserData: (userData) => dispatch({ type: "SET_USER_DATA", userData }),
  setUserToken: (authToken) =>
    dispatch({ type: "SET_USER_AUTHTOKEN", authToken }),
  setCorporateGroupData: (data) =>
    dispatch({ type: "SET_CORPORATE_GROUP_DATA", data }),
  setCorporateGroupTotal: (total) =>
    dispatch({ type: "SET_CORPORATE_GROUP_TOTAL", total }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CorporateGroupTable));
