import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { checkPermission } from "../AdminUser/CheckAdminPermission";

const { Content } = Layout;
const Option = Select.Option;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false
    };
  }

  input;

  componentDidMount() {

  }

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit(values, action) {

    this.props.onSubmit(values, this.props.data._id);

    this.setState({
      visible: false,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { loginUserData, admin } = this.props;
        // const hasPermission = checkPermission(loginUserData, admin, "user", "edit");
    return (
      <div>
        <Formik
          initialValues={{
            name: this.props.data.name,
            type: this.props.data.type,
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div>
                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Category Name
                    <Input
                      id="name"
                      placeholder="Category Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.name}
                      </p>
                    ) : null}
                  </div>
                </div>

                <Button
                  type="primary"
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Edit
                </Button>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}
