import React, { useState } from "react";
import { Modal, Button, Input, notification } from "antd";
import { changePasswordCorporate } from "../../Graphs/CorporateGroups/changePasswordUser";

const ChangePasswordForm = ({ visible, onClose, userData, authToken }) => {
  console.log("User Data: ", userData);
  
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  // Handle input change
  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Handle password change
  const handleChangePassword = async () => {
    if (formData.new_password !== formData.confirm_new_password) {
      notification.error({ message: "New passwords do not match!" });
      return;
    }

    try {
      const response = await changePasswordCorporate(authToken, formData, userData._id);

      if (response.status === 200) {
        notification.success({ message: "Password Changed Successfully!" });
        onClose(); // Close modal
      } else {
        notification.error({
          message: response.message || "Failed to change password.",
        });
      }
    } catch (error) {
      console.error("Error changing password:", error);
      notification.error({ message: "Something went wrong!" });
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Modal
      title="Change Password"
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Close
        </Button>,
        <Button key="change" type="primary" onClick={handleChangePassword}>
          Change Password
        </Button>,
      ]}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* Old Password */}
        <div>
          <p><strong>Old Password:</strong></p>
          <Input.Password
            value={formData.old_password}
            onChange={(e) => handleChange("old_password", e.target.value)}
            placeholder="Enter old password"
          />
        </div>

        {/* New Password */}
        <div>
          <p><strong>New Password:</strong></p>
          <Input.Password
            value={formData.new_password}
            onChange={(e) => handleChange("new_password", e.target.value)}
            placeholder="Enter new password"
          />
        </div>

        {/* Confirm New Password */}
        <div>
          <p><strong>Confirm New Password:</strong></p>
          <Input.Password
            value={formData.confirm_new_password}
            onChange={(e) => handleChange("confirm_new_password", e.target.value)}
            placeholder="Confirm new password"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordForm;
