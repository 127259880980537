import React from "react";
import { Button, Input, notification, Layout } from "antd";
import { Formik } from "formik";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Title from "./Title";
import { editAdminPassword } from "../../Graphs/Admin/editAdminPassword";

const { Content } = Layout;

export class ChangeAdminPassword extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      isLoading: false,
    };
  }

  validateForm(values) {
    const errors = {};

    // Old password validation
    if (!values.oldPassword) {
      errors.oldPassword = "Old Password is required";
    }

    // New password validation
    if (!values.newPassword) {
      errors.newPassword = "New Password is required";
    } else if (values.newPassword.length < 3) {
      errors.newPassword = "At least 3 characters required";
    }

    // Confirm new password validation
    if (!values.confirmNewPassword) {
      errors.confirmNewPassword = "Confirm New Password is required";
    } else if (values.confirmNewPassword.length < 3) {
      errors.confirmNewPassword = "At least 3 characters required";
    }

    // Password match validation
    if (values.newPassword !== values.confirmNewPassword) {
      errors.confirmNewPassword = "New Password and Confirm New Password do not match";
    }

    return errors;
  }

  handleSubmit(values, { setSubmitting, resetForm }) {
    this.changeAdminPassword(values, resetForm);
    setSubmitting(false);
  }

  async changeAdminPassword(passwordData, resetForm) {
    try {
      const { authToken } = this.props;

      this.setState({ isLoading: true });

      const adminObject = {
        old_password: passwordData.oldPassword,
        new_password: passwordData.newPassword
      };

      let resData = await editAdminPassword(authToken, adminObject);

      if (resData.status === 200) {
        notification["success"]({
          message: "Change Password",
          description: "Password changed successfully",
        });
        
        // Reset form after successful password change
        resetForm({
          values: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
          }
        });
      } else {
        // Handle other status codes
        notification["error"]({
          message: "Change Password",
          description: resData.message || "An error occurred",
        });
      }
    } catch (error) {
      console.error("Error in changing password", error);
      notification["error"]({
        message: "Change Password",
        description: error.toString(),
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div>
        <Title parent="Change Password" title="Admin Profile" />
        <Content style={{ background: "#fff", marginLeft: "10px" }}>
          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              confirmNewPassword: "",
            }}
            validate={this.validateForm}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div className="container-fluid p-20">
                <div className="form-group">
                  <div className="formik-field">
                    Old Password
                    <Input
                      id="oldPassword"
                      type="password"
                      placeholder="Old Password"
                      value={values.oldPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.oldPassword && touched.oldPassword && (
                      <p style={{
                        color: "red",
                        fontSize: "small",
                        margin: "0",
                      }}>
                        {errors.oldPassword}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <div className="formik-field">
                    New Password
                    <Input
                      id="newPassword"
                      type="password"
                      placeholder="New Password"
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.newPassword && touched.newPassword && (
                      <p style={{
                        color: "red",
                        fontSize: "small",
                        margin: "0",
                      }}>
                        {errors.newPassword}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <div className="formik-field">
                    Confirm New Password
                    <Input
                      id="confirmNewPassword"
                      type="password"
                      placeholder="Confirm New Password"
                      value={values.confirmNewPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.confirmNewPassword && touched.confirmNewPassword && (
                      <p style={{
                        color: "red",
                        fontSize: "small",
                        margin: "0",
                      }}>
                        {errors.confirmNewPassword}
                      </p>
                    )}
                  </div>
                </div>

                <Button
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Update Password
                </Button>
              </div>
            )}
          </Formik>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(ChangeAdminPassword));