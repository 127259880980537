import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserCourseQuizDetails } from "../../Graphs/User/UserCourseQuizDetails";

class QuizPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { userId, courseId, authToken } = this.props;
    console.log({ authToken, userId, courseId });
    try {
      const response = await getUserCourseQuizDetails(authToken, {
        userId,
        courseId,
      });
      this.setState({ data: response.data || [] });
    } catch (error) {
      console.error("Error fetching quiz data:", error);
    }
  };

  render() {
    return (
      <div className="container mx-auto p-6" style={{ padding: "2rem" }}>
        <h2 className="text-3xl font-extrabold mb-4 text-center">
          Quiz Details
        </h2>
        {this.state.data.map((topic) => (
          <div className="card" key={topic._id}>
            <h5 className="card-header" style={{ backgroundColor: "lightgrey" }}>
              {topic.name}
            </h5>
            <div>
              {topic.questionData.map((question) => (
                <div className="card-body" key={question._id}>
                  <h5 className="card-title">{question.title}</h5>
                  <p className="card-text">
                    {topic.userAnswer.find(
                      (ans) => ans.question_id === question._id
                    )
                      ? topic.userAnswer
                          .find((ans) => ans.question_id === question._id)
                          .answers.join(", ")
                      : "Not Answered"}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(QuizPreview);
