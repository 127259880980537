import {
  Table,
  Button,
  Popconfirm,
  Tooltip,
  notification,
  Spin,
  Modal,
  Input,
} from "antd";
import * as React from "react";
import { getDepartments } from "../../Graphs/Department/GetDepartment";
import { deleteDepartment } from "../../Graphs/Department/DeleteDepartment";
import { createDepartment } from "../../Graphs/Department/AddDepartment";
import { updateDepartment } from "../../Graphs/Department/UpdateDepartment";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Formik } from "formik";

class DepartmentRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      visible: false,
      isEditMode: false,
      selectedDepartmentId: null,
      selectedDepartment: "",
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  async componentDidMount() {
    // Store a reference to this component instance in window
    window.departmentTableRef = this;
    
    this.fetchDepartments();
  }

  componentWillUnmount() {
    // Clean up the reference when component unmounts
    if (window.departmentTableRef === this) {
      window.departmentTableRef = null;
    }
  }

  async fetchDepartments() {
    try {
      this.setState({ isLoading: true });
      const { authToken } = this.props;
      const response = await getDepartments(authToken, 0, 10);
      if (response.status === 200) {
        this.setState({
          data: response.data,
          isLoading: false,
        });
      }
    } catch (error) {
      console.error("Failed to fetch departments:", error);
      this.setState({ isLoading: false });
    }
  }
  
  // 67dbdf84c21ea5aaa5f69ae5
  async deleteDepartment(id) {
    try {
      const { authToken } = this.props;
      console.log("Auth token: ", authToken)
      const response = await deleteDepartment(id, authToken);
      console.log(id)
      if (response.status === 200) {
        notification.success({
          message: "Delete Department",
          description: "Successfully deleted the department.",
        });
        this.fetchDepartments(); // Refresh data after deletion
      }
    } catch (error) {
      notification.error({
        message: "Delete Department",
        description: "Failed to delete the department.",
      });
    }
  }

  // ✅ Handle Modal Open for Add/Edit Mode
  openModal = (record = null) => {
    this.setState({
      visible: true,
      isEditMode: record ? true : false,
      selectedDepartmentId: record ? record._id : null,
      selectedDepartment: record ? record.name : "",
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
      selectedDepartmentId: null,
      selectedDepartment: "",
      isEditMode: false,
    });
  };

  // ✅ Handle Form Submission
  handleSubmit = async (values) => {
    const { authToken } = this.props;
    const { isEditMode, selectedDepartmentId } = this.state;

    try {
      let response;
      if (isEditMode) {
        // UPDATE Department
        response = await updateDepartment(authToken, {
          id: selectedDepartmentId,
          name: values.department,
        });
      } else {
        // CREATE Department
        response = await createDepartment(authToken, {
          name: values.department,
        });
      }

      if (response.status === 200 || response.status === 201) {
        notification.success({
          message: isEditMode ? "Edit Department" : "Create Department",
          description: `Department ${
            isEditMode ? "updated" : "created"
          } successfully.`,
        });
        this.fetchDepartments();
        this.closeModal();
      }
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        message: isEditMode ? "Edit Department" : "Create Department",
        description: `Failed to ${
          isEditMode ? "update" : "create"
        } department.`,
      });
    }
  };

  handleTableChange(pagination) {
    this.setState({ pagination }, () => this.fetchDepartments());
  }

  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 70,
      render: (text, item, index) => {
        const { current, pageSize } = this.state.pagination;
        const serialNumber = (current - 1) * pageSize + index + 1;
        return (
          <div>
            <span>{serialNumber}</span>
          </div>
        );
      },
    },
    {
      title: "Department Name",
      key: "name",
      render: (text, record) => (
        <strong>
          <Tooltip title="Department Name">{record.name || "-"}</Tooltip>
        </strong>
      ),
    },
    {
      title: "Actions",
      key: "action",
      width: 150,
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Tooltip title="Edit">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => this.openModal(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this department?"
              onConfirm={() => this.deleteDepartment(record._id)}
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  render() {
    const { isLoading, data, pagination, visible, selectedDepartment } =
      this.state;

    return (
      <>
        {/* ✅ Table */}
        {isLoading ? (
          <Spin size="large" style={{ marginLeft: "50%", marginTop: "100px" }} />
        ) : (
          <Table
            rowKey={(record) => record._id}
            columns={this.columns}
            dataSource={data}
            pagination={pagination}
            onChange={this.handleTableChange}
          />
        )}

        {/* ✅ Add/Edit Modal */}
        <Modal
          destroyOnClose={true}
          title={this.state.isEditMode ? "Edit Department" : "Add Department"}
          visible={visible}
          onCancel={this.closeModal}
          footer={null}
          maskClosable={false}
        >
          <Formik
            initialValues={{
              department: selectedDepartment || "",
            }}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              resetForm, 
            }) => (
              <div>
                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Department
                    <Input
                      id="department"
                      placeholder="Enter Department Name"
                      value={values.department}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <Button
                  type="primary"
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  {this.state.isEditMode ? "Update" : "Add"}
                </Button>
              </div>
            )}
          </Formik>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(DepartmentRecordsTable);