import { Modal, Button, Divider, Checkbox, notification } from "antd";
import * as React from "react";
import { Layout, Input, Select } from "antd";
import { Formik } from "formik";
import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { editAdmin } from "../../Graphs/Admin/editAdmin";
import { listUser } from "../../Graphs/CorporateGroups/listUser";

const { Content } = Layout;
const Option = Select.Option;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: null,
      view: false,
      isLoading: false,
	  visible: false,
      isRestricted: false,
      isPrivate: false,
    };
  }

  input;
  allCourses = [];


  handleSubmit = async (values) => {
	  const { Token, itemId, onCancel, item } = this.props;

	  // console.log("Item ID: ", item, itemId)
	  try {
		if (this.state.selectedUser && this.state.selectedUser._id) {
		  // console.log("Updating user:", values);
		} else {
		  // console.log("Creating new user:", values);
		  const response = await editAdmin(Token, values, itemId);
  
		  if (response && response.success) {
			// console.log("User added successfully:", response.data);
			notification["success"]({
			  message: "New Admin Added Successfully",
			});
		  } else {
			console.error("Failed to add user:", response);
		  }
		}
	  } catch (error) {
		console.error("Error in handleSubmit:", error);
	  }
  
	 onCancel();
	};
  

  onChangeIsRestricted() {
    this.setState({
      isRestricted: !this.state.isRestricted,
    });
  }

  onChangeIsPrivate() {
    this.setState({
      isPrivate: !this.state.isPrivate,
    });
  }

  render() {
    const { primaryColor, item } = this.props;
    const { data, isLoading } = this.state;
    // const { subAdminType } = this.state;
    // console.log("Item", item)

    const safeItem = item || {};

    return (
      <div>
        <Modal
          destroyOnClose={true}
          title="Edit Admin"
          visible={this.props.visible} // Use prop instead of state
		//   onCancel={() => this.setState({ visible: false })}
          onCancel={this.props.onCancel} // Call parent function
          footer={null}
          maskClosable={false}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                // username: "",
                first_name:  safeItem.first_name || "",
                last_name:  safeItem.last_name || "",
                email: safeItem.email || "",
                phone: "",
                password: "",
                confirm_password: "",
                role: "corporate",
                permissions: this.permissionsData,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        First Name
                        <Input
                          id="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Last Name
                        <Input
                          id="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.last_name && touched.last_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.last_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Email
                        <Input
                          id="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Password
                        <Input
                          type="password"
                          id="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.password && touched.password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.password}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Confirm Password
                        <Input
                          id="confirm_password"
                          type="password"
                          placeholder="Confirm Password"
                          value={values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.confirm_password && touched.confirm_password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.confirm_password}
                          </p>
                        ) : null}
                      </div>
                    </div> */}
                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Edit
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(EditForm));
