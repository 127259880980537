import * as React from "react";
import { Upload, message, Button, Modal } from "antd";
import styled from "styled-components";
import { WEBURL } from "../../constant/comman";
import { InboxOutlined } from "@ant-design/icons";
import { COURSE_SESSION_MEDIA_UPLOAD_PATH } from "../../constant/comman";

const Dragger = Upload.Dragger;

const StyledDragger = styled(Dragger)`
  & .ant-upload {
    margin-bottom: 15px;
  }

  & .ant-upload-drag {
    margin-bottom: 15px;
  }
  & .ant-upload-list-item-actions {
    display: none;
  }
`;

const props = {
    name: "file",
    action: COURSE_SESSION_MEDIA_UPLOAD_PATH,
    // action: "http://localhost:4040/upload/file",
    headers: {
        authorization: "authorization-text"
    }
};

var temp = [];
var isUploadClick = false;

var supportedImageMimeTypes = ['image/jpeg', 'image/gif', 'image/pipeg', 'image/png', 'image/svg+xml', 'image/webp'];
var supportedVideoMimeTypes = ['video/mpeg', 'video/mp4', 'video/ogg', 'video/webm', 'video/x-msvideo'];

export class AddCourseSessionMediaFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disable: true,
            jsonFile: [],
            disableUpload: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.verifyDoc = this.verifyDoc.bind(this);
    }

    componentDidMount() {
        if (this.props.refx) {
            this.props.refx(this);
        }
    }

    verifyDoc() {
        isUploadClick = true;

        if (temp.length <= 0) {
            message.error("Please select a valid image");
            return;
        }

        //multiple temp file array to store file name into seperate array
        const tempFilesName = [];
        let mediaType = "";

        temp.map(item => {
            if (supportedImageMimeTypes.includes(item.type)) {
                mediaType = "image";
            } else if (supportedVideoMimeTypes.includes(item.type)) {
                mediaType = "video";
            }
            tempFilesName.push({ media_name: item.response.file, type: mediaType, thumb_media_name: item.response.thumb_media_name })
        })

        this.props.onUpload(tempFilesName);
    }

    handleChange(info) {
        console.log("!!!!!!!!!!!!!!!!!!!!info printed ", info);

        var error = false;
        if (info.file.status !== "uploading") {
            // console.log("status --", info.file.response.file, info.fileList);
            if (info.fileList.length < 1) {
                this.setState({
                    disable: true
                });
            } else {
                this.setState({
                    disable: false
                });
            }
        }

        if (info.file.status === "done") {
            if (info.file.response.code === "ERROR_IN_UPLOAD") {
                message.error(info.file.response.msg);
                error = true;
            } else {
                message.success(`${info.file.name} file uploaded successfully.`);
                temp = info.fileList;
                error = false;
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
            error = true;
        }
        // temp = info.fileList;
    }

    handleCancel = (e) => {
        this.hide();
    };

    show() {
        this.setState({
            visible: true
        });
    }

    hide() {
        this.setState(
            {
                visible: false
            },
            // () => (temp.length > 0 ? this.props.onUpload(temp) : this.props.onUpload(null))
            () => (isUploadClick ? this.props.onUpload(temp) : this.props.onUpload(null))
        );
    }

    render() {
        return (
            <Modal
                width="600px"
                closable={this.state.closable}
                visible={this.state.visible}
                // onOk={this.handleOk}
                onCancel={this.handleCancel.bind(this)}
                footer={null}
                maskClosable={false}
            >
                <div style={{ marginTop: "20px" }}>
                    <StyledDragger
                        //listType="picture-card"
                        accept=".png,.jpeg,.jpg"
                        multiple={true}
                        className="clearfix"
                        {...props}
                        onChange={this.handleChange}
                        onRemove={(file) => console.log("Removed -- ", file)}
                    >
                        {/* <img src={imageUrl} alt="avatar" /> */}
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint" style={{ marginBottom: "10px" }}>
                            Support for a single or bulk upload. Strictly prohibit from
                            uploading company data or other band files
                        </p>
                        {/* <Button>
            <Icon type="upload" /> Click to Upload
          </Button> */}
                    </StyledDragger>
                    <Button
                        style={{ marginTop: "50px" }}
                        disabled={this.state.disable}
                        onClick={this.verifyDoc}
                    >
                        Upload
                    </Button>
                    {/* <Button disabled={this.state.disableUpload} onClick={this.handleSubmit}>
          Upload
        </Button>
        .{" "} */}
                </div>
            </Modal>
        );
    }
}
