import React, { Fragment } from "react";
import {
  Spin,
  Tabs,
  Layout,
  Card,
  notification,
  Table,
  Tooltip,
  Switch,
  Button,
  Popconfirm,
} from "antd";
export const getDepartmentTableColumns = (serialCounter, onChangeStatus) => [
  {
    title: "Sr. No.",
    key: "serial_number",
    width: 100,
    render: (text, item, index) => {
      return (
        <div>
          <span>{serialCounter + index}</span>
        </div>
      );
    },
  },
  {
    title: "Name",
    key: "name",
    width: 200,
    render: (text, item) => (
      <Tooltip title="User Name">
        <div>{item.name || "-"}</div>
      </Tooltip>
    ),
  },
];
