import {
  Button,
  Layout,
  Input,
  Select,
  Checkbox,
  Radio,
  InputNumber,
} from "antd";
import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import { PictureOutlined, DeleteFilled } from "@ant-design/icons";
import { AddQuestionMedia } from "./AddQuestionMedia";
import {
  WEBURL,
  COURSE_TOPIC_MEDIA_PREVIEW_PATH,
  COURSE_TOPIC_QUESTION_AUDIO_PREVIEW_PATH,
} from "../../constant/comman";
import MediaItem from "../Media/MediaItem";

const { Content } = Layout;
const { TextArea } = Input;
const Option = Select.Option;

var amenities = [];

export class QuestionComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allQuestions: [],
    };
  }

  allQuesTypes = [
    <Option value={"Text"} key="Text">
      Text
    </Option>,
    <Option value={"Audio"} key="Audio">
      Audio
    </Option>,
    <Option value={"Video"} key="Video">
      Video
    </Option>,
    // <Option value={"Code Snipet"} key="Code Snipet">Code Snipet</Option>,
  ];

  allAnsTypes = [
    // <Option value={"multiChoies"} key="multiChoies">
    //   Multiple Choies
    // </Option>,
    <Option value={"singleChoies"} key="singleChoies">
      Single Choice
    </Option>,
    <Option value={"descriptive"} key="descriptive">
      Descriptive
    </Option>,
    // <Option value={"practical"} key="practical">Pratical</Option>,
  ];

  componentDidMount() {
    const { allQuestionsData } = this.props;

    //set question media as per the question component structure start
    let updateQuestionData = this.setQuestionMediaURL(allQuestionsData);
    //set question media as per the question component structure end

    this.setState({ allQuestions: updateQuestionData });
  }

  viewQuestionMediaModalRef;

  addQuestions = () => {
    const { allQuestions } = this.state;

    let dummyQuestions = allQuestions;

    dummyQuestions.push({
      title: "",
      ques_type: "",
      ans_type: "",
      media_name: "",
      short_description: "",
      options: [],
      view_question_media_modal: false,
      // long_description: "",
      // marks: 0,
    });

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  addOptions = (index) => {
    console.log("test log ", index);
    const { allQuestions } = this.state;

    let dummyOptions = allQuestions[index]["options"];
    let dummyQuestions = allQuestions;

    dummyOptions.push({
      title: "",
      is_correct: false,
    });

    dummyQuestions[index]["options"] = dummyOptions;

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  removeQuestionItem = (index) => {
    console.log("!!!!!removeQuestionItem", index);

    const { allQuestions } = this.state;

    let dummyQuestions = allQuestions;

    dummyQuestions.splice(index, 1);

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  removeQuestionOptionItem = (index, optIndex) => {
    console.log("!!!!!removeQuestionOptionItem", optIndex);

    const { allQuestions } = this.state;

    let dummyOptions = allQuestions[index]["options"];

    let dummyQuestions = allQuestions;

    dummyOptions.splice(optIndex, 1);

    dummyQuestions[index]["options"] = dummyOptions;

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  onQuestionValueChange = (e, index) => {
    const { allQuestions } = this.state;

    let dummyQuestions = allQuestions;

    dummyQuestions[index][e.target.name] = e.target && e.target.value;

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  onOptionValueChange = (e, index, optIndex) => {
    const { allQuestions } = this.state;

    let dummyQuestions = allQuestions;

    dummyQuestions[index]["options"][optIndex][e.target.name] =
      e.target && e.target.value;

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  onOptionIsCorrectChange = (e, index, optIndex) => {
    const { allQuestions } = this.state;

    let dummyQuestions = allQuestions;

    dummyQuestions[index]["options"][optIndex][e.target.name] =
      !dummyQuestions[index]["options"][optIndex][e.target.name];

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  onRadioOptionIsCorrectChange = (e, index, optIndex) => {
    const { allQuestions } = this.state;

    let dummyQuestions = allQuestions;

    this.resetRadioButtonValue(dummyQuestions, index);

    dummyQuestions[index]["options"][optIndex]["is_correct"] = true;
    dummyQuestions[index]["options"]["is_correct_radio"] = e.target.value;

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  showQuestionMediaUpload = (index) => {
    const { allQuestions } = this.state;

    let dummyQuestions = allQuestions;

    dummyQuestions[index]["view_question_media_modal"] = true;

    this.setState(
      {
        allQuestions: dummyQuestions,
      },
      () => {
        this.viewQuestionMediaModalRef.show();
      }
    );

    this.updateAllQuestionState(dummyQuestions);
  };

  uploadQuestionMedia = (name, index) => {
    const { allQuestions } = this.state;

    let dummyQuestions = allQuestions;

    dummyQuestions[index]["media_name"] = name;
    dummyQuestions[index]["view_question_media_modal"] = false;

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  changeQuestionType = (value, index) => {
    const { allQuestions } = this.state;

    let dummyQuestions = allQuestions;

    dummyQuestions[index]["ques_type"] = value;
    dummyQuestions[index]["media_name"] = "";

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  changeAnswerType = (value, index) => {
    const { allQuestions } = this.state;
    let dummyQuestions = allQuestions;
    dummyQuestions[index]["ans_type"] = value;

    this.resetRadioButtonValue(dummyQuestions, index);

    this.setState({
      allQuestions: dummyQuestions,
    });

    this.updateAllQuestionState(dummyQuestions);
  };

  resetRadioButtonValue = (questions, qIndex) => {
    if (questions[qIndex]["options"].length >= 1) {
      questions[qIndex]["options"] = questions[qIndex]["options"].map(
        (item) => {
          item.is_correct = false;
          return item;
        }
      );
    }
  };

  updateAllQuestionState(allQuestions) {
    const { updateAllQuestionStateAction } = this.props;

    updateAllQuestionStateAction(allQuestions);
  }

  setQuestionMediaURL(allQuestions) {
    let dummyQuestions = allQuestions;

    allQuestions.map((questionItem, index) => {
      if (
        questionItem.ques_type === "Audio" &&
        questionItem.questionMediaData &&
        questionItem.questionMediaData[0]
      ) {
        dummyQuestions[index]["media_name"] =
          questionItem.questionMediaData[0]["media_name"];
      }

      if (
        questionItem.ques_type === "Video" &&
        questionItem.questionMediaData &&
        questionItem.questionMediaData[0]
      ) {
        dummyQuestions[index]["media_name"] =
          questionItem.questionMediaData[0]["media_url"];
      }
    });
    return dummyQuestions;
  }

  render() {
    const { action, handleBlur, errors } = this.props;
    const { allQuestions } = this.state;

    return (
      <>
        <div style={{ marginTop: "15px" }}>
          <Button
            style={{
              marginTop: "19px",
              background: "#5C6AC4",
              color: "#fff",
            }}
            onClick={() => this.addQuestions()}
          >
            Add Question
          </Button>
        </div>

        {errors.allQuestions && typeof errors.allQuestions === "string" ? (
          <p
            style={{
              color: "red",
              fontSize: "small",
              margin: "0",
            }}
          >
            {errors.allQuestions}
          </p>
        ) : null}

        <div style={{ marginTop: "15px" }}>
          {allQuestions.map((data, index) => {
            return (
              <div
                style={{
                  marginBottom: "15px",
                  display: "inline-block",
                  width: "100%",
                  paddingBottom: "15px",
                  borderBottom: "4px dashed gray",
                }}
                key={index}
              >
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    width: "100%",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      float: "left",
                    }}
                  >
                    {`Question ${index + 1}`}
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      float: "right",
                    }}
                  >
                    <Button
                      shape="circle"
                      icon={<DeleteFilled />}
                      onClick={() => {
                        this.removeQuestionItem(index);
                      }}
                    />
                  </span>
                </div>

                <div className="formik-field_wrap custom30Width">
                  <div className="formik-field_wrap">
                    <div className="formik-field">
                      <span style={{ fontWeight: "bold" }}>Question Title</span>
                      <Input
                        id="question_title"
                        placeholder="Question Title"
                        name="title"
                        value={allQuestions[index]["title"]}
                        onChange={(e) => this.onQuestionValueChange(e, index)}
                        onBlur={handleBlur}
                      />
                      {errors.allQuestions &&
                      errors.allQuestions[index] &&
                      errors.allQuestions[index]["title"] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.allQuestions[index]["title"]}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="formik-field_wrap">
                    <div
                      className="formik-field-left"
                      style={{ width: "30%", marginTop: "5px" }}
                    >
                      <span style={{ fontWeight: "bold" }}>Question Type</span>
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          this.changeQuestionType(value, index)
                        }
                        value={allQuestions[index]["ques_type"]}
                        placeholder="Select Question Type"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.allQuesTypes}
                      </Select>
                      {errors.allQuestions &&
                      errors.allQuestions[index] &&
                      errors.allQuestions[index]["ques_type"] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.allQuestions[index]["ques_type"]}
                        </p>
                      ) : null}
                    </div>

                    <div
                      className="formik-field-right"
                      style={{ width: "30%", marginTop: "5px" }}
                    >
                      <span style={{ fontWeight: "bold" }}>Answer Type</span>
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          this.changeAnswerType(value, index)
                        }
                        value={allQuestions[index]["ans_type"]}
                        placeholder="Select Answer Type"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.allAnsTypes}
                      </Select>
                      {errors.allQuestions &&
                      errors.allQuestions[index] &&
                      errors.allQuestions[index]["ans_type"] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.allQuestions[index]["ans_type"]}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="formik-field_wrap">
                    <div className="formik-field">
                      <span style={{ fontWeight: "bold" }}>Description</span>
                      <TextArea
                        row={4}
                        id="short_description"
                        placeholder="Description"
                        name="short_description"
                        value={allQuestions[index]["short_description"]}
                        onChange={(e) => this.onQuestionValueChange(e, index)}
                        onBlur={handleBlur}
                      />
                      {errors.allQuestions &&
                      errors.allQuestions[index] &&
                      errors.allQuestions[index]["short_description"] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.allQuestions[index]["short_description"]}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {/* <div className="formik-field_wrap">
                <div className="formik-field">
                  <span style={{ fontWeight: "bold" }}>
                    Long Description
                  </span>
                  <TextArea
                    row={4}
                    id="long_description"
                    name="long_description"
                    placeholder="Long Description"
                    value={
                      allQuestions[index][
                        "long_description"
                      ]
                    }
                    onChange={(e) =>
                      this.onQuestionValueChange(e, index)
                    }
                    onBlur={handleBlur}
                  />
                  {errors.allQuestions &&
                  errors.allQuestions[index] &&
                  errors.allQuestions[index][
                    "long_description"
                  ] ? (
                    <p
                      style={{
                        color: "red",
                        fontSize: "small",
                        margin: "0",
                      }}
                    >
                      {
                        errors.allQuestions[
                          index
                        ]["long_description"]
                      }
                    </p>
                  ) : null}
                </div>
              </div> */}

                  {/* <div className="formik-field-left" style={{ width: "30%", marginTop: "5px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Correct Option
                  </span>
                  <Input
                    id="answerText"
                    placeholder="Answer Text"
                    value={allQuestions[index]["corre"]}
                    onChange={(e) => this.onAnswerTextChange(e, index)}
                    onBlur={handleBlur}
                  />
                </div> */}

                  {/* <div className="formik-field_wrap">
                    <div className="formik-field-left" style={{ width: "30%" }}>
                      <span style={{ fontWeight: "bold" }}>Time</span>
                      <Input
                        min={1}
                        id="time"
                        type="number"
                        placeholder="Time"
                        name="time"
                        value={parseInt(allQuestions[index]["time"])}
                        onChange={(e) => this.onQuestionValueChange(e, index)}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div
                      className="formik-field-right"
                      style={{ width: "30%" }}
                    >
                      <span style={{ fontWeight: "bold" }}>Marks</span>
                      <Input
                        min={1}
                        type="number"
                        id="marks"
                        placeholder="Marks"
                        name="marks"
                        value={parseInt(allQuestions[index]["marks"])}
                        // onChange={(e) => this.onMarksChange(e, index)}
                        onChange={(e) => this.onQuestionValueChange(e, index)}
                        onBlur={handleBlur}
                      />
                      {errors.allQuestions &&
                      errors.allQuestions[index] &&
                      errors.allQuestions[index]["marks"] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.allQuestions[index]["marks"]}
                        </p>
                      ) : null}
                    </div>
                  </div> */}

                  {allQuestions[index]["ans_type"] &&
                  (allQuestions[index]["ans_type"] === "multiChoies" ||
                    allQuestions[index]["ans_type"] === "singleChoies") ? (
                    <>
                      <div style={{ marginTop: "15px" }}>
                        <Button
                          style={{
                            marginTop: "20px",
                            background: "#5C6AC4",
                            color: "#fff",
                          }}
                          onClick={() => this.addOptions(index)}
                        >
                          Add Option
                        </Button>
                      </div>
                      {allQuestions[index]["options"].map(
                        (optData, optIndex) => {
                          return (
                            <div
                              className="formik-field_wrap"
                              style={{
                                borderBottom: "1px solid gray",
                                paddingBottom: "20px",
                              }}
                              key={optIndex}
                            >
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                  width: "100%",
                                  display: "inline-block",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    float: "left",
                                  }}
                                >
                                  {`Option ${optIndex + 1}`}
                                </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    float: "right",
                                  }}
                                >
                                  <Button
                                    shape="circle"
                                    icon={<DeleteFilled />}
                                    onClick={() => {
                                      this.removeQuestionOptionItem(
                                        index,
                                        optIndex
                                      );
                                    }}
                                  />
                                </span>
                              </div>

                              <div className="formik-field-left">
                                <Input
                                  id={`title ${optIndex + 1}`}
                                  placeholder={`Title ${optIndex + 1}`}
                                  name="title"
                                  value={
                                    allQuestions[index]["options"][optIndex][
                                      "title"
                                    ]
                                  }
                                  onChange={(e) =>
                                    this.onOptionValueChange(e, index, optIndex)
                                  }
                                  onBlur={handleBlur}
                                />
                                {errors.selectedAnswerType ? (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "small",
                                      margin: "0",
                                    }}
                                  >
                                    {errors.selectedAnswerType}
                                  </p>
                                ) : null}
                              </div>

                              {allQuestions[index]["ans_type"] ===
                              "multiChoies" ? (
                                <div
                                  className="formik-field-right"
                                  style={{
                                    width: "30%",
                                    marginTop: "5px",
                                  }}
                                >
                                  <Checkbox
                                    name="is_correct"
                                    onChange={(e) =>
                                      this.onOptionIsCorrectChange(
                                        e,
                                        index,
                                        optIndex
                                      )
                                    }
                                    checked={
                                      allQuestions[index]["options"][optIndex][
                                        "is_correct"
                                      ]
                                    }
                                  >
                                    Is Correct
                                  </Checkbox>
                                  {errors.isPaid ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        margin: "0",
                                      }}
                                    >
                                      {errors.isPaid}
                                    </p>
                                  ) : null}
                                </div>
                              ) : (
                                <div
                                  className="formik-field-right"
                                  style={{
                                    width: "30%",
                                    marginTop: "5px",
                                  }}
                                >
                                  <Radio.Group
                                    name={`is_correct_radio_group${index}`}
                                    onChange={(e) =>
                                      this.onRadioOptionIsCorrectChange(
                                        e,
                                        index,
                                        optIndex
                                      )
                                    }
                                    value={
                                      allQuestions[index]["options"][
                                        "is_correct_radio"
                                      ]
                                    }
                                  >
                                    <Radio value={optIndex}>Is Correct</Radio>
                                  </Radio.Group>

                                  {errors.isPaid ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        margin: "0",
                                      }}
                                    >
                                      {errors.isPaid}
                                    </p>
                                  ) : null}
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                    </>
                  ) : null}
                  {errors.allQuestions &&
                  errors.allQuestions[index] &&
                  errors.allQuestions[index]["options"] ? (
                    <p
                      style={{
                        color: "red",
                        fontSize: "small",
                        margin: "0",
                      }}
                    >
                      {errors.allQuestions[index]["options"]}
                    </p>
                  ) : null}
                </div>

                {allQuestions[index]["ques_type"] === "Audio" ? (
                  <>
                    <div className="customRightWidth">
                      <div className="formik-field_wrap">
                        <Button
                          style={{ width: "100%" }}
                          onClick={this.showQuestionMediaUpload.bind(
                            this,
                            index
                          )} //this.showViewModal.bind(this)
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {`Choose Question ${allQuestions[index]["ques_type"]}`}
                          </span>
                        </Button>
                        <br />

                        {allQuestions[index]["media_name"] ? (
                          <>
                            <Button
                              type="dashed"
                              block
                              onClick={() => {
                                window.open(
                                  WEBURL +
                                    "course/topic/question/" +
                                    allQuestions[index]["media_name"]
                                );
                              }}
                            >
                              Click To Preview
                            </Button>
                          </>
                        ) : null}

                        {allQuestions[index]["view_question_media_modal"] ? (
                          <AddQuestionMedia
                            refx={(e) => (this.viewQuestionMediaModalRef = e)}
                            onUpload={(name) =>
                              this.uploadQuestionMedia(name, index)
                            }
                            selectedMediaType={allQuestions[index][
                              "ques_type"
                            ].toLowerCase()}
                          />
                        ) : null}
                      </div>
                    </div>

                    {errors.allQuestions &&
                    errors.allQuestions[index] &&
                    errors.allQuestions[index]["media_name"] ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.allQuestions[index]["media_name"]}
                      </p>
                    ) : null}
                  </>
                ) : allQuestions[index]["ques_type"] === "Video" ? (
                  <>
                    <div className="formik-field_wrap">
                      <div className="formik-field">
                        <span style={{ fontWeight: "bold" }}>
                          Question Video
                        </span>
                        <Input
                          id="media_name"
                          placeholder="Question Video"
                          name="media_name"
                          value={allQuestions[index]["media_name"]}
                          onChange={(e) => this.onQuestionValueChange(e, index)}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    {errors.allQuestions &&
                    errors.allQuestions[index] &&
                    errors.allQuestions[index]["media_name"] ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.allQuestions[index]["media_name"]}
                      </p>
                    ) : null}
                  </>
                ) : null}
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default connect()(withRouter(QuestionComponent));
