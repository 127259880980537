import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { withRouter } from "react-router";
import * as la from "lodash";
import { connect } from "react-redux";
import { checkPermission } from "../AdminUser/CheckAdminPermission";

const { Content } = Layout;
const Option = Select.Option;

export class AddCategory extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
    };
  }

  input;
  allTypes = [
    <Option value={"charity"}>Charity</Option>,
    <Option value={"vendor"}>Vendor</Option>,
  ];

  validateForm(values) {
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit = (values, action) => {
    this.props.onSubmit(values);

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedType: "",
    });
  };

  render() {
    const { primaryColor, loginData } = this.props;
    console.log(loginData);
    // console.log(loginData.permissions.users.create);
    const canEdit = checkPermission(loginData, "users", "create");
    console.log("Can edit: ", canEdit)

    return (
      <div>
        { canEdit && (
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>
        )}
        <Modal
          destroyOnClose={true}
          title="Add Category"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          maskClosable={false}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Category Name
                        <Input
                          id="name"
                          placeholder="Category Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(AddCategory));
