import {
  Home,
  User,
  Users,
  Grid,
  Copy,
  Globe,
  Shield,
  FileText,
  Star,
} from "react-feather";

export const MENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "link",
    path: "/dashboard",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Admin Users",
    icon: Shield,
    type: "link",
    path: "/admin",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Users",
    icon: Users,
    type: "link",
    path: "/user",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Departments",
    icon: Grid,
    type: "link",
    path: "/department",
    active: true,
    // superadmin: true,
  },
  {
    title: "Manage Categories",
    icon: Grid,
    type: "link",
    path: "/category",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Sub Categories",
    icon: Copy,
    type: "link",
    path: "/sub-category",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Languages",
    icon: Globe,
    type: "link",
    path: "/language",
    active: true,
    superadmin: true,
  },
  {
    title: "General FAQ's",
    icon: Globe,
    type: "link",
    path: "/general_faq",
    active: true,
    superadmin: true,
  },
  {
    title: "Courses",
    icon: FileText,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/courses", title: "Manage Courses", type: "link" },
      { path: "/module", title: "Manage Modules", type: "link" },
      { path: "/course-session", title: "Manage Sessions", type: "link" },
      { path: "/course-session-topic", title: "Manage Topics", type: "link" },
    ],
  },
  {
    title: "Manage Corporate Groups",
    icon: Star,
    type: "link",
    path: "/corporategroup",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Testimonials",
    icon: Star,
    type: "link",
    path: "/testimonial",
    active: true,
    superadmin: true,
  },
  {
    title: "Manage Static Pages",
    icon: User,
    type: "link",
    path: "/static-pages",
    active: true,
  },
  {
    title: "Profile",
    icon: User,
    type: "link",
    path: "/edit-profile",
    active: true,
  },
];

export default MENUITEMS;

