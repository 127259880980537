import React, { useState, useEffect } from "react";
import { Modal, DatePicker, Button, message } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { extendValidity } from "../../Graphs/User/ExtendValidity";

const EditDateModal = ({
  visible,
  onClose,
  onSubmit,
  startDate,
  endDate,
  authToken,
  userId,
  courseId,
}) => {
  const [end, setEnd] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (endDate) {
      setEnd(moment(endDate));
    }
  }, [endDate, visible, authToken]);

  const handleSubmit = async () => {
    if (!end) {
      message.error("Please select a valid end date.");
      return;
    }

    if (end.isBefore(moment(endDate))) {
      message.error(
        "New end date cannot be earlier than the current end date."
      );
      return;
    }

    const userObject = {
      user_id: userId,
      course_id: courseId,
      end_date: end.format("YYYY-MM-DD"),
    };

    try {
      const response = await dispatch(extendValidity(authToken, userObject));

      console.log(authToken);
      console.log("API Response:", response);

      if (response.status === 200) {
        // message.success("End date updated successfully!");
        // onSubmit(userObject);
        // onClose();
        message.success("End date updated successfully!");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to update end date.");
    }

    // onSubmit({
    //   endDate: end.format("YYYY-MM-DD"),
    // });

    // dispatch(extendValidity(authtoken, userObject, userId))

    onClose();
  };

  return (
    <Modal
      title="Edit Dates"
      visible={visible}
      maskClosable={false}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Save
        </Button>,
      ]}
    >
      {/* ✅ Editable End Date */}
      <div style={{ marginBottom: 16 }}>
        <label>End Date:</label>
        <DatePicker
          value={end}
          onChange={(date) => setEnd(date)}
          style={{ width: "100%" }}
          placeholder="Select End Date"
          disabledDate={(current) => current && current < moment().endOf("day")}
        />
      </div>
    </Modal>
  );
};

export default EditDateModal;
