import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, Tabs } from "antd";
import { getCorporateGroups } from "../../Graphs/CorporateGroups/lsitCorporateGroups";
import CorporateGroupRecordsTable from "./CorporateGroupRecordsTable";
import { AddCorporateUser } from "./AddCorporate";

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeTabKey: "1",
      groups: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  }

  async componentDidMount() {
    await this.fetchCorporateGroups();
  }

  async fetchCorporateGroups(pagination = this.state.pagination) {
    const { authToken } = this.props;

    this.setState({ isLoading: true });

    try {
      const skipNumber = (pagination.current - 1) * pagination.pageSize;
      const limitNumber = pagination.pageSize;
      const searchQuery = ""; // Add search query if required

      const response = await getCorporateGroups(
        authToken,
        skipNumber,
        limitNumber,
        searchQuery
      );

      console.log("CourseID: ", response)

      this.setState({
        groups: response.data || [],
        isLoading: false,
        pagination: {
          ...pagination,
          total: response.total || 0,
        },
      });
    } catch (err) {
      console.error("Failed to fetch groups:", err);
      this.setState({ isLoading: false });
    }
  }

  handleTableChange = (pagination) => {
    this.setState({ pagination }, () => this.fetchCorporateGroups(pagination));
  };

  render() {
    const { isLoading, groups, pagination, primaryColor } = this.state;

    return (
      <Fragment>
        <Title parent="Corporate Groups" title="corporate Groups" />
        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <div
                style={{
                  marginTop: "20px",
                  display: "inline-block",
                  width: "100%",
                  marginBottom: "20px",
                  paddingLeft: "14px",
                  paddingRight: "55px",
                }}
              >
                <div style={{ float: "left", cursor: "pointer" }}>
                  <AddCorporateUser
                    primaryColor={primaryColor}
                    onSubmit={this.addSubmit}
                    Token={this.props.authToken}
                    LoginData={this.props.loginUserData}
                    fetchData={this.fetchCorporateGroups.bind(this)}
                  />
                </div>
              </div>

              <CorporateGroupRecordsTable
                data={groups}
                pagination={pagination}
                fetchData={this.fetchCorporateGroups.bind(this)}
                handleTableChange={this.handleTableChange.bind(this)}
                Token={this.props.authToken}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken, // Assuming authToken is stored in Redux state
});

export default withRouter(connect(mapStateToProps)(Group));
