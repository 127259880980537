import React, { Fragment, Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getDepartments } from "../../Graphs/Department/GetDepartment";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import Title from "./Title";
import DepartmentRecordTable from "./DepartmentRecordTable";
import { createDepartment } from "../../Graphs/Department/AddDepartment";
import { AddDepartment } from "./AddDepartment";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class Department extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentList: [],
      departmentListTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
    };
  }

  async componentDidMount() {
    if (
      !checkPermission(
        this.props.loginUserData.permissions,
        "department",
        "view"
      )
    ) {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
      return;
    }

    try {
      const { setRouteName, setSearchText } = this.props;
      let primaryColor = await localStorage.getItem("primary_color");

      await setRouteName("DEPARTMENT");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: primaryColor,
      });

      await this.fetchDepartmentData();
    } catch (e) {
      notification["error"]({
        message: "Department",
        description: "Something went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  fetchDepartmentData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListDepartmentData,
      setListDepartmentDataTotal,
    } = this.props;

    console.log(authToken);

    try {
      let departmentData = await getDepartments(authToken, 0, 10);

      if (departmentData.status === 200) {
        await setListDepartmentData(departmentData.data);
        await setListDepartmentDataTotal(departmentData.total);

        this.setState({
          departmentList: departmentData.data,
          departmentListTotal: departmentData.total,
        });
      } else if (departmentData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("Error fetching departments:", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      loginUserData,
    } = this.props;

    try {
      this.setState({ isLoading: true });

      let addData = await createDepartment(authToken, values);

      if (addData.status === 200) {
        await this.fetchDepartmentData();
        notification["success"]({
          message: "Department Added Successfully",
        });
      } else if (addData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "There was a problem adding the Department",
      });
      console.log("Error adding department:", e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const { listDepartmentData, listDepartmentDataTotal, loginUserData } =
      this.props;

    return (
      <Fragment>
        <Title parent="Department" title="Departments" />

        {checkPermission(loginUserData, "department", "add") && (
          <div
            style={{
              marginTop: "20px",
              display: "inline-block",
              width: "100%",
              marginBottom: "20px",
              paddingLeft: "14px",
              paddingRight: "55px",
            }}
          >
            <div style={{ float: "left", cursor: "pointer" }}>
              <AddDepartment
                primaryColor={primaryColor}
                onSubmit={this.addSubmit}
                Token={this.props.authToken}
                LoginData={this.props.loginUserData}
                departmentId={this.state.selectedDepartmentId}
                departmentName={this.state.selectedDepartmentName}
                isEditMode={this.state.isEditMode}
              />
            </div>
          </div>
        )}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <DepartmentRecordTable
                departmentList={listDepartmentData}
                departmentTotal={listDepartmentDataTotal}
                primaryColor={primaryColor}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({ type: "SET_LOGIN_FLAG", flag: flag });
    },
    setRouteName: (routeName) => {
      dispatch({ type: "SET_ROUTE_NAME", routeName: routeName });
    },
    setSearchText: (searchText) => {
      dispatch({ type: "SET_SEARCH_TEXT", searchText: searchText });
    },
    setListDepartmentData: (data) => {
      dispatch({ type: "SET_LIST_DEPARTMENT_DATA", listDepartmentData: data });
    },
    setListDepartmentDataTotal: (total) => {
      dispatch({
        type: "SET_LIST_DEPARTMENT_DATA_TOTAL",
        listDepartmentDataTotal: total,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Department));
