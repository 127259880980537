export const checkPermission = (loginUserData, permissionType, action) => {
  if (!loginUserData || !loginUserData.permissions) return false;

  // console.log("loginUserData:", loginUserData);
  // console.log("permissionType:", permissionType);
  // console.log("action:", action);

  const permissions = loginUserData.permissions[permissionType];

  if (!permissions) return false;

  if (action === "add" || action === "create") {
    if (loginUserData.role === "corporate") {
      // If role is corporate, check for 'add'
      return permissions["add"] === true;
    } else if (loginUserData.role === "admin") {
      // If role is admin, check for 'create'
      return permissions["create"] === true;
    }
  }

  // For other actions (delete, view, edit)
  return permissions[action] === true;
};
