import React, { Fragment } from "react";
import man from "../../../assets/images/dashboard/boy-2.png";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";

const UserPanel = ({ adminName }) => {
  const url = "";

  return (
    <Fragment>
      <div className="sidebar-user text-center">
        <div>
          <img
            className="img-50 rounded-circle lazyloaded blur-up"
            src={url || "https://img.freepik.com/premium-vector/default-avatar-profile-icon-social-media-user-image-gray-avatar-icon-blank-profile-silhouette-vector-illustration_561158-3383.jpg"} 
            alt="Profile"
          />
          {/* <div className="profile-edit">
            <Link to="#">
              <Edit />
            </Link>
          </div> */}
        </div>
        <h6 className="mt-3 f-14">{adminName}</h6>
      </div>
    </Fragment>
  );
};

export default UserPanel;
