import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllFaq } from "../../Graphs/generalFaq/listFaq";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import FaqRecordsTable from "./FaqRecordsTable";
import { AddFaq } from "./AddFaq";
import { addFaq } from "../../Graphs/generalFaq/addFaq";

class GeneralFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generalfaqList: [],
      generalfaqTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };

  }

  async componentDidMount() {
    try {
      console.log("Component..");
      const { setRouteName } = this.props;
      let editPrimaryColor = localStorage.getItem("primary_color");

      let routeName = "general_FAQ";

      await setRouteName(routeName);

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });
      await this.listGeneralFaqData();
      console.log("Listed: ");
    } catch (e) {
      notification["success"]({
        message: "Faq",
        description: "Somwthing went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listGeneralFaqData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListGeneralFaqData,
      setListGeneralFaqDataTotal
    } = this.props;

    try {
      let GeneralfaqData = await getAllFaq(authToken, 0, 100);
      console.log("Data: ", GeneralfaqData);

      if (GeneralfaqData.status === 200) {

        //set faq data
        // await setListGeneralFaqData(GeneralfaqData.data);
        console.log("ABCD", GeneralfaqData.data);
        await setListGeneralFaqData(GeneralfaqData.data);
        console.log("abhs", GeneralfaqData.data);
        await setListGeneralFaqDataTotal(GeneralfaqData.total);

        this.setState({
          GeneralfaqList: GeneralfaqData.data,
          // faqList: faqData.data.FAQs.docs,
          GeneralfaqTotal: GeneralfaqData.total,
        });
      } else if (GeneralfaqData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("Error", e);
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { history, authToken, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });
      let addGeneralFaqData = await addFaq(authToken, values);
      if (addGeneralFaqData.status === 200) {
        //set faq data
        await this.listGeneralFaqData();
      } else if (addGeneralFaqData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!e", e);
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const { listGeneralFaqData, listGeneralFaqDataTotal  } = this.props;

    return (
      <Fragment>
        {/* {!course_id ? <Title parent="Faq" title="faq" /> : null} */}

        <Title parent="General FAQ's" title="general faq's" />

        <div
          style={{
            marginTop: "50px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "left", cursor: "pointer" }}>
            <AddFaq
              primaryColor={primaryColor}
              onSubmit={this.addSubmit}
            //   course_id={course_id}
            />
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <FaqRecordsTable
                listGeneralFaqData={listGeneralFaqData}
                primaryColor={primaryColor}
                listGeneralFaqDataTotal={listGeneralFaqDataTotal}
                // course_id={course_id}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listGeneralFaqData: state.faq.listGeneralFaqData,
  listGeneralFaqDataTotal: state.faq.listGeneralFaqDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListGeneralFaqData: (listGeneralFaqData) => {
      dispatch({
        type: "SET_LIST_FAQ_DATA",
        listGeneralFaqData: listGeneralFaqData,
      });
    },
    setListGeneralFaqDataTotal: (listGeneralFaqDataTotal) => {
      dispatch({
        type: "SET_LIST_FAQ_DATA_TOTAL",
        listGeneralFaqDataTotal: listGeneralFaqDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    // setSearchText: (searchText) => {
    //   dispatch({
    //     type: "SET_SEARCH_TEXT",
    //     searchText: searchText,
    //   });
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GeneralFaq));
