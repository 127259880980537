import { callApiToServer } from "../callApi";

export async function editCorporateUser(authtoken, userObject, userId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };
    
    userObject['user_id'] = userId;
    let body = JSON.stringify(userObject);
    console.log('user obj', userObject);
    let endUrl = "v1/auth/admin/edit-corporate-user";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}