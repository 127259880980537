import React, { Fragment, Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllUser } from "../../Graphs/User/listUser";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button, Table } from "antd";
import Title from "./Title";
import UserRecordsTable from "./UserRecordsTable";
import { AddUser } from "./AddUser";
import { addUser } from "../../Graphs/User/addUser";
import { checkPermission } from "../AdminUser/CheckPremission";
import { addCorporateUser } from "../../Graphs/User/addCorporateUser";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userListTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (
      !checkPermission(this.props.loginUserData.permissions, "user", "view")
    ) {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("USER");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listUserData();
    } catch (e) {
      notification["error"]({
        message: "User",
        description: "Somwthing went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listUserData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListUserData,
      setListUserDataTotal,
    } = this.props;

    try {
      let userData = await getAllUser(authToken, 0, 100);

      if (userData.status === 200) {
        //set user data
        // await setListUserData(userData.data.docs);
        await setListUserData(userData.data);
        await setListUserDataTotal(userData.total);

        this.setState({
          // userList: userData.data.docs,
          userList: userData.data,
          userTotal: userData.total,
        });
      } else if (userData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  // addSubmit = async (values) => {
  //   const {
  //     authToken,
  //     history,
  //     setLoginFlag,
  //     setUserData,
  //     setUserToken,
  //     setListUserData,
  //     setListUserDataTotal,
  //   } = this.props;

  //   try {
  //     const { authToken } = this.props;
  //     this.setState({
  //       isLoading: true,
  //     });
  //     console.log(values);
  //     let addUserData = await addUser(authToken, values);

  //     if (addUserData.status === 200) {
  //       await this.listUserData();
  //       notification["success"]({
  //         message: "User Added Successfully",
  //       });
  //     } else if (addUserData.status === 401) {
  //       await setLoginFlag(false);
  //       await setUserData(null);
  //       await setUserToken(null);
  //       history.push(`${process.env.PUBLIC_URL}/login`);
  //     }
  //   } catch (e) {
  //     notification["error"]({
  //       message: "There was a problem in Adding User",
  //     });
  //     console.log("!!!!!!!!e", e);
  //   }
  // };
  
  addSubmit = async (values) => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListUserData,
      setListUserDataTotal,
      loginUserData, // Add this to props to get user role
    } = this.props;
  
    try {
      this.setState({
        isLoading: true,
      });
  
      console.log(values);
  
      let addUserData;
      
      if (loginUserData.role === "corporate") {
        addUserData = await addCorporateUser(authToken, values); // Call corporate API
      } else {
        addUserData = await addUser(authToken, values); // Call regular API
      }
  
      if (addUserData.status === 200) {
        await this.listUserData();
        notification["success"]({
          message: "User Added Successfully",
        });
      } else if (addUserData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }

      await this.listUserData();
    } catch (e) {
      notification["error"]({
        message: "There was a problem in Adding User",
      });
      console.log("!!!!!!!!e", e);
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  

  render() {
    const { primaryColor, isLoading } = this.state;
    const { listUserData, listUserDataTotal, loginUserData } = this.props;

    return (
      <Fragment>
        <Title parent="User" title="Users" />

        {checkPermission(loginUserData, "user", "add") && (
          <div
            style={{
              marginTop: "20px",
              display: "inline-block",
              width: "100%",
              marginBottom: "20px",
              paddingLeft: "14px",
              paddingRight: "55px",
            }}
          >
            <div style={{ float: "left", cursor: "pointer" }}>
              <AddUser primaryColor={primaryColor} onSubmit={this.addSubmit} Token={this.props.authToken} LoginData={this.props.loginUserData}/>
            </div>
          </div>
        )}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <UserRecordsTable
                userList={listUserData}
                userTotal={listUserDataTotal}
                primaryColor={primaryColor}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listUserData: state.user.listUserData,
  listUserDataTotal: state.user.listUserDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserData: (listUserData) => {
      dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: listUserData,
      });
    },
    setListUserDataTotal: (listUserDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: listUserDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));
