import React from "react";
import { Button, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { checkPermission } from "../AdminUser/CheckAdminPermission";

export const getCourseTableColumns = (
  serialCounter,
  onChangeStatus,
  authToken,
  handleEditClick,
  handleDeleteGroupCourse,
  LoginData
) => {
  let columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 30,
      render: (text, item, index) => <span>{serialCounter + index}</span>,
    },
    {
      title: "Name",
      key: "name",
      width: 150,
      render: (text, item) => item.courses.courseDetails.name || "-",
    },
    {
      title: "Max User Count",
      key: "userCount",
      width: 150,
      render: (text, item) => item.courses.max_user_count || "-",
    },
    {
      title: "Assign User Count", // New Column
      key: "assignUserCount",
      width: 240,
      render: (text, item) => item.courses.assign_count || "0", // Assign Count from API
    },
    {
        title: "Actions",
        width: 150,
        key: "action",
        render: (text, item) => {
          const canEdit = checkPermission(LoginData, "users", "edit");
          const canDelete = checkPermission(LoginData, "users", "delete");
  
          return (
            <div style={{ display: "inline-block", width: "100%" }}>
              {canEdit && (
                <div style={{ float: "left" }}>
                  <Tooltip title="Edit">
                    <Button
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => handleEditClick(item)}
                    />
                  </Tooltip>
                </div>
              )}
              {canDelete && (
                <div style={{ float: "left", marginLeft: "10px" }}>
                  <Tooltip title="Delete">
                    <Button
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteGroupCourse(item.courses.course_id)}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          );
        },
    }
  ];

  if (LoginData.role === "corporate") {
    columns  = columns.filter((item) => item.key !== "action");
  }

  return columns;
};
