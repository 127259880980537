import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllSubCategory } from "../../Graphs/SubCategory/listSubCategory";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteSubCategory } from "../../Graphs/SubCategory/deleteSubCategory";
import { changeStatus } from "../../Graphs/SubCategory/changeStatus";
import { editSubCategory } from "../../Graphs/SubCategory/editSubCategory";
import { searchSubCategory } from "../../Graphs/SubCategory/searchSubCategory";
import { EditModal } from "./EditModal";
import { checkPermission } from "../AdminUser/CheckAdminPermission";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class SubCategoryRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedSubCategory = this.getLimitedSubCategory.bind(this);
  }

  async componentDidMount() {
      const { loginUserData } = this.props;
    
      // Check if user has any permission (edit, delete, view)
      const canEdit = checkPermission(loginUserData, "users", "edit");
      const canDelete = checkPermission(loginUserData, "users", "delete");
      const canView = checkPermission(loginUserData, "users", "view");
    
      // Remove "Actions" column if no permissions are granted
      if (!canEdit && !canDelete && !canView) {
        this.columns = this.columns.filter((item) => item.key !== "action");
      }
    
      // Keep existing state logic
      this.setState({
        data: this.props.data,
        isLoading: false,
        userSelectedData: null,
      });
    }

  modalRef;

  async getLimitedSubCategory(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText !== "") {
          //get search text props from redux store
          this.listSearchSubCategoryData(start, end);
        } else {
          //call normal user Data
          this.listSubCategoryData(start, end);
        }
      }
    );
  }

  listSearchSubCategoryData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListSubCategoryData,
        setListSubCategoryDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let subCategoryData = await searchSubCategory(
        authToken,
        searchText,
        start,
        end
      );

      if (subCategoryData.status === 200) {
        //set user Data
        await setListSubCategoryData(subCategoryData.data);
        await setListSubCategoryDataTotal(subCategoryData.total);

        this.setState({
          isLoading: false,
          data: subCategoryData.data,
        });
      } else if (subCategoryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list search user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listSubCategoryData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListSubCategoryData,
        setListSubCategoryDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let subCategoryData = await getAllSubCategory(authToken, start, end);

      if (subCategoryData.status === 200) {
        //set user Data
        // await setListSubCategoryData(subCategoryData.data.docs);
        await setListSubCategoryData(subCategoryData.data);
        await setListSubCategoryDataTotal(subCategoryData.total);
      } else if (subCategoryData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        subCategorySelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      subCategorySelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      width: 150,
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      width: 150,
      key: "name",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Name">
                <div>{item.name ? item.name : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Category",
      width: 150,
      key: "category",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Category">
                <div>
                  {item.category_id && item.category_id.name
                    ? item.category_id.name
                    : "-"}
                </div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Active",
      width: 100,
      key: "is_active",
      render: (text, item, index) => {
        const { loginUserData } = this.props;
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement="bottom" title="Sub Category Activation">
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active
                        ? this.props.primaryColor
                        : "#bababa",
                    }}
                    onChange={this.onChange.bind(this, item)}
                    disabled={loginUserData.role === "corporate"}
                  />
                }
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        const { loginUserData } = this.props;
        const canEdit = checkPermission(loginUserData, "users", "edit");
        const canDelete = checkPermission(loginUserData, "users", "delete");
        // const canView = checkPermission(loginUserData, "users", "view");


        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            { canEdit && (
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            )}
            { canDelete && (
              <div
                ml="15px"
                mr="5px"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={this.deleteSubCategoryData.bind(this, item)}
                    title="Are you sure you want to delete Sub Category?"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  async deleteSubCategoryData(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteSubCategory(authToken, item._id);

      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete  ",
          description: "Successfully deleted",
        });

        /*if (searchText != null && searchText !== "") {
          this.listSearchSubCategoryData(startIndex, limitNumber);
          return;
        } */
        this.listSubCategoryData(startIndex, limitNumber);
      } else if (deleteData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Delete Sub Category",
        description: e,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item._id, checked);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Change Sub Category Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchSubCategoryData(startIndex, limitNumber);
          return;
        }
        this.listSubCategoryData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (err) {
      notification["error"]({
        message: "Change Sub Category Status",
        description: err,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (subCategoryObject, subCategoryId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editSubCategory(authToken, subCategoryObject);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Edit Sub Category Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchSubCategoryData(startIndex, limitNumber);
          return;
        }
        this.listSubCategoryData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Edit Sub Category",
        description: e,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedSubCategory(pagination)
    );
  }

  render() {
    const { isLoading, data } = this.state;
    const {
      listSubCategoryData,
      listSubCategoryDataTotal,
      searchLoader,
      loginUserData,
    } = this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ overflowX: "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listSubCategoryData}
          pagination={{
            total: listSubCategoryDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {/* {this.state.subCategorySelectedData &&
        checkPermission(loginUserData.permissions, "sub_category", "edit") ? ( */}
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            subCategorySelectedData={this.state.subCategorySelectedData}
            onSubmit={this.editSubmit}
          />
        {/* ) : null} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listSubCategoryData: state.subcategory.listSubCategoryData,
  listSubCategoryDataTotal: state.subcategory.listSubCategoryDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListSubCategoryData: (listSubCategoryData) => {
      dispatch({
        type: "SET_LIST_SUB_CATEGORY_DATA",
        listSubCategoryData: listSubCategoryData,
      });
    },
    setListSubCategoryDataTotal: (listSubCategoryDataTotal) => {
      dispatch({
        type: "SET_LIST_SUB_CATEGORY_DATA_TOTAL",
        listSubCategoryDataTotal: listSubCategoryDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubCategoryRecordsTable));
