import { callApiToServer } from "../callApi";

export const extendValidity = (authToken, userObject) => async (dispatch) => {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}` 
    };
    
    let body = JSON.stringify(userObject);
    console.log("Request Body:", body);

    let endUrl = "/v1/auth/admin/update-user-course-validity";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    console.error("Error in extendValidity:", err);
    dispatch({
      type: 'EXTEND_VALIDITY_ERROR',
      payload: err.message,
    });
    throw err;
  }
};
