import { callApiToServer } from "../callApi";

export async function updateDepartment(Token, departmentData) {
    try {
      let headers = {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      };
  
      let endUrl = "/v1/auth/admin/department";
  
      const response = await callApiToServer(
        JSON.stringify(departmentData),
        headers,
        "PUT",
        endUrl
      );
  
      console.log("Update Department Response:", response);
      return response;
    } catch (err) {
      console.error("Update Department Error:", err);
      throw err;
    }
  }
  