import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select, DatePicker, Upload, notification } from "antd";
import { Formik, FieldProps, Field } from "formik";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getDepartments } from "../../Graphs/Department/GetDepartment";
import { createDepartment } from "../../Graphs/Department/AddDepartment";

const { Content } = Layout;
const Option = Select.Option;

export class AddDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      selectedType: "",
      departments: [],
      selectedDepartment: "",
    };
  }

  input;
  allDepartments = [];

  //   validateForm = (values) => {
  //     const errors = {};
  //     console.log("Validating form -- ", values);
  //     console.log("Validating errors -- ", errors);
  //     return errors;
  //   };

  //   handleSubmit = async (values, action) => {
  //     const { selectedDepartment } = this.state;
  //     values.department_id = [selectedDepartment];
  //     console.log(values.department);
  //     this.props.onSubmit(values);
  //     this.setState({
  //       visible: false,
  //     });
  //   };

  handleSubmit = async (values, action) => {
    const { selectedDepartment } = this.state;
    const { Token } = this.props;
    console.log("Auth token: ", Token);

    // Prepare department data
    const departmentData = {
      name: selectedDepartment,
    };

    console.log("Submitting department data:", departmentData);

    try {
      const response = await createDepartment(Token, departmentData);
      console.log("Department created successfully:", response);

      if (response.status === 200) {
        console.log("Department created:", response.data);

        notification.success({
          message: "Success",
          description: "Department successfully added!",
        });
        
        // Trigger the department table refresh
        if (window.departmentTableRef && typeof window.departmentTableRef.fetchDepartments === 'function') {
          window.departmentTableRef.fetchDepartments();
        }
      }
    } catch (err) {
      console.error("Error creating department:", err);
      notification.error({
        message: "Error",
        description: "Unable to add Department!",
      });
    }

    this.setState({
      visible: false,
      selectedDepartment: "",
      // message: "Department created",
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
      selectedDepartment: "",
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
      selectedDepartment: "",
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,

      selectedDepartment: "",
    });
  };

  render() {
    const { primaryColor, LoginData } = this.props;
    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          destroyOnClose={true}
          title="Add Department"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          maskClosable={false}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                department: "",
              }}
              //   validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({ handleSubmit, isSubmitting }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Department
                        <Input
                          id="department"
                          placeholder="Enter Department Name"
                          value={this.state.selectedDepartment}
                          onChange={(e) =>
                            this.setState({
                              selectedDepartment: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(AddDepartment));
