import { Modal, Button } from "antd";
import * as React from "react";
import { Layout, Input } from "antd";
import { Formik } from "formik";

const { TextArea } = Input;
const { Content } = Layout;

export class AddFaq extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
    };
  }

  input;

  async componentDidMount() {
  }

  validateForm = (values) => {
    // const { selectedCourseType } = this.state;

    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.question) {
      errors.question = "Question is required";
    }

    if (!values.answer) {
      errors.answer = "Answer is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  };

  handleSubmit = (values, action) => {
    // const { selectedCourseType } = this.state;

    values.FAQs = [{ question: values.question, answer: values.answer }];
    values.question = values.question;
    values.answer = values.answer;

    this.props.onSubmit(values);

    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  // changeCourse = (value) => {
  //   this.setState({
  //     selectedCourseType: value,
  //   });
  // };

  render() {
    const { primaryColor } = this.props;
    // const { selectedCourseType } = this.state;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          destroyOnClose={true}
          title="Add Faq"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          maskClosable={false}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                question: "",
                answer: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Question
                          <Input
                            id="question"
                            placeholder="Question"
                            value={values.question}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.question && touched.question ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.question}
                            </p>
                          ) : null}
                        </div>

                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field">
                          Answer
                          <TextArea
                            row={4}
                            id="answer"
                            placeholder="Answer"
                            value={values.answer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.answer && touched.answer ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.answer}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <Button
                        type="primary"
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}
