import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllSubCategory } from "../../Graphs/SubCategory/listSubCategory";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import SubCategoryRecordsTable from "./SubCategoryRecordsTable";
import { AddSubCategory } from "./AddSubCategory";
import { addSubCategory } from "../../Graphs/SubCategory/addSubCategory";
import { checkPermission } from "../AdminUser/CheckPremission";
import {
  ACCESS_DENIED_MESSAGE,
  ACCESS_DENIED_MESSAGE_DESCRIPTION,
} from "../../constant/comman";

class SubCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subCategoryList: [],
      subCategoryTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (this.props.loginUserData.role === "corporate") {
          notification["error"]({
            message: ACCESS_DENIED_MESSAGE,
            description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
          });
          this.props.history.replace("/dashboard");
        }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("SUB_CATEGORY");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listSubCategoryData();
    } catch (e) {
      notification["success"]({
        message: "SubCategory",
        description: "Somwthing went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listSubCategoryData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListSubCategoryData,
      setListSubCategoryDataTotal,
    } = this.props;

    try {
      let subCategoryData = await getAllSubCategory(authToken, 0, 10);

      if (subCategoryData.status === 200) {
        //set subCategory data
        // await setListSubCategoryData(subCategoryData.data.docs);
        await setListSubCategoryData(subCategoryData.data);
        await setListSubCategoryDataTotal(subCategoryData.total);

        this.setState({
          subCategoryList: subCategoryData.data,
          // subCategoryList: subCategoryData.data.docs,
          subCategoryTotal: subCategoryData.total,
        });
      } else if (subCategoryData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { history, authToken, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });
      let addSubCategoryData = await addSubCategory(authToken, values);
      if (addSubCategoryData.status === 200) {
        //set subCategory data
        await this.listSubCategoryData();
      } else if (addSubCategoryData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!e", e);
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const { subCategoryList, subCategoryTotal, loginUserData } = this.props;

    return (
      <Fragment>
        <Title parent="SubCategory" title="subCategory" />

        {checkPermission(
          loginUserData.permissions,
          "sub_category",
          "create"
        ) && (
          <div
            style={{
              marginTop: "20px",
              display: "inline-block",
              width: "100%",
              marginBottom: "20px",
              paddingLeft: "14px",
              paddingRight: "55px",
            }}
          >
            <div style={{ float: "left", cursor: "pointer" }}>
              <AddSubCategory
                primaryColor={primaryColor}
                onSubmit={this.addSubmit}
                loginData={this.props.loginUserData}
              />
            </div>
          </div>
        )}

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <SubCategoryRecordsTable
                listSubCategoryData={subCategoryList}
                primaryColor={primaryColor}
                listSubCategoryDataTotal={subCategoryTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  subCategoryList: state.subcategory.listSubCategoryData,
  subCategoryTotal: state.subcategory.listSubCategoryDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListSubCategoryData: (listSubCategoryData) => {
      dispatch({
        type: "SET_LIST_SUB_CATEGORY_DATA",
        listSubCategoryData: listSubCategoryData,
      });
    },
    setListSubCategoryDataTotal: (listSubCategoryDataTotal) => {
      dispatch({
        type: "SET_LIST_SUB_CATEGORY_DATA_TOTAL",
        listSubCategoryDataTotal: listSubCategoryDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubCategory));
