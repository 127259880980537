import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllModule } from "../../Graphs/Module/listModule";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import ModuleRecordsTable from "./ModuleRecordsTable";
import { AddModule } from "./AddModule";
import { addModule } from "../../Graphs/Module/addModule";
import { checkPermission } from "../AdminUser/CheckPremission";
import { ACCESS_DENIED_MESSAGE, ACCESS_DENIED_MESSAGE_DESCRIPTION } from "../../constant/comman";

class Module extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleList: [],
      moduleTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {

    //check the logged-in user has the module view permission else it will redirect to the defined route start
    if (!checkPermission(this.props.loginUserData.permissions, "course", "view")) {
      notification["error"]({
        message: ACCESS_DENIED_MESSAGE,
        description: ACCESS_DENIED_MESSAGE_DESCRIPTION,
      });
      this.props.history.replace("/dashboard");
    }
    //check the logged-in user has the module view permission else it will redirect to the defined route end

    try {
      const { setRouteName, setSearchText, course_id } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      let routeName = "MODULE";
      if (course_id) {
        routeName = "COURSE_MODULE";
      }

      await setRouteName(routeName);
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listModuleData();
    } catch (e) {
      notification["success"]({
        message: "Module",
        description: "Somwthing went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listModuleData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListModuleData,
      setListModuleDataTotal,
      course_id,
    } = this.props;

    try {
      let moduleData = await getAllModule(authToken, 0, 10, course_id);

      if (moduleData.status === 200) {
        //set module data
        // await setListModuleData(moduleData.data.docs);
        await setListModuleData(moduleData.data);
        await setListModuleDataTotal(moduleData.total);

        this.setState({
          moduleList: moduleData.data,
          // moduleList: moduleData.data.docs,
          moduleTotal: moduleData.total,
        });
      } else if (moduleData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { history, authToken, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });
      let addModuleData = await addModule(authToken, values);
      if (addModuleData.status === 200) {
        //set module data
        await this.listModuleData();
      } else if (addModuleData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!e", e);
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const { listModuleData, listModuleDataTotal, course_id } = this.props;

    return (
      <Fragment>
        {!course_id ? <Title parent="Module" title="module" /> : null}
        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "left", cursor: "pointer" }}>
            <AddModule
              primaryColor={primaryColor}
              course_id={course_id}
              onSubmit={this.addSubmit}
              logindata={this.props.loginUserData}
            />
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <ModuleRecordsTable
                listModuleData={listModuleData}
                primaryColor={primaryColor}
                listModuleDataTotal={listModuleDataTotal}
                course_id={course_id}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  listModuleData: state.module.listModuleData,
  listModuleDataTotal: state.module.listModuleDataTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListModuleData: (listModuleData) => {
      dispatch({
        type: "SET_LIST_MODULE_DATA",
        listModuleData: listModuleData,
      });
    },
    setListModuleDataTotal: (listModuleDataTotal) => {
      dispatch({
        type: "SET_LIST_MODULE_DATA_TOTAL",
        listModuleDataTotal: listModuleDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Module));
