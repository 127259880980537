import { callGetApiToServer } from "../callApi";

export async function getUserCourseQuizDetails(authtoken,data) {
//   console.log(authtoken,limitNumber,data);
  const { userId,courseId } = data
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    // let endUrl = "v1/auth/admin/user-courses/" + skipNumber + "/" + limitNumber;
    let endUrl = `v1/auth/admin/user-course-topics/${userId}/${courseId}`

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
