import * as React from "react";
import { Modal, Spin, Tabs } from "antd";
import { EditForm } from "./EditForm";
import ListUserCourseData from "./ListUserCourseData";
import { getAllUser } from "../../Graphs/User/listUser";
const { TabPane } = Tabs;

export class ListModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      tabKey: "inprogess",
    };
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    // ✅ Extract data from history state
    const adminSelectedData = this.props.location.state.adminSelectedData;
    console.log("Admin Selected Data: ", this.props.location.state.adminSelectedData);
    if (adminSelectedData) {
      this.setState({ recordDetails: adminSelectedData }, () =>
        this.fetchUserData(adminSelectedData)
      );
    }
  }

  fetchUserData = async (data) => {
    this.setState({ isLoading: true });
    try {
      const result = await getAllUser(this.props.authToken, 0, 10);
      console.log("API Result:", result);
      if (result.status === 200) {
        this.setState({
          data: result.data,
          isLoading: false,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ isLoading: false });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  // show() {
  //     this.setState({
  //         visible: true
  //     });
  // }

  // hide() {
  //     this.setState({
  //         visible: false
  //     });
  //     this.props.onClose();
  // }

  render() {
    return (
      <div style={{ padding: "20px" }}>
        <h2>List User Courses</h2>
        {/* <ListUserCourseData adminSelectedData={this.props?.adminSelectedData} /> */}
        <Tabs
          defaultActiveKey="session"
          onChange={(key) => {
            console.log({ key });
            //   setTabKey(key)
            this.setState({
              tabKey: key,
            });
          }}
          type="card"
        >
          {/* <TabPane tab="In Progress" key="inprogess">
                            {this.state.tabKey === 'inprogess' && (
                                <ListUserCourseData adminSelectedData={this.props.adminSelectedData} type={this.state.tabKey} key={this.state.tabKey}/>
                            )}
                        </TabPane>
                        <TabPane tab="Completed" key="completed">
                            {this.state.tabKey === 'completed' && (
                                <ListUserCourseData adminSelectedData={this.props.adminSelectedData} type={this.state.tabKey} key={this.state.tabKey}/>
                            )}
                        </TabPane> */}

          <TabPane tab="In Progress" key="inprogess">
            {this.state.tabKey === "inprogess" && (
              <ListUserCourseData
                adminSelectedData={this.state.recordDetails}
                type={this.state.tabKey}
                data={this.state.data}
                key={this.state.tabKey}
              />
            )}
          </TabPane>
          <TabPane tab="Completed" key="completed">
            {this.state.tabKey === "completed" && (
              <ListUserCourseData
                adminSelectedData={this.state.recordDetails}
                type={this.state.tabKey}
                data={this.state.data}
                key={this.state.tabKey}
              />
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
