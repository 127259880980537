//CUSTOMIZER
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";

//LOGIN AND COMMAN (auth)
export const SET_LOGIN_FLAG = "SET_LOGIN_FLAG";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_AUTHTOKEN = "SET_USER_AUTHTOKEN";
export const SET_ROUTE_NAME = "SET_ROUTE_NAME";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_SEARCH_LOADER = "SET_SEARCH_LOADER";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";

//ADMIN
export const SET_LIST_ADMIN_DATA = "SET_LIST_ADMIN_DATA";
export const SET_LIST_ADMIN_DATA_TOTAL = "SET_LIST_ADMIN_DATA_TOTAL";

//USER
export const SET_LIST_USER_DATA = "SET_LIST_USER_DATA";
export const SET_LIST_USER_DATA_TOTAL = "SET_LIST_USER_DATA_TOTAL";
export const SET_LIST_USER_COURSE_DATA = "SET_LIST_USER_COURSE_DATA";

//CATEGORY
export const SET_LIST_CATEGORY_DATA = "SET_LIST_CATEGORY_DATA";
export const SET_LIST_CATEGORY_DATA_TOTAL = "SET_LIST_CATEGORY_DATA_TOTAL";

//SUB CATEGORY
export const SET_LIST_SUB_CATEGORY_DATA = "SET_LIST_SUB_CATEGORY_DATA";
export const SET_LIST_SUB_CATEGORY_DATA_TOTAL =
  "SET_LIST_SUB_CATEGORY_DATA_TOTAL";

//LANGUAGE
export const SET_LIST_LANGUAGE_DATA = "SET_LIST_LANGUAGE_DATA";
export const SET_LIST_LANGUAGE_DATA_TOTAL = "SET_LIST_LANGUAGE_DATA_TOTAL";

//COURSE
export const SET_LIST_COURSE_DATA = "SET_LIST_COURSE_DATA";
export const SET_LIST_COURSE_DATA_TOTAL = "SET_LIST_COURSE_DATA_TOTAL";
export const SET_SINGLE_COURSE_DATA = "SET_SINGLE_COURSE_DATA";

//MODULE
export const SET_LIST_MODULE_DATA = "SET_LIST_MODULE_DATA";
export const SET_LIST_MODULE_DATA_TOTAL = "SET_LIST_MODULE_DATA_TOTAL";

//COURSE SESSION
export const SET_LIST_COURSE_SESSION_DATA = "SET_LIST_COURSE_SESSION_DATA";
export const SET_LIST_COURSE_SESSION_DATA_TOTAL =
  "SET_LIST_COURSE_SESSION_DATA_TOTAL";

//COURSE SESSION TOPIC
export const SET_LIST_COURSE_SESSION_TOPIC_DATA =
  "SET_LIST_COURSE_SESSION_TOPIC_DATA";
export const SET_LIST_COURSE_SESSION_TOPIC_DATA_TOTAL =
  "SET_LIST_COURSE_SESSION_TOPIC_DATA_TOTAL";

//FAQ
export const SET_LIST_FAQ_DATA = "SET_LIST_FAQ_DATA";
export const SET_LIST_FAQ_DATA_TOTAL = "SET_LIST_FAQ_DATA_TOTAL";

//TESTIMONIAL
export const SET_LIST_TESTIMONIAL_DATA = "SET_LIST_TESTIMONIAL_DATA";
export const SET_LIST_TESTIMONIAL_DATA_TOTAL =
  "SET_LIST_TESTIMONIAL_DATA_TOTAL";

//GROUP
export const SET_LIST_GROUP_DATA = "SET_LIST_GROUP_DATA";
export const SET_LIST_GROUP_DATA_TOTAL = "SET_LIST_GROUP_DATA_TOTAL";

//APP CONTENT
export const SET_LIST_APPCONTENT_DATA = "SET_LIST_APPCONTENT_DATA";
export const SET_LIST_APPCONTENT_DATA_TOTAL = "SET_LIST_APPCONTENT_DATA_TOTAL";
