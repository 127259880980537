import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllFaq } from "../../Graphs/Faq/listFaq";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification, Button } from "antd";
import FaqRecordsTable from "./FaqRecordsTable";
import { AddFaq } from "./AddFaq";
import { addFaq } from "../../Graphs/Faq/addFaq";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqList: [],
      faqTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
      envData: null,
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText, course_id } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      let routeName = "FAQ";
      if (course_id) {
        routeName = "COURSE_FAQ";
      }

      await setRouteName(routeName);
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listFaqData();
    } catch (e) {
      notification["success"]({
        message: "Faq",
        description: "Somwthing went wrong",
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listFaqData = async () => {
    const {
      authToken,
      history,
      setLoginFlag,
      setUserData,
      setUserToken,
      setListFaqData,
      setListFaqDataTotal,
      course_id,
    } = this.props;

    try {
      let faqData = await getAllFaq(authToken, 0, 10, course_id);

      if (faqData.status === 200) {
        //set faq data
        // await setListFaqData(faqData.data.FAQs.docs);
        await setListFaqData(faqData.data.FAQs);
        await setListFaqDataTotal(faqData.total);

        this.setState({
          faqList: faqData.data.FAQs,
          // faqList: faqData.data.FAQs.docs,
          faqTotal: faqData.total,
        });
      } else if (faqData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  addSubmit = async (values) => {
    try {
      const { history, authToken, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });
      let addFaqData = await addFaq(authToken, values);
      if (addFaqData.status === 200) {
        //set faq data
        await this.listFaqData();
      } else if (addFaqData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!!e", e);
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, isLoading } = this.state;
    const { listFaqData, listFaqDataTotal, course_id } = this.props;

    return (
      <Fragment>
        {!course_id ? <Title parent="Faq" title="faq" /> : null}

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "left", cursor: "pointer" }}>
            <AddFaq
              primaryColor={primaryColor}
              onSubmit={this.addSubmit}
              course_id={course_id}
              loginData={this.props.loginUserData}
            />
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <FaqRecordsTable
                listFaqData={listFaqData}
                primaryColor={primaryColor}
                listFaqDataTotal={listFaqDataTotal}
                course_id={course_id}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listFaqData: state.faq.listFaqData,
  listFaqDataTotal: state.faq.listFaqDataTotal,
  loginUserData: state.auth.loginUserData
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListFaqData: (listFaqData) => {
      dispatch({
        type: "SET_LIST_FAQ_DATA",
        listFaqData: listFaqData,
      });
    },
    setListFaqDataTotal: (listFaqDataTotal) => {
      dispatch({
        type: "SET_LIST_FAQ_DATA_TOTAL",
        listFaqDataTotal: listFaqDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Faq));
