import { Modal, Button, Divider, Layout, Input, Select } from "antd";
import * as React from "react";
import { Formik } from "formik";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { AddCourseSessionMediaFiles } from "./AddCourseSessionMediaFiles";
import { PictureOutlined } from "@ant-design/icons";
import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";
import { COURSE_SESSION_MEDIA_PREVIEW_PATH } from "../../constant/comman";
import MediaItem from "../Media/MediaItem";
import { checkPermission } from "../AdminUser/CheckAdminPermission";

const { Content } = Layout;
const { TextArea } = Input;
const Option = Select.Option;

var amenities = [];

export class AddCourseSession extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      viewBannerModal: false,
      viewMediaModal: false,
      viewBenefitIconModal: false,
      isLoading: false,
      visible: false,
      selectedCourseType: "",
      selectedModuleType: "",
      selectedParentType: "course",
      isPaid: false,
      isRestricted: false,
      headerPoints: [],
      media: [],
      isShouldComponentUpdate: false,
      errors: {
        selectedCourseType: "",
        selectedParentType: "",
      },
    };
  }

  input;
  selectedColorType = "";
  selctedColorKey = "";
  headerPoints = [];
  allCourses = [];
  allModules = [];

  allTypes = [
    <Option value={"course"} key="course">
      Course
    </Option>,
    <Option value={"module"} key="module">
      Module
    </Option>,
  ];

  viewMediaModalRef;

  async componentDidMount() {
    const { authToken, course_id } = this.props;

    let courseData = await listAllDropDownCourse(authToken, 0, 1000000);

    if (courseData.data && courseData.data.length >= 1) {
      courseData.data.map((data, index) => {
        this.allCourses.push(
          <Option key={data._id} value={data._id}>
            {data.name}
          </Option>
        );
      });
    }

    if (course_id) {
      this.setState({
        selectedCourseType: course_id,
      });
    }

    // let moduleData = await listAllDropDownModule(authToken);

    // if (moduleData.data && moduleData.data.length >= 1) {
    //   moduleData.data.map((data, index) => {
    //     this.allModules.push(
    //       <Option value={data.courseData[0]._id + "-" + data._id}>
    //         {data.title}
    //       </Option>
    //     );
    //   });
    // }
  }

  validateForm = (values) => {
    const { selectedCourseType, media } = this.state;

    const errors = {};

    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }

    // if (!values.sort_number) {
    //   errors.sort_number = "Sort Number is required"
    // }

    if (!values.time) {
      errors.time = "Time is required";
    }

    if (!values.short_description) {
      errors.short_description = "Short Description is required";
    }

    if (!selectedCourseType) {
      errors.selectedCourseType = "Please select course";
    }

    // if (!media || media.length <= 0) {
    //   errors.media = "Please select at least one media";
    // }

    console.log("Validating errors -- ", errors);

    return errors;
  };

  handleSubmit = async (values, action) => {
    const {
      selectedParentType,
      selectedCourseType,
      selectedModuleType,
      media,
    } = this.state;

    values.course_id = selectedCourseType;
    // values.media = [...media];
    // values.header_points = this.headerPoints;
    // values.parent_type = selectedParentType;

    // if (selectedParentType == "module") {
    //   let tempValue = [];
    //   if (selectedModuleType) {
    //     tempValue = selectedModuleType.split("-");
    //     values.course_id = tempValue[0];
    //     values.module_id = tempValue[1];
    //   }
    // }

    this.props.onSubmit(values);

    this.setState({
      visible: false,
      selectedModuleType: "",
      isShouldComponentUpdate: false,
    });
  };

  changeCourseType = (value) => {
    this.setState({
      selectedCourseType: value,
    });
  };

  changeModuleType = (value) => {
    this.setState({
      selectedModuleType: value,
    });
  };

  // changeParentType = (value) => {
  //   this.setState({
  //     selectedModuleType: "",
  //     selectedCourseType: "",
  //     selectedParentType: value
  //   });
  // };

  showModal = () => {
    this.setState({
      visible: true,
      isShouldComponentUpdate: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      // media: [],
      errors: {},
      isShouldComponentUpdate: false,
    });
  };

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  // handleChangeHeaderPoints = (headerPoints) => {
  //   let stateHeaderPoints = this.state.headerPoints;

  //   headerPoints.map(headerPoint => {
  //     let tempHp = headerPoint.trim();
  //     tempHp = tempHp.toLowerCase();
  //     if (!this.state.headerPoints.map(item => item.toLowerCase()).includes(tempHp)) {
  //       this.headerPoints.push({ "header": headerPoint })
  //       stateHeaderPoints.push(headerPoint);
  //     }
  //   });

  //   this.setState({ headerPoints: stateHeaderPoints });
  // }

  // uploadMediaFiles = (mediaData) => {
  //   console.log("!!!!!!!!!!!!!!after upload", mediaData);
  //   if (mediaData && mediaData.length > 0) {
  //     this.setState({
  //       media: [...this.state.media, ...mediaData],
  //       viewMediaModal: false
  //     });
  //   } else {
  //     this.setState({
  //       viewMediaModal: false
  //     });
  //   }
  // }

  // showMediaModal(params) {
  //   this.setState(
  //     {
  //       viewMediaModal: true
  //     },
  //     () => {
  //       this.viewMediaModalRef.show();
  //     }
  //   );
  // }

  render() {
    const { primaryColor, course_id, loginData } = this.props;
    const canEdit = checkPermission(loginData, "users", "create");
    console.log("Can edit: ", canEdit);
    const { media, selectedCourseType } = this.state;

    return (
      <div>
        {canEdit && (
          <div
            onClick={this.showModal}
            style={{
              width: "100px",
              backgroundColor: primaryColor,
              color: "white",
              padding: "7px 0px",
              textAlign: "center",
              borderRadius: "50px",
            }}
          >
            ADD
          </div>
        )}

        <Modal
          destroyOnClose={true}
          title="Add Course Session"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          maskClosable={false}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: "",
                course_id: "",
                // sort_number: "",
                time: "",
                short_description: "",
                long_description: "",
              }}
              enableReinitialize
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field">
                        Name
                        <Input
                          id="name"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.name}
                          </p>
                        ) : null}
                      </div>

                      {/* <div className="formik-field-right">
                        Sort Number
                        <Input
                          id="sort_number"
                          placeholder="Sort Number"
                          value={values.sort_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.sort_number && touched.sort_number ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.sort_number}
                          </p>
                        ) : null}
                      </div> */}
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Course
                        {course_id ? (
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Select Course"
                            disabled
                            value={selectedCourseType}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.allCourses}
                          </Select>
                        ) : (
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={(value) => this.changeCourseType(value)}
                            placeholder="Select Course"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.allCourses}
                          </Select>
                        )}
                        {errors.selectedCourseType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.selectedCourseType}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Time
                        <Input
                          id="time"
                          placeholder="Time"
                          value={values.time}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.time && touched.time ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.time}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Type
                        <Select
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeParentType(value)}
                          placeholder="Select Type"
                          showSearch
                          value={selectedParentType}
                          // defaultValue="subAdmin"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allTypes}
                        </Select>
                        {this.state.errors.selectedParentType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedParentType}
                          </p>
                        ) : null}
                      </div>

                      {selectedParentType == "course" ?
                        <div className="formik-field-right">
                          Course
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={(value) => this.changeCourseType(value)}
                            placeholder="Select Course"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.allCourses}
                          </Select>
                          {this.state.errors.selectedCourseType ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.selectedCourseType}
                            </p>
                          ) : null}
                        </div>
                        :
                        <div className="formik-field-right">
                          Module
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={(value) => this.changeModuleType(value)}
                            placeholder="Select Module"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.allModules}
                          </Select>
                          {this.state.errors.selectedModuleType ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.selectedModuleType}
                            </p>
                          ) : null}
                        </div>
                      }
                    </div> */}

                    <div className="formik-field_wrap">
                      <div className="formik-field">
                        Short Description
                        <TextArea
                          row={4}
                          id="short_description"
                          placeholder="Short Description"
                          value={values.short_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.short_description &&
                        touched.short_description ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.short_description}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field">
                        Long Description
                        <TextArea
                          row={4}
                          id="long_description"
                          placeholder="Long Description"
                          value={values.long_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    {/* <div className="formik-field_wrap">
                      <div className="formik-field">
                        Header Points
                        <TagsInput
                          value={this.state.headerPoints}
                          onChange={this.handleChangeHeaderPoints}
                          onlyUnique={true}
                          placeholder="Header Points"
                        />
                      </div>
                    </div> */}

                    {/* <Divider></Divider> */}

                    {/* Course Session Media Section Start */}
                    {/* <div className="formik-field_wrap">
                      <div className="formik-field">
                        <div className="formik-field-wrap">
                          <div style={{ display: "inline-block", width: "100%", marginTop: "20px", alignItems: "center" }}>
                            <div style={{ float: "left", width: "10%" }}>
                              <PictureOutlined style={{ width: "100%", fontSize: "30px" }} />
                            </div>
                            <Button style={{ width: "90%" }} onClick={this.showMediaModal.bind(this)} >
                              Choose Media Files
                            </Button>

                            {errors.media ? (
                              <p style={{ color: "red", fontSize: "small", margin: "0", }} >
                                {errors.media}
                              </p>
                            ) : null}

                            {this.state.viewMediaModal ? (
                              <AddCourseSessionMediaFiles refx={(e) => (this.viewMediaModalRef = e)} onUpload={this.uploadMediaFiles} />
                            ) : null}
                          </div>

                          <div style={{ marginTop: "20px", width: "100%", backgroundColor: this.selectedColorType !== "" ? this.selectedColorType : "#ffffff", borderRadius: "10px" }}>
                            {media && media.length > 0 ? (
                              media.map((media) =>
                                <MediaItem name={media.media_name} url={`${COURSE_SESSION_MEDIA_PREVIEW_PATH}${media.media_name}`} thumbMediaUrl={`${COURSE_SESSION_MEDIA_PREVIEW_PATH}${media.thumb_media_name}`} />
                              )
                            ) : null}
                          </div>

                        </div>
                      </div>
                    </div> */}
                    {/* Course Session Media Section End */}

                    {/* <Divider></Divider> */}

                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}
