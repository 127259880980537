import { callApiToServer } from "../callApi";

export async function deleteDepartment(id, authToken) {

  console.log(authToken)
    try {
      let headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };
  
      let endUrl = `v1/auth/admin/department/${id}`;

      // const body = JSON.stringify(id);
      // console.log("Body", body);
  
      const response = await callApiToServer( null, headers, "DELETE", endUrl);
  
      console.log("Delete Department Response:", response);
      return response;
    } catch (err) {
      console.error("Delete Department Error:", err);
      throw err;
    }
  }
  