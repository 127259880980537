import * as React from 'react';
import { Upload, message, Button, Modal } from 'antd';
import styled from 'styled-components';
import { COURSE_H5P_UPLOAD_PATH } from '../../constant/comman';
import { InboxOutlined } from '@ant-design/icons';

const Dragger = Upload.Dragger;

const StyledDragger = styled(Dragger)`
    & .ant-upload {
        margin-bottom: 15px;
    }

    & .ant-upload-drag {
        margin-bottom: 15px;
    }
    & .ant-upload-list-item-actions {
        display: none;
    }
`;

const props = {
    name: 'file',
    action: COURSE_H5P_UPLOAD_PATH,
    // action: "http://localhost:4040/upload/file",
    headers: {
        authorization: 'authorization-text'
    },
    accept: ".html"
};

var temp = [];
var isUploadClick = false;

// var supportedImageMimeTypes = ['image/jpeg', 'image/gif', 'image/pipeg', 'image/png', 'image/svg+xml', 'image/webp'];
// var supportedVideoMimeTypes = ['video/mpeg', 'video/mp4', 'video/ogg', 'video/webm', 'video/x-msvideo'];

export class AddH5p extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disable: true,
            jsonFile: [],
            disableUpload: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.verifyDoc = this.verifyDoc.bind(this);
    }

    componentDidMount() {
        if (this.props.refx) {
            this.props.refx(this);
        }
    }

    verifyDoc() {
        var file = [];

        isUploadClick = true;

        if (temp.length <= 0) {
            // message.error('Please select a valid image');
            return;
        }

        //get the media type based mime type from the selected media start
        //****commeneted the code as only video type file will get upload as intro media
        // let mediaType = "";

        // if (supportedImageMimeTypes.includes(temp[0].type)) {
        //     mediaType = "image";
        // } else if (supportedVideoMimeTypes.includes(temp[0].type)) {
        //     mediaType = "video";
        // }
        //get the media type based mime type from the selected media end

        const bannerData = {
            media_name: temp[0].response.file,
            type: 'image',
            media_type: 'banner'
        };

        this.props.onUpload(temp[0].response.file, bannerData);
    }

    handleChange(info) {
        console.log('!!!!!!!!!!!!!!!!!!!!info printed banner iamge ', info);

        var error = false;
        const maxSize = 5 * 1024 * 1024; // 5MB
        if (info.file.size > maxSize) {
            message.error("File size must be less than 5MB!");
            return;
        }
        if (info.file.status !== 'uploading') {
            console.log('status --', info.file.response.file, info.fileList);
            if (info.fileList.length < 1) {
                this.setState({
                    disable: true
                });
            } else {
                this.setState({
                    disable: false
                });
            }
        }

        if (info.file.status === 'done') {
            if (info.file.response.code === 'ERROR_IN_UPLOAD') {
                message.error(info.file.response.msg);
                error = true;
            } else {
                message.success(`${info.file.name} file uploaded successfully.`);
                temp = info.fileList;
                error = false;
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            error = true;
        }
        this.verifyDoc()
        // temp = info.fileList;
    }

    handleCancel = (e) => {
        this.hide();
    };

    show() {
        this.setState({
            visible: true
        });
    }

    hide() {
        this.setState(
            {
                visible: false
            },
            // () => (temp.length > 0 ? this.props.onUpload(temp) : this.props.onUpload(null))
            () => (isUploadClick ? this.props.onUpload(temp) : this.props.onUpload(null))
        );
    }

    render() {
        return (
            // <Modal
            //     width="600px"
            //     closable={this.state.closable}
            //     visible={this.state.visible}
            //     // onOk={this.handleOk}
            //     onCancel={this.handleCancel.bind(this)}
            //     footer={null}
            // >
                <div style={{ marginTop: '20px' }}>
                    <StyledDragger
                        //listType="picture-card"
                        accept=".png,.jpeg,.jpg"
                        className="clearfix"
                        {...props}
                        onChange={this.handleChange}
                        onRemove={(file) => console.log('Removed -- ', file)}
                    >
                        {/* <img src={imageUrl} alt="avatar" /> */}
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint" style={{ marginBottom: '10px' }}>
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>
                        {/* <Button>
            <Icon type="upload" /> Click to Upload
          </Button> */}
                    </StyledDragger>
                    {/* <Button style={{ marginTop: '50px' }} disabled={this.state.disable} onClick={this.verifyDoc}>
                        Upload
                    </Button> */}
                    {/* <Button disabled={this.state.disableUpload} onClick={this.handleSubmit}>
          Upload
        </Button>
        .{" "} */}
                </div>
            // </Modal>
        );
    }
}
