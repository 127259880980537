import {
  Modal,
  Button,
  Divider,
  Select,
  Input,
  Tabs,
  notification,
} from "antd";
import * as React from "react";
import { Layout, DatePicker, Upload } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";
import { addUser } from "../../Graphs/User/addUser";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { checkPermission } from "../AdminUser/CheckAdminPermission";
import { UploadOutlined } from "@ant-design/icons";
import { addBulkUser } from "../../Graphs/User/bulkUser";
import { uploadGroupCsv } from "../../Graphs/User/fileUpload";
import { message } from "antd";
import { getDepartments } from "../../Graphs/Department/GetDepartment";
import { AssignCourse } from "../../Graphs/User/AssignCourse";
// import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";
import { FetchCourse } from "../../Graphs/User/FetchCourse";
import { getAllUser } from "../../Graphs/User/listUser";

const { Content } = Layout;
const Option = Select.Option;

export class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      bulkUploadVisible: false,
      selectedType: "",
      isVerified: false,
      selectedDob: "",
      file: null,
      userList: [],
      image: null,
      departments: [],
    //   selectedDepartment: this.props.data.department_id
    // ? Array.isArray(this.props.data.department_id) 
    //     ? this.props.data.department_id.map(item => item.name) // If array, map through it
    //     : [this.props.data.department_id.name] // If single object, wrap it in an array
    // : [],

      selectedCourses: [{ courses: null, maxUsers: "" }],
      courses: [],
      assignCourseVisible: false,

      errors: {
        email: "",
      },
    };
  }

  input;
  allDepartments = [];

  fetchUserList = async() => {

    const {Token} = this.props;
    let userData = await getAllUser(Token, 0,100)
    console.log(userData)
  }

  validateForm = (values) => {
    // const { selectedDob } = this.state;

    const { LoginData } = this.props;
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(values.email).toLowerCase()
      )
    ) {
      errors.email = "Please enter a valid email";
    }

    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required";
    }

    if (LoginData.role === "corporate") {
      if (values.selectedDepartment.length === 0) {
        errors.selectedDepartment = "Department Name is required";
      }
    }

    console.log("Validating errors -- ", errors);

    return errors;
  };

  handleSubmit = async (values, action) => {
    const { selectedDepartment } = this.state;
    const { userList, Token } = this.props;

    values.department_id = values.selectedDepartment;
    delete values.selectedDepartment;
    // console.log(values.department);
    console.log("Final Payload:", values);

    notification.success({
      message: "Success",
      description: "User successfully added!",
    });

    await this.fetchUserList()

    this.props.onSubmit(values);
    this.setState({
      visible: false,
    });
  };

  async fetchCourses() {
    try {
      const { Token } = this.props;
      const response = await FetchCourse(Token);

      if (
        response &&
        response.data &&
        response.data.length > 0 &&
        response.data[0].courses &&
        response.data[0].courses.courseDetails
      ) {
        this.allCourses = response.data.map((item) => (
          <Option
            key={item.courses.courseDetails._id}
            value={item.courses.courseDetails._id}
          >
            {item.courses.courseDetails.name}
          </Option>
        ));

        this.setState({
          selectedCourses: response.data[0].courses.courseDetails._id,
        });
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  }

  // handleAssignSubmit = async () => {
  //   const { selectedCourses } = this.state;
  //   const { authToken, groupId } = this.props;

  //   if (selectedCourses.every((item) => item.course && item.maxUsers)) {
  //     try {
  //       const payload = {
  //         group_id: groupId,
  //         courses: selectedCourses.map((item) => ({
  //           course_id: item.course,
  //           max_user_count: item.maxUsers,
  //         })),
  //       };

  //       console.log("Submitting:", payload);

  //       const response = await AssignGroupCourse(authToken, payload);
  //       // console.log("Response:", response);

  //       if (response && response.status === 200) {
  //         notification.success({
  //           message: "Success",
  //           description: "Courses assigned successfully!",
  //         });
  //         this.setState({ visible: false });
  //       } else if (response) {
  //         notification.error({
  //           message: "Error",
  //           description: response.message || "Failed to assign courses.",
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error submitting courses:", error);
  //       notification.error({
  //         message: "Error",
  //         description: "Failed to assign courses. Please try again.",
  //       });
  //     }
  //   } else {
  //     alert("Please fill all fields.");
  //   }
  // };

  changeType = (value) => {
    this.setState({
      selectedType: value,
    });
  };

  // changeDob = (date, dateString) => {
  //   this.setState({
  //     selectedDob: dateString,
  //   });
  // };

  // handleDepartmentChange = async (value) => {
  //   this.setState({ selectedDepartment: value });

  //   const { authToken } = this.props;

  //   try {
  //     const departmentData = {
  //       name: value, // Assuming department data includes 'name'
  //     };

  //     const response = await updateDepartment(authToken, departmentData);
  //     if (response && response.status === 200) {
  //       message.success("Department updated successfully!");
  //     } else {
  //       message.error("Failed to update department");
  //     }
  //   } catch (error) {
  //     console.error("Update Department Error:", error);
  //     message.error("Failed to update department");
  //   }
  // };

  async componentDidMount() {
    const { Token } = this.props;

    this.fetchCourses();

    let DepartmentData = await getDepartments(Token);

    if (DepartmentData.data && DepartmentData.data.length >= 1) {
      DepartmentData.data.map((data, index) => {
        this.allDepartments.push(
          <Option value={data._id} key={data._id}>
            {data.name}
          </Option>
        );
      });
    }
  }

  handleAssignChange = (index, field, value) => {
    this.setState((prevState) => {
      const updatedCourses = [...prevState.selectedCourses];
      updatedCourses[index][field] = value;
      return { selectedCourses: updatedCourses };
    });
  };

  handleBulkUpload = async () => {
    const { file } = this.state;

    const { Token } = this.props;
    console.log("++++", Token);
    // console.log(this.state.auth.authToken);
    // console.log("loginUserData:", loginUserData);
    try {
      const formData = new FormData();
      formData.append("file", file);
      // console.log("Uploading CSV...");
      const csvResponse = await uploadGroupCsv(Token, formData);
      console.log("CSV Upload Response:", csvResponse.file);

      const fileName = csvResponse.file;
      console.log("File Name:", fileName);

      if (fileName) {
        console.log("Adding Bulk Users...");

        try {
          const userResponse = await addBulkUser(Token, file, fileName);
          console.log("User Response: ", userResponse);

          if (userResponse && userResponse.status === 200) {
            message.success("Bulk users added successfully!");
          } else {
            message.error(
              userResponse.data
                ? userResponse.data.message
                : "Failed to add bulk users."
            );
          }
        } catch (error) {
          console.error("Add Bulk Users Error:", error);
          console.error("Failed to add bulk users.");
        }
      } else {
        message.error("Failed to get file name from CSV response.");
      }

      this.setState({ bulkUploadVisible: false, file: null });
      // message("Files uploaded successfully!");
      this.setState({ bulkUploadVisible: false, file: null });
    } catch (error) {
      console.error("Upload error:", error);
      // message("Failed to upload files.");
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  handleAssignCourse = async () => {
    const { selectedCourses, selectedDepartment } = this.state;
    const { Token } = this.props;

    if (selectedCourses.length && selectedDepartment.length) {
      try {
        const payload = {
          course_id: selectedCourses,
          department_ids: selectedDepartment,
        };

        console.log("Payload:", payload);

        const response = await AssignCourse(Token, payload);
        console.log(response);

        if (response && response.status === 200) {
          notification.success({
            message: "Success",
            description: "Courses assigned successfully!",
          });
          this.setState({ assignCourseVisible: false });
        } else {
          notification.error({
            message: "Error",
            description: response.message || "Failed to assign courses.",
          });
        }
      } catch (error) {
        console.error("Error submitting courses:", error);
        notification.error({
          message: "Error",
          description: "Failed to assign courses. Please try again.",
        });
      }
    } else {
      alert("Please fill all fields.");
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  showBulkUploadModal = () => {
    this.setState({
      bulkUploadVisible: true,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
      selectedDepartment: "",
    });
  };

  showAssignModal = () => {
    this.setState({
      assignCourseVisible: true, // Opens the modal
      selectedCourses: [], // Initialize or reset selected courses
      selectedDepartment: [], // Initialize or reset selected department
    });

    // Fetch courses when the modal is opened
    this.fetchCourses();
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      bulkUploadVisible: false,
      selectedType: "",
      selectedDepartment: [],
      assignCourseVisible: false,
      selectedCourses: [{ course: null, maxUsers: "" }],
    });
  };

  render() {
    const { primaryColor, LoginData, visible } = this.props;
    const { selectedCourses, assignCourseVisible, courses } = this.state;
    console.log(LoginData.role);
    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        {/* BULK UPLOAD BUTTON */}
        <div
          onClick={this.showBulkUploadModal}
          style={{
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
            marginLeft: "111px",
            marginTop: "-35px",
            cursor: "pointer",
          }}
        >
          Bulk Upload
        </div>

        {/* Assign Button */}
        {this.props.LoginData.role === "corporate" && (
          <div
            type="primary"
            onClick={this.showAssignModal}
            style={{
              width: "100px",
              backgroundColor: primaryColor,
              color: "white",
              padding: "7px 0px",
              textAlign: "center",
              borderRadius: "50px",
              marginLeft: "225px",
              marginTop: "-35px",
              cursor: "pointer",
            }}
          >
            Assign Course
          </div>
        )}

        {/* Modal */}
        <Modal
          title="Assign Course"
          visible={this.state.assignCourseVisible}
          onCancel={() => this.setState({ assignCourseVisible: false })}
          onOk={() => this.handleAssignCourse()}
          maskClosable={false}
        >
          <div className="formik-field_wrap">
            {/* Course Dropdown */}
            <div className="formik-field-left">
              Courses
              <Select
                id="Select Courses"
                mode="single"
                placeholder="Select Courses"
                value={this.state.selectedCourses}
                onChange={(value) => this.setState({ selectedCourses: value })}
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
              >
                {this.allCourses}
              </Select>
            </div>

            {/* Department Dropdown */}
            <div className="formik-field-right">
              Department
              <Select
                id="department"
                mode="multiple"
                placeholder="Select Department"
                value={this.state.selectedDepartment}
                onChange={(value) =>
                  this.setState({ selectedDepartment: value })
                }
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
              >
                {this.allDepartments}
              </Select>
            </div>
          </div>
        </Modal>

        {/* BULK UPLOAD MODAL */}
        <Modal
          title="Bulk Upload"
          visible={this.state.bulkUploadVisible}
          onCancel={this.handleCancel}
          onOk={this.handleBulkUpload}
          maskClosable={false}
        >
          {/* <div style={{ marginBottom: "15px" }}>
            <label><strong>Select CSV File:</strong></label>
            <Upload beforeUpload={() => false} onChange={this.handleFileChange}>
              <Button icon={<UploadOutlined />}>Upload CSV</Button>
            </Upload>
          </div> */}

          <div style={{ marginBottom: "15px" }}>
            <label>
              <strong>Select CSV File:</strong>
            </label>
            <Upload
              beforeUpload={(file) => {
                this.setState({ file });
                return false; // Prevent default upload
              }}
              fileList={
                this.state.file
                  ? [{ uid: "-1", name: this.state.file.name }]
                  : []
              }
              onRemove={() => this.setState({ file: null })}
            >
              <Button icon={<UploadOutlined />}>Upload CSV</Button>
            </Upload>
          </div>
        </Modal>

        {/* // <div
        //   onClick={this.showModal}
        //   style={{
        //     width: "100px",
        //     backgroundColor: primaryColor,
        //     color: "white",
        //     padding: "7px 0px",
        //     textAlign: "center",
        //     borderRadius: "50px",
        //   }}
        // >
        //   ADD
        // </div> */}

        <Modal
          destroyOnClose={true}
          title="Add User"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          maskClosable={false}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                // name: "",
                // username: "",
                email: "",
                // department: "",
                // phone: "",
                // country: "",
                // city: "",
                // bio: "",
                // current_location: "",
                first_name: "",
                last_name: "",
                selectedDepartment: [],
                // address_line1: "",
                // address_line2: "",
                // is_verified: false,
                // job_post: "",
                // password: "",
                // confirm_password: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        First Name
                        <Input
                          id="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Last Name
                        <Input
                          id="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.last_name && touched.last_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.last_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      {/* Email Field (Always Visible) */}
                      <div className="formik-field-left">
                        Email
                        <Input
                          id="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                      {/* Department Field (Only for Corporate User) */}
                      {LoginData.role === "corporate" && (
                        <div className="formik-field-right">
                          Department
                          <Select
                            id="department"
                            mode="multiple"
                            placeholder="Select Department"
                            value={values.selectedDepartment} // Use Formik values
                            onChange={(value) => setFieldValue("selectedDepartment", value)}
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                          >
                            {this.allDepartments}
                          </Select>
                          {errors.selectedDepartment &&
                          touched.selectedDepartment ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.selectedDepartment}
                            </p>
                          ) : null}
                        </div>
                      )}
                    </div>

                    {/* Show the following fields only if the user is an admin
                    {(LoginData.role === "SUPERADMIN" ||
                      LoginData.role === "SUBADMIN") && (
                      <>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Password
                            <Input
                              id="password"
                              type="password"
                              placeholder="Password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.password && touched.password ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.password}
                              </p>
                            ) : null}
                          </div>

                          <div className="formik-field-right">
                            Confirm Password
                            <Input
                              id="confirm_password"
                              type="password"
                              placeholder="Confirm Password"
                              value={values.confirm_password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.confirm_password &&
                            touched.confirm_password ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.confirm_password}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Bio
                            <Input
                              type="textbox"
                              id="bio"
                              placeholder="Bio"
                              value={values.bio}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.bio && touched.bio ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.bio}
                              </p>
                            ) : null}
                          </div>

                          <div className="formik-field-right">
                            Current Location
                            <Input
                              id="current_location"
                              placeholder="Current Location"
                              value={values.current_location}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.current_location &&
                            touched.current_location ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.current_location}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )} */}
                    <Button
                      type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(AddUser));
