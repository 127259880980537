import { callApiToServer } from "../callApi";

export async function addBulkUser(Token, file, fileName) {
  console.log("Calling addBulkUser...");

  try {
    let formData = new FormData();// Attach the file
    formData.append("file", fileName); // Attach the file name

    let header = {
      Authorization: `Bearer ${Token}`,
    };

    console.log("Auth Token:", Token);
    console.log("FormData:", formData);
    
    let endUrl = "v1/auth/admin/add-bulk-user";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(formData, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    console.error("Error in addBulkUser:", err);
    throw err;
  }
}
