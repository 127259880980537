import * as React from "react";
import { Upload, message, Button, Modal } from "antd";
import styled from "styled-components";
import {
  COURSE_TOPIC_QUESTION_AUDIO_UPLOAD_PATH,
  COURSE_TOPIC_QUESTION_VIDEO_UPLOAD_PATH,
} from "../../constant/comman";
import { InboxOutlined } from "@ant-design/icons";

const Dragger = Upload.Dragger;

const StyledDragger = styled(Dragger)`
  & .ant-upload {
    margin-bottom: 15px;
  }

  & .ant-upload-drag {
    margin-bottom: 15px;
  }
  & .ant-upload-list-item-actions {
    display: none;
  }
`;

const videoProps = {
  name: "video",
  action: COURSE_TOPIC_QUESTION_VIDEO_UPLOAD_PATH,
  headers: {
    authorization: "authorization-text",
  },
};

const audioProps = {
  name: "audio",
  action: COURSE_TOPIC_QUESTION_AUDIO_UPLOAD_PATH,
  headers: {
    authorization: "authorization-text",
  },
};

var temp = [];

export class AddQuestionMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: true,
      jsonFile: [],
      disableUpload: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.verifyDoc = this.verifyDoc.bind(this);
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  verifyDoc() {
    var file = [];

    if (temp.length <= 0) {
      message.error("Please select a valid image");
      return;
    }

    // temp.forEach((t,i)=>{
    //   file.push(temp[i].response.file)
    // })

    this.props.onUpload(temp[0].response.file);
  }

  handleChange(info) {
    console.log("!!!!!!!!!!!!!!!!!!!!info printed ", info);

    var error = false;
    if (info.file.status !== "uploading") {
      if (info.fileList.length < 1) {
        this.setState({
          disable: true,
        });
      } else {
        this.setState({
          disable: false,
        });
      }
    }

    if (info.file.status === "done") {
      if (info.file.response.code === "ERROR_IN_UPLOAD") {
        message.error(info.file.response.msg);
        error = true;
      } else {
        message.success(`${info.file.name} file uploaded successfully.`);
        temp = info.fileList;
        error = false;
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      error = true;
    }
    // temp = info.fileList;
  }

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState(
      {
        visible: false,
      },
      () => {} //(temp.length > 0 ? this.props.onUpload(temp) : this.props.onUpload(null))
    );
  }

  render() {
    const { selectedMediaType } = this.props;

    return (
      <Modal
        width="600px"
        closable={this.state.closable}
        visible={this.state.visible}
        // onOk={this.handleOk}
        onCancel={this.handleCancel.bind(this)}
        footer={null}
        maskClosable={false}
      >
        <div style={{ marginTop: "20px" }}>
          {selectedMediaType === "video" ? (
            <StyledDragger
              //listType="picture-card"
              accept={
                ".mpg,.mp2,.mpeg,.mpe,.mpv,.mp4,.m4p,.m4v,.mkv,.flv,.vob,.svi,.ogg,.ogv,.mov"
              }
              className="clearfix"
              {...videoProps}
              onChange={this.handleChange}
              onRemove={(file) => console.log("Removed -- ", file)}
            >
              {/* <img src={imageUrl} alt="avatar" /> */}
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint" style={{ marginBottom: "10px" }}>
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
              {/* <Button>
                        <Icon type="upload" /> Click to Upload
                    </Button> */}
            </StyledDragger>
          ) : (
            <StyledDragger
              //listType="picture-card"
              accept={".mp3,.acc,.ogg,.m4a"}
              className="clearfix"
              {...audioProps}
              onChange={this.handleChange}
              onRemove={(file) => console.log("Removed -- ", file)}
            >
              {/* <img src={imageUrl} alt="avatar" /> */}
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint" style={{ marginBottom: "10px" }}>
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
              {/* <Button>
                        <Icon type="upload" /> Click to Upload
                    </Button> */}
            </StyledDragger>
          )}

          <Button
            style={{ marginTop: "50px" }}
            disabled={this.state.disable}
            onClick={this.verifyDoc}
          >
            Upload
          </Button>
          {/* <Button disabled={this.state.disableUpload} onClick={this.handleSubmit}>
                Upload
                </Button>
                .{" "} */}
        </div>
      </Modal>
    );
  }
}
