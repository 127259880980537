import { callGetApiToServer } from "../callApi";

export async function listCourse(authtoken, skipNumber, limitNumber, id) {
  console.log(authtoken, limitNumber, id);
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    // Dynamically use the ID from params
    let endUrl = `v1/auth/admin/list-group-course?group_id=${id}&skip=${skipNumber}&limit=${limitNumber}`;

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
