import { callApiToServer } from "../callApi";

export async function getDepartments(Token, skip, limit) {
    try {
      let headers = {
        Authorization: `Bearer ${Token}`,
      };
  
      let endUrl = `/v1/auth/admin/department/0/20`;
  
      const response = await callApiToServer(null, headers, "GET", endUrl);
  
      console.log("Get Departments Response:", response);
      return response;
    } catch (err) {
      console.error("Get Departments Error:", err);
      throw err;
    }
  }
  