import {
  Modal,
  Button,
  Divider,
  Layout,
  Input,
  Select,
  Checkbox,
  Radio,
  InputNumber,
} from "antd";
import * as React from "react";
import { Formik } from "formik";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";
import { listAllDropDownCourseSession } from "../../Graphs/CourseSession/listAllDropDownCourseSession";
import { QuestionComponent } from "./QuestionComponent";
import EditorField from "../common/EditorField";
import { AddH5p } from "../Course/AddH5p";

const { Content } = Layout;
const { TextArea } = Input;
const Option = Select.Option;

var amenities = [];

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      viewMediaModal: false,
      isLoading: false,
      visible: false,
      isSample: false,
      isShouldComponentUpdate: false,
      inputFields: [],
      allQuestions: [],
      selectedAnswerType: [],
      selectedQuestionType: [],
      media: [],
      allCoursesOptions: [],
      allSessionsOptions: [],
      selectedType: "",
      selectedParentType: "course",
      selectedCourseType: "",
      selectedModuleType: "",
      selectedSessionType: "",
      longDescription: null,
      errors: {},
    };
  }

  input;
  selectedColorType = "";
  selctedColorKey = "";
  src = [];
  mediaSrc = [];
  allCourses = [];
  allModules = [];
  allSessions = [];
  viewMediaModalRef;

  allTypes = [
    <Option value={"study"} key="study">
      Study
    </Option>,
    <Option value={"quiz"} key="quiz">
      Quiz
    </Option>,
  ];

  allParentTypes = [
    <Option value={"course"} key="course">
      Course
    </Option>,
    // <Option value={"module"} key="module">Module</Option>,
    <Option value={"session"} key="session">
      Session
    </Option>,
  ];

  topicContentTypes = [
    <Option value={"document"} key="document">
      Document
    </Option>,
    <Option value={"h5p"} key="h5p">
      H5p
    </Option>,
  ];

  // allQuesTypes = [
  //   <Option value={"Text"} key="Text">
  //     Text
  //   </Option>,
  //   <Option value={"Audio"} key="Audio">
  //     Audio
  //   </Option>,
  //   <Option value={"Video"} key="Video">
  //     Video
  //   </Option>,
  //   // <Option value={"Code Snipet"} key="Code Snipet">Code Snipet</Option>,
  // ];

  // allAnsTypes = [
  //   <Option value={"multiChoies"} key="multiChoies">
  //     Multiple Choies
  //   </Option>,
  //   <Option value={"singleChoies"} key="singleChoies">
  //     Single Choies
  //   </Option>,
  //   <Option value={"descriptive"} key="descriptive">
  //     Descriptive
  //   </Option>,
  //   // <Option value={"practical"} key="practical">Pratical</Option>,
  // ];

  async componentDidMount() {
    const { authToken, data, course_id } = this.props;

    let courseData = await listAllDropDownCourse(authToken, 0, 1000000);

    if (courseData.data && courseData.data.length >= 1) {
      courseData.data.map((data, index) => {
        this.allCourses.push(
          <Option value={data._id} key={index}>
            {data.name}
          </Option>
        );
      });
    }

    this.setState({
      allCoursesOptions: this.allCourses,
    });

    // let moduleData = await listAllDropDownModule(authToken);

    // if (moduleData.data && moduleData.data.length >= 1) {
    //   moduleData.data.map((data, index) => {
    //     this.allModules.push(
    //       <Option value={data.courseData[0]._id + "-" + data._id}>
    //         {data.title}
    //       </Option>
    //     );
    //   });
    // }

    // let sessionData = await listAllDropDownCourseSession(authToken);

    // if (sessionData.data && sessionData.data.length >= 1) {
    //   sessionData.data.map((data, index) => {
    //     this.allSessions.push(
    //       <Option value={data.courseData[0]._id + "-" + data._id}>
    //         {data.name}
    //       </Option>
    //     );
    //   });
    // }

    //get course session data if course id available get data based on that else all data retrieve start
    this.getSessionData(data.course_id ? data.course_id : course_id);
    //get course session data if course id available get data based on that else all data retrieve end

    this.setState({
      isLoading: false,
      // short_description: data.short_description,
      longDescription: data.long_description,
      selectedType: data.topic_type,
      selectedContentType : data.topic_content_type,
      selectedCourseType: data.course_id,
      selectedModuleType: data.module_id,
      selectedSessionType:
        data.parent_type === "session"
          ? data.course_id + "-" + data.course_session_id
          : "",
      selectedParentType: data.parent_type,
      allQuestions: data.topicQuestionData,
      media: data.courseTopicMediaData,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isShouldComponentUpdate } = nextState;
    console.log("!!!!!!!!!!!!!update 1", nextState);
    if (!isShouldComponentUpdate) {
      this.selectedColorType = "";
      this.mediaSrc = [];
    }

    return true;
  }

  validateForm(values) {
    // const {selectedType} = this.state;
    let isValidation = false;
    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.name) {
      errors.name = "Name is required";
    }

    // if ((!values.passing_marks || !(values.passing_marks >= 0 && values.passing_marks <= 100))) {
    //   isValidation = true;
    //   errors.passing_marks = "Passing marks must be between 0 and 100.";
    // }

    // if (!values.sort_number) {
    //   errors.sort_number = "Sort Number is required"
    // }

    // if (!values.time) {
    //   errors.time = "Time is required";
    // }

    // if (!values.short_description) {
    //   errors.short_description = "Short Description is required";
    // }

    console.log("Validating errors -- ", errors);

    return errors;
  }

  handleSubmit = async (values, action) => {
    const {
      errors,
      allQuestions,
      selectedSessionType,
      selectedCourseType,
      selectedType,
      selectedParentType,
      selectedModuleType,
      longDescription,
      media,
      isSample,
    } = this.state;
    const { data } = this.props;

    let dummyErrors = { ...errors };

    let isValidation = false;

    dummyErrors.selectedParentType = "";
    dummyErrors.selectedCourseType = "";
    dummyErrors.selectedModuleType = "";
    dummyErrors.selectedSessionType = "";
    dummyErrors.media = "";
    dummyErrors.allQuestions = [];
    dummyErrors.passing_marks = "";

    if (!selectedParentType) {
      isValidation = true;
      dummyErrors.selectedParentType = "Please select parent type";
    } else {
      if (selectedParentType === "course" && !selectedCourseType) {
        isValidation = true;
        dummyErrors.selectedCourseType = "Please select course";
      }

      if (selectedParentType === "session" && !selectedSessionType) {
        isValidation = true;
        dummyErrors.selectedSessionType = "Please select session";
      }

      // if (selectedParentType == "module" && !selectedModuleType) {
      //   isValidation = true;
      //   dummyErrors.selectedModuleType = "Please select module";
      // }
    }

    if (!selectedType) {
      isValidation = true;
      dummyErrors.selectedType = "Please select type";
    }

    if (
      selectedType === "quiz" &&
      (!values.passing_marks ||
        !(values.passing_marks >= 0 && values.passing_marks <= 100))
    ) {
      isValidation = true;
      dummyErrors.passing_marks = "Passing marks must be between 0 and 100.";
    }

    if (selectedType === "study" && !values.media && !longDescription) {
      isValidation = true;
      dummyErrors.media = "Please enter media URL or description";
    }

    if (selectedType === "quiz") {
      if (!allQuestions || allQuestions.length <= 0) {
        isValidation = true;
        dummyErrors.allQuestions = "Please add at least one question";
      } else if (allQuestions.length >= 1) {
        allQuestions.map((item, index) => {
          dummyErrors.allQuestions[index] = {
            title: "",
            ques_type: "",
            ans_type: "",
            options: "",
            media_name: "",
            // short_description: "",
            // long_description: "",
            // marks: "",
          };

          if (!item.title) {
            isValidation = true;
            dummyErrors.allQuestions[index]["title"] = "Please enter title";
          }

          if (!item.ques_type) {
            isValidation = true;
            dummyErrors.allQuestions[index]["ques_type"] =
              "Please select question type";
          }

          if (!item.ans_type) {
            isValidation = true;
            dummyErrors.allQuestions[index]["ans_type"] =
              "Please select answer type";
          }

          // if (!item.short_description) {
          //   isValidation = true;
          //   dummyErrors.allQuestions[index]["short_description"] =
          //     "Please enter description";
          // }

          // if (!item.long_description) {
          //   isValidation = true;
          //   dummyErrors.allQuestions[index]["long_description"] =
          //     "Please enter long description";
          // }

          // if (!item.marks) {
          //   isValidation = true;
          //   dummyErrors.allQuestions[index]["marks"] = "Please enter marks";
          // }

          if (
            (item.ans_type === "multiChoies" ||
              item.ans_type === "singleChoies") &&
            (!item.options || item.options <= 0)
          ) {
            isValidation = true;
            dummyErrors.allQuestions[index]["options"] =
              "Please provide answer options";
          }

          if (
            item.ques_type === "Audio" &&
            (!item.media_name || item.media_name <= 0)
          ) {
            isValidation = true;
            dummyErrors.allQuestions[index]["media_name"] =
              "Please select question media";
          }

          if (item.ques_type === "Video" && !item.media_name) {
            isValidation = true;
            dummyErrors.allQuestions[index]["media_name"] =
              "Please enter question media URL";
          }
        });
      }
    }

    console.log("dummy errors: ", dummyErrors.passing_marks);
    this.setState({
      errors: dummyErrors,
    });

    if (isValidation) {
      return;
    }

    if (selectedType === "study" && longDescription) {
      values.long_description = longDescription;
    }
    values.is_module = false;
    values.is_sample = isSample;
    values.topic_id = data._id;
    values.courses_id = selectedCourseType;
    values.session_id = selectedSessionType;
    values.type = selectedType;
    values.questions = allQuestions;
    // values.media = [...media];

    if (selectedParentType === "session") {
      let tempValue = [];
      if (selectedSessionType) {
        tempValue = selectedSessionType.split("-");
        values.course_id = tempValue[0];
        values.session_id = tempValue[1];
        values.module_id = null;
      }
    }

    console.log("for sumitssion values", values);

    this.props.onSubmit(values);

    this.setState({
      visible: false,
      isShouldComponentUpdate: false,
      viewMediaModal: false,
      isLoading: false,
      isSample: false,
      inputFields: [],
      allQuestions: [],
      selectedAnswerType: [],
      selectedQuestionType: [],
      allCoursesOptions: [],
      allSessionsOptions: [],
      media: "",
      selectedType: "",
      selectedParentType: "course",
      selectedCourseType: "",
      selectedModuleType: "",
      selectedSessionType: "",
      longDescription: null,
      errors: {},
    });
  };

  changeType = (value) => {
    this.setState({
      selectedType: value,
      allQuestions: [],
      media: [],
    });
  };

  changeParentType = (value) => {
    const { course_id } = this.props;

    this.setState({
      selectedCourseType: course_id ? course_id : "",
      selectedModuleType: "",
      selectedSessionType: "",
      selectedParentType: value,
    });
  };

  changeCourseType = (value) => {
    this.setState({
      selectedCourseType: value,
      selectedModuleType: "",
      selecetdSessionType: "",
    });
  };

  changeModuleType = (value) => {
    this.setState({
      selectedModuleType: value,
      selecetdSessionType: "",
    });
  };

  changeSessionType = (value) => {
    this.setState({
      selectedSessionType: value,
      selectedModuleType: "",
    });
  };

  // changeAnswerType = (value, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["ans_type"] = value;

  //   this.resetRadioButtonValue(dummyQuesions, index);

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // changeQuestionType = (value, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["ques_type"] = value;
  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  getSessionData = async (courseId) => {
    const { authToken } = this.props;

    let sessionData = await listAllDropDownCourseSession(
      authToken,
      0,
      100000,
      courseId
    );

    if (sessionData.data && sessionData.data.length >= 1) {
      sessionData.data.map((data, index) => {
        if (data.courseData && data.courseData[0]) {
          this.allSessions.push(
            <Option value={data.courseData[0]._id + "-" + data._id} key={index}>
              {data.name}
            </Option>
          );
        }
      });
    }

    this.setState({
      allSessionsOptions: this.allSessions,
    });
  };

  onChangeIsFeatured() {
    this.setState({
      isSample: !this.state.isSample,
    });
  }

  onDescriptionChange = (description) => {
    this.setState({ longDescription: description });
  };

  showModal = () => {
    this.setState({
      visible: true,
      isShouldComponentUpdate: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      media: [],
      allQuestions: [],
      selectedType: "",
      selectedParentType: "course",
      selectedCourseType: "",
      selectedModuleType: "",
      selectedSessionType: "",
      errors: {},
      isShouldComponentUpdate: false,
    });
  };

  changeTopicContent = (value) => {
    this.setState({
      selectedContentType: value,
      allQuestions: [],
      media: [],
    });
  };

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  uploadMediaFiles = (mediaData) => {
    console.log("!!!!!!!!!!!!!!after upload 1111111 ", mediaData);
    if (mediaData && mediaData.length > 0) {
      this.setState({
        // media: [...this.state.media, ...mediaData],
        media: mediaData,
        viewMediaModal: false,
      });
    } else {
      this.setState({
        viewMediaModal: false,
      });
    }
  };

  showMediaModal(params) {
    this.setState(
      {
        viewMediaModal: true,
      },
      () => {
        this.viewMediaModalRef.show();
      }
    );
  }

  // addQuestions = () => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions.push({
  //     question_title: "",
  //     ques_type: "",
  //     ans_type: "",
  //     options: [],
  //     media_name: "",
  //     short_description: "",
  //     long_description: "",
  //     marks: 0,
  //     view_question_media_modal: false,
  //     view_answer_image_modal: false,
  //     is_madatory: true,
  //   });

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // addOptions = (index) => {
  //   console.log("test log ", index);
  //   const { allQuestions } = this.state;

  //   let dummyOptions = allQuestions[index]["options"];
  //   let dummyQuesions = allQuestions;

  //   dummyOptions.push({
  //     title: "",
  //     is_correct: false,
  //     is_madatory: true,
  //   });

  //   dummyQuesions[index]["options"] = dummyOptions;

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // removeQuestionItem = (index) => {
  //   console.log("!!!!!removeQuestionItem", index);
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions.splice(index, 1);

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // removeQuestionOptionItem = (index, optIndex) => {
  //   console.log("!!!!!removeQuestionOptionItem", optIndex);
  //   const { allQuestions } = this.state;

  //   let dummyOptions = allQuestions[index]["options"];
  //   let dummyQuesions = allQuestions;

  //   dummyOptions.splice(optIndex, 1);

  //   dummyQuesions[index]["options"] = dummyOptions;

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // uploadQuestionMedia = (name, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["media_name"] = name;
  //   dummyQuesions[index]["view_question_media_modal"] = false;
  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // uploadAnswerImage = (name, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["answer_audio_url"] = name;
  //   dummyQuesions[index]["view_answer_image_modal"] = false;
  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // viewQuestionMediaModalRef;
  // viewAnswerImageModalRef;

  // showQuestionMediaUpload(index) {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["view_question_media_modal"] = true;
  //   this.setState(
  //     {
  //       allQuestions: dummyQuesions,
  //     },
  //     () => {
  //       this.viewQuestionMediaModalRef.show();
  //     }
  //   );
  // }

  // showAnswerImageUpload(index) {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["view_answer_image_modal"] = true;
  //   this.setState(
  //     {
  //       allQuestions: dummyQuesions,
  //     },
  //     () => {
  //       this.viewAnswerImageModalRef.show();
  //     }
  //   );
  // }

  // onQuestionTextChange = (e, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["question_title"] = e.target && e.target.value;
  //   this.setState({
  //     allQuestions: dummyQuesions
  //   })
  // }

  // onQuestionValueChange = (e, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index][e.target.name] = e.target && e.target.value;
  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // onOptionValueChange = (e, index, optIndex) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;

  //   dummyQuesions[index]["options"][optIndex][e.target.name] =
  //     e.target && e.target.value;

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // onOptionIsCorrectChange = (e, index, optIndex) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;

  //   dummyQuesions[index]["options"][optIndex][e.target.name] =
  //     !dummyQuesions[index]["options"][optIndex][e.target.name];

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // onRadioOptionIsCorrectChange = (e, index, optIndex) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;

  //   this.resetRadioButtonValue(dummyQuesions, index);

  //   dummyQuesions[index]["options"][optIndex]["is_correct"] = true;
  //   dummyQuesions[index]["options"]["is_correct_radio"] = e.target.value;

  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  // onMarksChange = (e, index) => {
  //   const { allQuestions } = this.state;
  //   let dummyQuesions = allQuestions;
  //   dummyQuesions[index]["marks"] = e.target && e.target.value;
  //   this.setState({
  //     allQuestions: dummyQuesions,
  //   });
  // };

  updateAllQuestionStateAction = (allQuestions) => {
    this.setState({
      allQuestions: allQuestions,
    });
  };

  render() {
    const { primaryColor, course_id, data } = this.props;
    const {
      allCoursesOptions,
      allSessionsOptions,
      allQuestions,
      selectedParentType,
      selectedCourseType,
      selectedSessionType,
      selectedType,
      selectedModuleType,
      longDescription,
      selectedContentType,
      media,
      isSample,
    } = this.state;

    return (
      <div>
        <Formik
          initialValues={{
            name: data.name,
            media: data.media,
            // sort_number: data.sort_number,
            // time: data.time,
            // short_description: data.short_description,
            selectedContentType: data.topic_content_type ? data.topic_content_type : "",
            long_description: data.long_description,
            passing_marks: data.passing_marks ? data.passing_marks : "",
          }}
          enableReinitialize
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div>
                <div className="formik-field_wrap">
                  <div className="formik-field">
                    Name
                    <Input
                      id="name"
                      placeholder="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.name}
                      </p>
                    ) : null}
                  </div>

                  {/* <div className="formik-field-right">
                    Sort Number
                    <Input
                      id="sort_number"
                      placeholder="Sort Number"
                      value={values.sort_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div> */}

                  {/* <div className="formik-field-right">
                    Total Time
                    <Input
                      type="number"
                      id="time"
                      placeholder="Total Time"
                      value={parseInt(values.time)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.time && touched.time ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.time}
                      </p>
                    ) : null}
                  </div> */}
                </div>

                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Parent Type
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) => this.changeParentType(value)}
                      placeholder="Select Type"
                      showSearch
                      value={selectedParentType}
                      // defaultValue="subAdmin"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.allParentTypes}
                    </Select>
                    {this.state.errors.selectedParentType ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {this.state.errors.selectedParentType}
                      </p>
                    ) : null}
                  </div>

                  {selectedParentType === "course" ? (
                    <div className="formik-field-right">
                      Course
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        onChange={(value) => this.changeCourseType(value)}
                        placeholder="Select Course"
                        showSearch
                        disabled={course_id ? true : false}
                        value={selectedCourseType}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allCoursesOptions}
                      </Select>
                      {this.state.errors.selectedCourseType ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.selectedCourseType}
                        </p>
                      ) : null}
                    </div>
                  ) : (
                    <div className="formik-field-right">
                      Session
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        onChange={(value) => this.changeSessionType(value)}
                        placeholder="Select Session"
                        showSearch
                        value={selectedSessionType}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allSessionsOptions}
                      </Select>
                      {this.state.errors.selectedSessionType ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.selectedSessionType}
                        </p>
                      ) : null}
                    </div>
                  )}
                </div>

                {/* <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Type
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) => this.changeParentType(value)}
                      placeholder="Select Type"
                      showSearch
                      value={selectedParentType}
                      // defaultValue="subAdmin"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.allParentTypes}
                    </Select>
                    {this.state.errors.selectedParentType ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {this.state.errors.selectedParentType}
                      </p>
                    ) : null}
                  </div>

                  {
                    selectedParentType == "course" ?
                      <div className="formik-field-right">
                        Course
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          onChange={(value) => this.changeCourseType(value)}
                          placeholder="Select Course"
                          showSearch
                          disabled={course_id ? true : false}
                          value={selectedCourseType}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.allCourses}
                        </Select>
                        {this.state.errors.selectedCourseType ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.selectedCourseType}
                          </p>
                        ) : null}
                      </div>

                      : selectedParentType == "module" ?

                        <div className="formik-field-right">
                          Module
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={(value) => this.changeModuleType(value)}
                            placeholder="Select Module"
                            showSearch
                            value={selectedModuleType}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.allModules}
                          </Select>
                          {this.state.errors.selectedModuleType ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.selectedModuleType}
                            </p>
                          ) : null}
                        </div>

                        :

                        <div className="formik-field-right">
                          Session
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={(value) => this.changeSessionType(value)}
                            placeholder="Select Session"
                            showSearch
                            value={selectedSessionType}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.allSessions}
                          </Select>
                          {this.state.errors.selectedSessionType ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.selectedSessionType}
                            </p>
                          ) : null}
                        </div>
                  }
                </div> */}

                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Topic Type
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      onChange={(value) => this.changeType(value)}
                      placeholder="Select Type"
                      showSearch
                      value={selectedType}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.allTypes}
                    </Select>
                    {this.state.errors.selectedType ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {this.state.errors.selectedType}
                      </p>
                    ) : null}
                  </div>

                  {this.state.selectedType === "study" && (
                    <div className="formik-field-right">
                      <Checkbox
                        onChange={(value) => this.onChangeIsFeatured()}
                        checked={isSample}
                      >
                        is Sample?
                      </Checkbox>
                      {this.state.errors.isSample ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.isSample}
                        </p>
                      ) : null}
                    </div>
                  )}

                  {this.state.selectedType === "quiz" && (
                    <div className="formik-field-right">
                      Passing Marks
                      <InputNumber
                        id="passing_marks"
                        type="number"
                        placeholder="Passing Marks"
                        value={values.passing_marks}
                        onChange={(value) => {
                          if (!isNaN(value)) {
                            console.log("passing_marks", { value });
                            setFieldValue("passing_marks", value);
                          }
                        }}
                        onBlur={handleBlur}
                        style={{ width: "100%" }}
                      />
                      {this.state.errors.passing_marks ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.passing_marks}
                        </p>
                      ) : null}
                    </div>
                  )}

                  {/* <div className="formik-field-right">
                    Total Time
                    <Input
                      id="time"
                      placeholder="Total Time"
                      value={values.time}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.time && touched.time ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.time}
                      </p>
                    ) : null}
                  </div> */}
                </div>

                {/* <div className="formik-field_wrap">
                  <div className="formik-field">
                    Short Description
                    <TextArea
                      row={4}
                      id="short_description"
                      placeholder="Short Description"
                      value={values.short_description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.short_description && touched.short_description ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.short_description}
                      </p>
                    ) : null}
                  </div>
                </div> */}

                {this.state.selectedType === "study" && (
                  <div
                    className="formik-field-right"
                    style={{ marginTop: "1rem" }}
                  >
                    Topic Content
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      value={values.selectedContentType}
                      onChange={(value) => this.changeTopicContent(value)}
                      disabled={true}
                      placeholder="Select Type"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.topicContentTypes}
                    </Select>
                  </div>
                )}

                {this.state.selectedType === "study" &&
                  this.state.selectedContentType === "document" && (
                    <>
                      <div className="formik-field_wrap">
                        <div className="formik-field">
                          Description
                          <EditorField
                            id="about-course-editor"
                            value={longDescription}
                            onChange={this.onDescriptionChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field">
                          Topic Media
                          <Input
                            id="media"
                            placeholder="Topic Media"
                            name="media"
                            value={values.media}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {this.state.errors.media ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.media}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}

                {this.state.selectedType === "study" &&
                  this.state.selectedContentType === "h5p" && (
                    <>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        {/* <div style={{ float: "left", width: "10%" }}>
                        <PictureOutlined
                          style={{ width: "100%", fontSize: "30px" }}
                        />
                      </div> */}
                        {/* <Button
                        style={{ width: "90%" }}
                        onClick={this.showPrimaryModal.bind(this)}
                      >
                        Choose Course Primary Media
                      </Button> */}

                        {this.state.errors.primary ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {this.state.errors.primary}
                          </p>
                        ) : null}

                        {/* {this.state.viewPrimaryModal ? ( */}
                        <AddH5p
                          refx={(e) => (this.viewPrimaryModalRef = e)}
                          onUpload={this.uploadPrimary}
                        />
                        {/* ) : null} */}
                      </div>
                    </>
                  )}

                {this.state.selectedType === "quiz" && (
                  <>
                    <QuestionComponent
                      allQuestionsData={allQuestions}
                      errors={this.state.errors}
                      updateAllQuestionStateAction={
                        this.updateAllQuestionStateAction
                      }
                    />

                    <Divider></Divider>
                  </>
                )}

                {/* {this.state.selectedType === "quiz" && (
                  <>
                    <div style={{ marginTop: "15px" }}>
                      <Button
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={() => this.addQuestions()}
                      >
                        Add Question
                      </Button>
                    </div>

                    <div style={{ marginTop: "15px" }}>
                      {allQuestions.map((data, index) => {
                        return (
                          <div
                            style={{
                              marginBottom: "15px",
                              display: "inline-block",
                              width: "100%",
                              paddingBottom: "15px",
                              borderBottom: "4px dashed gray",
                            }}
                          >
                            <div
                              style={{
                                marginTop: "5px",
                                marginBottom: "5px",
                                width: "100%",
                                display: "inline-block",
                              }}
                            >
                              <span
                                style={{ fontWeight: "bold", float: "left" }}
                              >
                                {`Question ${index + 1}`}
                              </span>
                              <span
                                style={{ fontWeight: "bold", float: "right" }}
                              >
                                <Button
                                  shape="circle"
                                  icon={<DeleteFilled />}
                                  onClick={() => {
                                    this.removeQuestionItem(index);
                                  }}
                                />
                              </span>
                            </div>

                            <div className="formik-field_wrap custom30Width">
                              <div className="formik-field_wrap">
                                <div
                                  className="formik-field-left"
                                  style={{ width: "30%" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    Question Title
                                  </span>
                                  <Input
                                    id="question_title"
                                    placeholder="Question Title"
                                    name="title"
                                    value={allQuestions[index]["title"]}
                                    onChange={(e) =>
                                      this.onQuestionValueChange(e, index)
                                    }
                                    onBlur={handleBlur}
                                  />
                                  {this.state.errors.allQuestions &&
                                  this.state.errors.allQuestions[index] &&
                                  this.state.errors.allQuestions[index][
                                    "title"
                                  ] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        margin: "0",
                                      }}
                                    >
                                      {
                                        this.state.errors.allQuestions[index][
                                          "title"
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>

                                <div
                                  className="formik-field-right"
                                  style={{ width: "30%", marginTop: "5px" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    Question Type
                                  </span>
                                  <Select
                                    allowClear
                                    style={{ width: "100%" }}
                                    onChange={(value) =>
                                      this.changeQuestionType(value, index)
                                    }
                                    placeholder="Select Question Type"
                                    showSearch
                                    value={allQuestions[index]["ques_type"]}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.allQuesTypes}
                                  </Select>
                                  {this.state.errors.allQuestions &&
                                  this.state.errors.allQuestions[index] &&
                                  this.state.errors.allQuestions[index][
                                    "ques_type"
                                  ] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        margin: "0",
                                      }}
                                    >
                                      {
                                        this.state.errors.allQuestions[index][
                                          "ques_type"
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="formik-field_wrap">
                                <div
                                  className="formik-field-left"
                                  style={{ width: "30%", marginTop: "5px" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    Answer Type
                                  </span>
                                  <Select
                                    allowClear
                                    style={{ width: "100%" }}
                                    onChange={(value) =>
                                      this.changeAnswerType(value, index)
                                    }
                                    placeholder="Select Answer Type"
                                    value={allQuestions[index]["ans_type"]}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.allAnsTypes}
                                  </Select>
                                  {this.state.errors.allQuestions &&
                                  this.state.errors.allQuestions[index] &&
                                  this.state.errors.allQuestions[index][
                                    "ans_type"
                                  ] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        margin: "0",
                                      }}
                                    >
                                      {
                                        this.state.errors.allQuestions[index][
                                          "ans_type"
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="formik-field_wrap">
                                <div className="formik-field">
                                  <span style={{ fontWeight: "bold" }}>
                                    Short Description
                                  </span>
                                  <TextArea
                                    row={4}
                                    id="short_description"
                                    placeholder="Short Description"
                                    name="short_description"
                                    value={
                                      allQuestions[index]["short_description"]
                                    }
                                    onChange={(e) =>
                                      this.onQuestionValueChange(e, index)
                                    }
                                    onBlur={handleBlur}
                                  />
                                  {this.state.errors.allQuestions &&
                                  this.state.errors.allQuestions[index] &&
                                  this.state.errors.allQuestions[index][
                                    "short_description"
                                  ] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        margin: "0",
                                      }}
                                    >
                                      {
                                        this.state.errors.allQuestions[index][
                                          "short_description"
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="formik-field_wrap">
                                <div className="formik-field">
                                  <span style={{ fontWeight: "bold" }}>
                                    Long Description
                                  </span>
                                  <TextArea
                                    row={4}
                                    id="long_description"
                                    name="long_description"
                                    placeholder="Long Description"
                                    value={
                                      allQuestions[index]["long_description"]
                                    }
                                    onChange={(e) =>
                                      this.onQuestionValueChange(e, index)
                                    }
                                    onBlur={handleBlur}
                                  />
                                  {this.state.errors.allQuestions &&
                                  this.state.errors.allQuestions[index] &&
                                  this.state.errors.allQuestions[index][
                                    "long_description"
                                  ] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        margin: "0",
                                      }}
                                    >
                                      {
                                        this.state.errors.allQuestions[index][
                                          "long_description"
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              {/* <div className="formik-field-left" style={{ width: "30%", marginTop: "5px" }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        Correct Option
                                      </span>
                                      <Input
                                        id="answerText"
                                        placeholder="Answer Text"
                                        value={allQuestions[index]["corre"]}
                                        onChange={(e) => this.onAnswerTextChange(e, index)}
                                        onBlur={handleBlur}
                                      />
                                    </div> */}

                {/* <div className="formik-field_wrap">
                                <div
                                  className="formik-field-left"
                                  style={{ width: "30%" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    Time
                                  </span>
                                  <Input
                                    id="time"
                                    placeholder="Time"
                                    name="time"
                                    value={allQuestions[index]["time"]}
                                    // onChange={(e) => this.onMarksChange(e, index)}
                                    onChange={(e) =>
                                      this.onQuestionValueChange(e, index)
                                    }
                                    onBlur={handleBlur}
                                  />
                                </div>

                                <div
                                  className="formik-field-right"
                                  style={{ width: "30%" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    Marks
                                  </span>
                                  <Input
                                    id="marks"
                                    placeholder="Marks"
                                    type="number"
                                    name="marks"
                                    value={allQuestions[index]["marks"]}
                                    // onChange={(e) => this.onMarksChange(e, index)}
                                    onChange={(e) =>
                                      this.onQuestionValueChange(e, index)
                                    }
                                    onBlur={handleBlur}
                                  />
                                  {this.state.errors.allQuestions &&
                                  this.state.errors.allQuestions[index] &&
                                  this.state.errors.allQuestions[index][
                                    "marks"
                                  ] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        margin: "0",
                                      }}
                                    >
                                      {
                                        this.state.errors.allQuestions[index][
                                          "marks"
                                        ]
                                      }
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              {allQuestions[index]["ans_type"] &&
                              (allQuestions[index]["ans_type"] ===
                                "multiChoies" ||
                                allQuestions[index]["ans_type"] ===
                                  "singleChoies") ? (
                                <>
                                  <div style={{ marginTop: "15px" }}>
                                    <Button
                                      style={{
                                        marginTop: "20px",
                                        background: "#5C6AC4",
                                        color: "#fff",
                                      }}
                                      onClick={() => this.addOptions(index)}
                                    >
                                      Add Option
                                    </Button>
                                  </div>
                                  {allQuestions[index]["options"].map(
                                    (optData, optIndex) => {
                                      return (
                                        <div
                                          className="formik-field_wrap"
                                          style={{
                                            borderBottom: "1px solid gray",
                                            paddingBottom: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginTop: "5px",
                                              marginBottom: "5px",
                                              width: "100%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "left",
                                              }}
                                            >
                                              {`Option ${optIndex + 1}`}
                                            </span>
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                float: "right",
                                              }}
                                            >
                                              <Button
                                                shape="circle"
                                                icon={<DeleteFilled />}
                                                onClick={() => {
                                                  this.removeQuestionOptionItem(
                                                    index,
                                                    optIndex
                                                  );
                                                }}
                                              />
                                            </span>
                                          </div>

                                          <div className="formik-field-left">
                                            <Input
                                              id={`title ${optIndex + 1}`}
                                              placeholder={`Title ${
                                                optIndex + 1
                                              }`}
                                              name="title"
                                              value={
                                                allQuestions[index]["options"][
                                                  optIndex
                                                ]["title"]
                                              }
                                              onChange={(e) =>
                                                this.onOptionValueChange(
                                                  e,
                                                  index,
                                                  optIndex
                                                )
                                              }
                                              onBlur={handleBlur}
                                            />
                                            {this.state.errors
                                              .selectedAnswerType ? (
                                              <p
                                                style={{
                                                  color: "red",
                                                  fontSize: "small",
                                                  margin: "0",
                                                }}
                                              >
                                                {
                                                  this.state.errors
                                                    .selectedAnswerType
                                                }
                                              </p>
                                            ) : null}
                                          </div>

                                          {allQuestions[index]["ans_type"] ===
                                          "multiChoies" ? (
                                            <div
                                              className="formik-field-right"
                                              style={{
                                                width: "30%",
                                                marginTop: "5px",
                                              }}
                                            >
                                              <Checkbox
                                                name="is_correct"
                                                onChange={(e) =>
                                                  this.onOptionIsCorrectChange(
                                                    e,
                                                    index,
                                                    optIndex
                                                  )
                                                }
                                                checked={
                                                  allQuestions[index][
                                                    "options"
                                                  ][optIndex]["is_correct"]
                                                }
                                              >
                                                Is Correct
                                              </Checkbox>
                                              {this.state.errors.isPaid ? (
                                                <p
                                                  style={{
                                                    color: "red",
                                                    fontSize: "small",
                                                    margin: "0",
                                                  }}
                                                >
                                                  {this.state.errors.isPaid}
                                                </p>
                                              ) : null}
                                            </div>
                                          ) : (
                                            <div
                                              className="formik-field-right"
                                              style={{
                                                width: "30%",
                                                marginTop: "5px",
                                              }}
                                            >
                                              <Radio.Group
                                                name={`is_correct_radio_group${index}`}
                                                onChange={(e) =>
                                                  this.onRadioOptionIsCorrectChange(
                                                    e,
                                                    index,
                                                    optIndex
                                                  )
                                                }
                                                value={
                                                  allQuestions[index][
                                                    "options"
                                                  ]["is_correct_radio"]
                                                }
                                              >
                                                <Radio value={optIndex}>
                                                  Is Correct
                                                </Radio>
                                              </Radio.Group>

                                              {this.state.errors.isPaid ? (
                                                <p
                                                  style={{
                                                    color: "red",
                                                    fontSize: "small",
                                                    margin: "0",
                                                  }}
                                                >
                                                  {this.state.errors.isPaid}
                                                </p>
                                              ) : null}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              ) : null}
                              {this.state.errors.allQuestions &&
                              this.state.errors.allQuestions[index] &&
                              this.state.errors.allQuestions[index][
                                "options"
                              ] ? (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    margin: "0",
                                  }}
                                >
                                  {
                                    this.state.errors.allQuestions[index][
                                      "options"
                                    ]
                                  }
                                </p>
                              ) : null}
                            </div>

                            {allQuestions[index]["ques_type"] === "Audio" ||
                            allQuestions[index]["ques_type"] === "Video" ? (
                              <div className="customRightWidth">
                                <div className="formik-field_wrap">
                                  <Button
                                    style={{ width: "100%" }}
                                    onClick={this.showQuestionMediaUpload.bind(
                                      this,
                                      index
                                    )} //this.showViewModal.bind(this)
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      {`Choose Question ${allQuestions[index]["ques_type"]}`}
                                    </span>
                                  </Button>
                                  <br />

                                  {allQuestions[index]["media_name"] ? (
                                    <>
                                      <Button
                                        type="dashed"
                                        block
                                        onClick={() => {
                                          window.open(
                                            WEBURL +
                                              "course/topic/question/" +
                                              allQuestions[index]["media_name"]
                                          );
                                        }}
                                      >
                                        Click To Preview
                                      </Button>
                                    </>
                                  ) : null}

                                  {allQuestions[index][
                                    "view_question_media_modal"
                                  ] ? (
                                    <AddQuestionMedia
                                      refx={(e) =>
                                        (this.viewQuestionMediaModalRef = e)
                                      }
                                      onUpload={(name) =>
                                        this.uploadQuestionMedia(name, index)
                                      }
                                      selectedMediaType={allQuestions[index][
                                        "ques_type"
                                      ].toLowerCase()}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                            {this.state.errors.allQuestions &&
                            this.state.errors.allQuestions[index] &&
                            this.state.errors.allQuestions[index][
                              "media_name"
                            ] ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {
                                  this.state.errors.allQuestions[index][
                                    "media_name"
                                  ]
                                }
                              </p>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                    <Divider></Divider>
                  </>
                )} */}

                {/* Course Topic Media Section Start */}

                {/* {this.state.selectedType === "study" && (
                  <>
                    <div className="formik-field_wrap">
                      <div className="formik-field">
                        <div className="formik-field-wrap">
                          <div
                            style={{
                              display: "inline-block",
                              width: "100%",
                              marginTop: "20px",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ float: "left", width: "10%" }}>
                              <PictureOutlined
                                style={{ width: "100%", fontSize: "30px" }}
                              />
                            </div>
                            <Button
                              style={{ width: "90%" }}
                              onClick={this.showMediaModal.bind(this)}
                            >
                              Choose Media Files
                            </Button>

                            {this.state.errors.media ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {this.state.errors.media}
                              </p>
                            ) : null}

                            {this.state.viewMediaModal ? (
                              <AddCourseSessionTopicMediaFiles
                                refx={(e) => (this.viewMediaModalRef = e)}
                                onUpload={this.uploadMediaFiles}
                              />
                            ) : null}
                          </div>

                          <div
                            style={{
                              marginTop: "20px",
                              width: "100%",
                              backgroundColor:
                                this.selectedColorType !== ""
                                  ? this.selectedColorType
                                  : "#ffffff",
                              borderRadius: "10px",
                            }}
                          >
                            {media && media.length > 0
                              ? media.map((media) => (
                                  <MediaItem
                                    name={media.media_name}
                                    url={`${COURSE_TOPIC_MEDIA_PREVIEW_PATH}${media.media_name}`}
                                    thumbMediaUrl={`${COURSE_TOPIC_MEDIA_PREVIEW_PATH}${media.thumb_media_name}`}
                                  />
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider></Divider>
                  </>
                )} */}
                {/* Course Topic Media Section End */}

                <Button
                  type="primary"
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Edit
                </Button>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default connect()(withRouter(EditForm));
