import { callApiToServer } from "../callApi";

export async function deleteCorporate(authtoken, adminId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      "admin_id":adminId
    })
    console.log(body);
    let endUrl = `v1/auth/admin`;
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body,header, "DELETE", endUrl);
    console.log(responseData);
    return responseData;
  } catch (err) {
    throw err;
  }
}
