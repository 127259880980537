import { callGetApiToServer } from "../callApi";

export async function getCorporateGroups(authToken, skipNumber, limitNumber, searchQuery = "") {
  console.log(authToken, skipNumber, limitNumber, searchQuery);
  
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    };

    // Add query parameters for skip, limit, and search
    let endUrl = `v1/auth/admin/list-corporate-group?skip=${skipNumber}&limit=${limitNumber}&search=${encodeURIComponent(searchQuery)}`;

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
