import { callApiToServer } from '../callApi';

export async function editAdminPassword(authtoken, adminObject) {
  try {
    const header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + authtoken,
    };

    const body = JSON.stringify({
      oldPassword: adminObject.old_password,
      newPassword: adminObject.new_password
    });

    const endUrl = "v1/auth/admin/change-admin-password";
    console.log("endUrl printed here", endUrl);

    const responseData = await callApiToServer(body, header, "PUT", endUrl);

    console.log("----- API response -----", responseData);

    return responseData;

  } catch (err) {
    throw err;
  }
}
