import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
// import { get } from "lodash";
import { getAllFaq } from "../../Graphs/generalFaq/listFaq";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteFaq } from "../../Graphs/generalFaq/deleteFaq";
import { editFaq } from "../../Graphs/generalFaq/editFaq";
import {EditModal} from "../generalFaq/EditModal";
// D:\trainovateAdmin\tarinovate-admin\src\components\generalFaq\EditModal.js

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class FaqRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedFaq = this.getLimitedFaq.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      generalfaqSelectedData: null,
    });
    console.log("Cds")
  }
  // modalRef;

  async getLimitedFaq(pagination) {
    console.log("Cds")
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
          //call normal user Data
          console.log("ABDS");
          this.listGeneralFaqData(start, end);
          console.log("LISTED DATA", this.listGeneralFaqData);
      }
    );
  }

  listGeneralFaqData = async (start, end) => {
    console.log("Cds")
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListGeneralFaqData,
        setListGeneralFaqDataTotal,
        // course_id,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let GeneralfaqData = await getAllFaq(authToken, start, end);

      if (GeneralfaqData.status === 200) {
        //set user Data
        // await setListFaqData(faqData.data.docs);
        await setListGeneralFaqData(GeneralfaqData.data);
        await setListGeneralFaqDataTotal(GeneralfaqData.total);
      } else if (GeneralfaqData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      console.log("!!!!!!!list user Data printed here", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  showModal(item) {
    this.setState(
      {
        generalfaqSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      generalfaqSelectedData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      width: 150,
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Question",
      width: 150,
      key: "question",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <strong>
              <Tooltip placement="bottom" title="Question">
                <div>{item.question ? item.question : "-"}</div>
              </Tooltip>
            </strong>
          </div>
        );
      },
    },
    // {
    //   title: "Course",
    //   width: 150,
    //   key: "course",
    //   render: (text, item, index) => {
    //     console.log("user record --> ", item);
    //     return (
    //       <div>
    //         <strong>
    //           <Tooltip placement="bottom" title="Course">
    //             <div>{item.courseData && item.courseData[0] && item.courseData[0].name ? item.courseData[0].name : "-"}</div>
    //           </Tooltip>
    //         </strong>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Active",
    //   width: 100,
    //   key: "is_active",
    //   render: (text, item, index) => {
    //     return (
    //       <div>
    //         {item.is_active != null ? (
    //           <Tooltip placement="bottom" title="Faq Activation">
    //             {
    //               <Switch checked={item.is_active} style={{ backgroundColor: item.is_active ? this.props.primaryColor : "#bababa", }} onChange={this.onChange.bind(this, item)} />
    //             }
    //           </Tooltip>
    //         ) : (
    //           "-"
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            <div mr="5px" style={{ float: "left" }}>
              <Tooltip placement="bottom" title="Edit">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showModal(item);
                  }}
                />
              </Tooltip>
            </div>
            <div
              ml="15px"
              mr="5px"
              style={{ float: "left", marginLeft: "10px" }}
            >
              <Tooltip placement="bottom" title="Delete">
                <Popconfirm
                  onConfirm={this.deleteFaqData.bind(this, item)}
                  title="Are you sure you want to delete FAQ?"
                >
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  async deleteFaqData(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        // course_id
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteFaq(authToken, item._id);

      if (deleteData.status === 200) {
        notification["success"]({
          message: "Delete Faq",
          description: "Successfully deleted",
        });

        /*if (searchText != null && searchText !== "") {
          this.listSearchFaqData(startIndex, limitNumber);
          return;
        } */
        this.listGeneralFaqData(startIndex, limitNumber);
      } else if (deleteData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Delete Faq",
        description: e,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  // async onChange(item, checked) {
  //   try {
  //     const {
  //       authToken,
  //       history,
  //       setLoginFlag,
  //       setUserData,
  //       setUserToken,
  //       searchText,
  //     } = this.props;
  //     const { startIndex, limitNumber } = this.state;

  //     this.setState({
  //       isLoading: true,
  //     });
  //   } catch (err) {
  //     notification["error"]({
  //       message: "Change Faq Status",
  //       description: err,
  //     });
  //   } finally {
  //     this.setState({
  //       isLoading: false,
  //     });
  //   }
  // }

  editSubmit = async (faqObject) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await editFaq(authToken, faqObject);
      if (statusData.status === 200) {
        notification["success"]({
          message: "Edit Faq Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText !== "") {
          this.listSearchFaqData(startIndex, limitNumber);
          return;
        }
        this.listGeneralFaqData(startIndex, limitNumber);
      } else if (statusData.status === 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (e) {
      notification["error"]({
        message: "Edit Faq",
        description: e,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedFaq(pagination)
    );
  }

  render() {
    const { isLoading } = this.state;
    const { listGeneralFaqData, listGeneralFaqDataTotal, searchLoader } = this.props;
    console.log({listGeneralFaqData})
    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ overflowX: "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listGeneralFaqData}
          pagination={{
            total: listGeneralFaqDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />
        {this.state.generalfaqSelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            generalfaqSelectedData={this.state.generalfaqSelectedData}
            onSubmit={this.editSubmit}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listGeneralFaqData: state.GeneralFaq.listGeneralFaqData,
  listGeneralFaqDataTotal: state.GeneralFaq.listGeneralFaqDataTotal,
  searchLoader: state.auth.searchLoader,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListGeneralFaqData: (listGeneralFaqData) => {
      dispatch({
        type: "SET_LIST_FAQ_DATA",
        listGeneralFaqData: listGeneralFaqData,
      });
    },
    setListGeneralFaqDataTotal: (listGeneralFaqDataTotal) => {
      dispatch({
        type: "SET_LIST_FAQ_DATA_TOTAL",
        listGeneralFaqDataTotal: listGeneralFaqDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FaqRecordsTable));
