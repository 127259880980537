import { callApiToServer } from "../callApi";

export async function EditGroupCourse(authToken, payload) {
  try {
    let header = {
      // Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Berear ${authToken}` ,
    };

    let body = JSON.stringify(payload)
    console.log("Body", body);

    let endUrl = `v1/auth/admin/edit-course-user-limit`;

    let responseData = await callApiToServer(body, header, "PUT", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
