import {  Button } from "antd";
import * as React from "react";
import {  Input, Select } from "antd";
import { Formik } from "formik";
// import { listAllDropDownCourse } from "../../Graphs/Course/listAllDropDownCourse";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const { TextArea } = Input;
// const Option = Select.Option;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
    };
  }

  input;

  async componentDidMount() {
  }

  validateForm = (values) => {

    // const { selectedCourseType } = this.state;

    const errors = {};
    console.log("Validating form -- ", values);

    if (!values.question) {
      errors.question = "Question is required";
    }

    if (!values.answer) {
      errors.answer = "Answer is required";
    }

    console.log("Validating errors -- ", errors);
    return errors;
  }

  handleSubmit = (values, action) => {
    // const { selectedCourseType } = this.state;
    const { data } = this.props;

    values.faq_id = data._id;
    // values.course_id = selectedCourseType;

    this.props.onSubmit(values);

    this.setState({
      visible: false,
    });
  };

//   changeCourse = (value) => {
//     this.setState({
//       selectedCourseType: value,
//     });
//   };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { data } = this.props;
    // const { selectedCourseType } = this.state;
    return (
      <div>
        <Formik
          initialValues={{
            question: data.question,
            answer: data.answer
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div>
                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Question
                    <Input
                      id="question"
                      placeholder="Question"
                      value={values.question}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.question && touched.question ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.question}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="formik-field_wrap">
                  <div className="formik-field">
                    Answer
                    <TextArea
                      row={4}
                      id="answer"
                      placeholder="Answer"
                      value={values.answer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.answer && touched.answer ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.answer}
                      </p>
                    ) : null}
                  </div>
                </div>

                <Button
                  type="primary"
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Edit
                </Button>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  authToken: state.auth.authToken
});

export default connect(mapStateToProps)(withRouter(EditForm));