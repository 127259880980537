import React, { Fragment } from "react";
import {
  Spin,
  Tabs,
  Layout,
  Card,
  notification,
  Table,
  Tooltip,
  Switch,
  Button,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  LockOutlined,
  UnlockOutlined,
  KeyOutlined
} from "@ant-design/icons";
export const getUserTableColumns = (serialCounter, onChangeStatus, handleEditUser, handlePasswordChange, handleDeleteUser) => [
  {
    title: "Sr. No.",
    key: "serial_number",
    width: 70,
    render: (text, item, index) => {
      return (
        <div>
          <span>{serialCounter + index}</span>
        </div>
      );
    },
  },
  {
    title: "Name",
    key: "name",
    width: 150,
    render: (text, item) => (
      <Tooltip title="User Name">
        <div>{item.first_name || "-"}</div>
      </Tooltip>
    ),
  },
  {
    title: "Email",
    key: "email",
    width: 150,
    render: (text, item) => (
      <Tooltip title="Email">
        <div>{item.email || "-"}</div>
      </Tooltip>
    ),
  },
  {
    title: "Is Active",
    key: "is_active",
    width: 100,
    render: (text, item) => (
      <Switch
        checked={item.is_active}
        onChange={(checked) => {
          console.log("Switch toggled:", checked, "For User:", item);
          if (onChangeStatus) {
            onChangeStatus(item, checked);
          } else {
            console.error("onChangeStatus function is not provided!");
          }
        }}
      />
    ),
  },
  {
    title: "Department",
    key: "department",
    width: 100,
    render: (text, item) => (
      <Tooltip title="Department">
        <div>
        {item.department_id && item.department_id.length > 0
          ? item.department_id.map((dept) => dept.name).join(", ")
          : "-"}
      </div>
      </Tooltip>
    ),
  },
  {
    title: "Actions",
    key: "action",
    width: 180,
    render: (text, item) => (
      <div style={{ display: "flex", gap: "10px" }}>
        {/* Edit User */}
        <Tooltip title="Edit">
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => handleEditUser(item)}
          />
        </Tooltip>

      {/* Change Password */}
      <Tooltip title="Change Password">
        <Button
          shape="circle"
          icon={<LockOutlined />}
          onClick={() => handlePasswordChange(item)}
        />
      </Tooltip>

        {/* Delete User */}
        <Tooltip title="Delete">
          <Popconfirm
            title="Are you sure you want to delete this user?"
            onConfirm={() => handleDeleteUser(item._id)}
          >
            <Button shape="circle" icon={<DeleteOutlined
             />} />
          </Popconfirm>
        </Tooltip>
      </div>
    ),
  },
];
