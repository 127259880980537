import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select, DatePicker } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import * as la from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getDepartments } from "../../Graphs/Department/GetDepartment";

const { Content } = Layout;
const Option = Select.Option;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    // Initialize selectedDepartment with department IDs for backend
    const initialDepartments = this.props.data.department_id
      ? Array.isArray(this.props.data.department_id) 
          ? this.props.data.department_id.map(item => item._id)
          : [this.props.data.department_id._id]
      : [];

    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      selectedType: "",
      selectedDob: "",
      departments: [],
      selectedDepartment: initialDepartments,
      displayDepartments: this.props.data.department_id  // Store full department objects for display
        ? Array.isArray(this.props.data.department_id)
            ? this.props.data.department_id
            : [this.props.data.department_id]
        : [],
      errors: {},
    };
  }

  input;
  allDepartments = [];

  async componentDidMount() {
    const { data, Token } = this.props;

    let DepartmentData = await getDepartments(Token);

    if (DepartmentData.data && DepartmentData.data.length >= 1) {
      this.setState({
        departments: DepartmentData.data.map((data) => ({
          value: data._id,
          label: data.name,
        })),
      });
    }

    this.setState({
      selectedDob: data.dob,
    });
  }


  validateForm = (values) => {

    const errors = {};
    const {loginData} = this.props
    const {selectedDepartment} = this.state;
    console.log("Validating form -- ", values);

    // if (!values.email) {
    //   errors.email = "Email is required";
    // } else if (
    //   !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //     String(values.email).toLowerCase()
    //   )
    // ) {
    //   errors.email = "Please enter a valid email";
    // }

    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required";
    }

    if(loginData.role === "corporate") {
      if(values.department_id.length === 0) {
        errors.selectedDepartment  = "Department Name is required"
      }
    }
    // if (!selectedDob) {
    //   errors.selectedDob = "Please select Date of Birth";
    // }

    console.log("Validating errors -- ", errors);

    return errors;
  };

  handleSubmit = async (values, action) => {
    const { selectedDepartment } = this.state;
    // Ensure we're passing the array of department IDs
    values.department_id = selectedDepartment;
    delete values.selectedDepartment;
    console.log("Payload: ", values)
    this.props.onSubmit(values, this.props.data._id);
    this.setState({
      visible: false,
    });
  };

  changeType = (value) => {
    this.setState({
      selectedType: value,
    });
  };

  changeDob = (date, dateString) => {
    this.setState({
      selectedDob: dateString,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      selectedType: "",
    });
  }

  render() {
    const { primaryColor, data, loginData } = this.props;
    const { selectedDob } = this.state;
    console.log("login data:", data);

    return (
      <div>
        <Formik
          initialValues={{
            // name: data.name,
            // email: data.email,
            // department: "",
            phone: data.phone,
            country: data.country,
            city: data.city,
            bio: data.bio,
            current_location: data.current_location,
            first_name: data.first_name,
            last_name: data.last_name,
            address_line1: data.address_line1,
            address_line2: data.address_line2,
            is_verified: data.is_verified,
            job_post: data.job_post,
            // department: data.department_id
            // departments: data.department_id.map((item=> item.name)) || ""
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div>
                {/* <div className="formik-field_wrap"> */}
                {/* <div className="formik-field-left">
                    Username
                        <Input
                      id="username"
                      placeholder="Username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.username && touched.username ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.username}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right">
                    Name
                        <Input
                      id="name"
                      placeholder="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.name}
                      </p>
                    ) : null}
                  </div>
                </div> */}

                <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    First Name
                    <Input
                      id="first_name"
                      placeholder="First Name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.first_name && touched.first_name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.first_name}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right">
                    Last Name
                    <Input
                      id="last_name"
                      placeholder="Last Name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.last_name && touched.last_name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.last_name}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="formik-field_wrap">
                  {/* Email Field (Always Visible) */}
                  {/* <div className="formik-field-left">
                    Email
                    <Input
                      id="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.email}
                      </p>
                    ) : null}
                  </div> */}
                  {/* Department Field (Only for Corporate User) */}
                  {loginData.role === "corporate" ? (
                    <div className="formik-field-left">
                      Department
                      <Select
                        id="department"
                        mode="multiple" // Enables multiple selection
                        placeholder="Select Department"
                        value={this.state.selectedDepartment}
                        onChange={(value) =>
                          this.setState({ selectedDepartment: value })
                        }
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="children"
                      >
                        {this.state.departments.map((dept) => (
                          <Option key={dept.value} value={dept.value}>
                            {dept.label}
                          </Option>
                        ))}
                      </Select>

                      {/* Display error message below the Select component */}
                      {errors.department && touched.department ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.department}
                            </p>
                          ) : null}
                    </div>
                  ) : null }
                </div>

                {/* <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Password
                        <Input
                      type="password"
                      id="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password && touched.password ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.password}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right">
                    Confirm Password
                                    <Input
                      id="confirm_password"
                      type="password"
                      placeholder="Confirm Password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.confirm_password && touched.confirm_password ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0"
                        }}
                      >
                        {errors.confirm_password}
                      </p>
                    ) : null}
                  </div>
                </div> */}

                {/* Show the following fields only if the user is an admin */}
                {/* {(loginData.role === "SUPERADMIN" ||
                  loginData.role === "SUBADMIN") && (
                  <>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Bio
                        <Input
                          type="textbox"
                          id="bio"
                          placeholder="Bio"
                          value={values.bio}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.bio && touched.bio ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.bio}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Current Location
                        <Input
                          id="current_location"
                          placeholder="Current Location"
                          value={values.current_location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.current_location && touched.current_location ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.current_location}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Address line 1
                        <Input
                          id="address_line1"
                          placeholder="address line 1"
                          value={values.address_line1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.address_line1 && touched.address_line1 ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.address_line1}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Address line 2
                        <Input
                          id="address_line2"
                          placeholder="address line 2"
                          value={values.address_line2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.address_line2 && touched.address_line2 ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.address_line2}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        city
                        <Input
                          id="city"
                          placeholder="City"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.city && touched.city ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.city}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Country
                        <Input
                          id="country"
                          placeholder="Country"
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.country && touched.country ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.country}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </>
                )} */}

                {/* <div className="formik-field_wrap">
                  <div className="formik-field-left">
                    Job Post
                        <Input
                      id="job_post"
                      placeholder="Job Post"
                      value={values.job_post}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.job_post && touched.job_post ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.job_post}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right">
                    {/* Is Verified ?
                        <Input
                      type="checkbox"
                      id="is_verified"
                      placeholder="name"
                      value={values.is_verified}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    /> */}
                {/* <Checkbox checked={values.is_verified} name="is_verified" onBlur={handleBlur} onChange={handleChange}>Is Verified ?</Checkbox>
                  </div>
                </div> */}

                <Button
                  type="primary"
                  style={{
                    marginTop: "19px",
                    background: "#5C6AC4",
                    color: "#fff",
                  }}
                  onClick={handleSubmit}
                >
                  Edit
                </Button>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  };
}



const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(EditForm));
