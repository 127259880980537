import { callGetApiToServer } from "../callApi";

export async function getUserCourseList(authtoken,data, skipNumber, limitNumber) {
  console.log(authtoken,limitNumber,data);
  const {_id,type} = data
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    // let endUrl = "v1/auth/admin/user-courses/" + skipNumber + "/" + limitNumber;
    let endUrl = "v1/auth/admin/user-courses/" + _id + "/" + type;

    let responseData = await callGetApiToServer(header, "GET", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
